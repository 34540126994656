import {
  DEFAULT_SHIP_ICON_WIDTH,
  DEFAULT_SHIP_ICON_HEIGHT,
} from "../../../utils/Constants";
import { useRef, useEffect } from "react";

export const SimilarShips = (
  color = "#888888",
  width = DEFAULT_SHIP_ICON_WIDTH,
  height = DEFAULT_SHIP_ICON_HEIGHT,
) => {
  return `<svg
      width=${width}
      height=${height}
      viewBox="0 0 14 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.9999 21.5L12.8235 21.5L12.8235 16.7413C12.8235 6.01595 6.99989 1.5 6.99989 1.5C6.99989 1.5 1.17627 6.01595 1.17628 16.7413L1.17628 21.5H6.9999Z"
        fill="${color}"
        stroke="#111326"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M4.99951 11.5C4.99951 10.5 5.49951 9.5 6.99951 9.5C8.49951 9.5 8.99951 10.5 8.99951 11.5C8.99951 12.5 7.99951 13 7.49951 13.5C7.0542 13.9453 6.99951 14 6.99951 15"
        stroke="#111326"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <circle cx="6.99951" cy="17.5" r="1" fill="#111326" />
    </svg>`;
};

const SimilarShipsIcon = ({ color, width, height }: any) => {
  const svgWrapperRef: any = useRef();
  useEffect(() => {
    if (svgWrapperRef.current) {
      svgWrapperRef.current.innerHTML = SimilarShips(color, width, height);
    }
  }, [color, width, height]);

  return <div ref={svgWrapperRef}></div>;
};

export default SimilarShipsIcon;
