import { Dispatch, FC, SetStateAction } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  OutlinedInput,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { styled } from "@mui/system";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styles from "./CustomSelect.module.scss";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import PathAnalysisIcon from "../../assets/icons/PathAnalysis";
import PathIcon from "../../assets/icons/Path";

interface customSelectComponent {
  title?: string | JSX.Element;
  label: string;
  MenuItems: {
    value: string;
    title: string;
    icon?: JSX.Element;
    disabled?: boolean;
  }[];
  onChange?: any;
  selectedOption: string;
  onClick?: any;
  disabled?: boolean;
  isPathAnalysisVisible?: boolean;
  setIsPathAnalysisVisible?: Dispatch<SetStateAction<boolean>>;
  isViewPathVisible?: boolean;
  setIsViewPathVisible?: Dispatch<SetStateAction<boolean>>;
}

const CustomIconWrapper = styled("div")({
  backgroundColor: "transparent",
  padding: 0,
  margin: 0,
  height: 40,
  minWidth: 40,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#fff",
  "&.MuiSelect-icon": {
    right: 0,
    top: 0,
  },
  "&:hover": {
    backgroundColor: "#0094FF",
  },
  ".MuiSvgIcon-root": {
    width: "16px",
    height: "16px",
    fill: "#fff",
  },
});

const CustomIcon = (props: any) => (
  <CustomIconWrapper {...props}>
    <KeyboardArrowDownIcon />
  </CustomIconWrapper>
);

const CustomSelectComponent: FC<customSelectComponent> = ({
  title,
  label,
  MenuItems,
  onChange,
  selectedOption,
  onClick,
  disabled = false,
  isPathAnalysisVisible = false,
  setIsPathAnalysisVisible,
  isViewPathVisible = false,
  setIsViewPathVisible,
}) => {
  const filteredMenuItems = MenuItems.map((item) => {
    if (item.value === "pathAnalysis") {
      return {
        ...item,
        title: isPathAnalysisVisible
          ? "Hide path analysis"
          : "View path analysis",
        icon: isPathAnalysisVisible ? (
          <VisibilityOffOutlinedIcon />
        ) : (
          <PathAnalysisIcon />
        ),
        value: "pathAnalysis",
      };
    }
    if (item.value === "pathPlayback" && isPathAnalysisVisible) {
      return {
        ...item,
        disabled: true,
      };
    }
    if (item.value === "path") {
      return {
        ...item,
        title: isViewPathVisible ? "Hide path" : "View path",
        icon: <PathIcon />,
      };
    }
    return item;
  });
  const handleItemClick = (value: string) => {
    if (value === "pathAnalysis") {
      setIsPathAnalysisVisible &&
        setIsPathAnalysisVisible(!isPathAnalysisVisible);
    }
    if (value === "path") {
      setIsViewPathVisible && setIsViewPathVisible(!isViewPathVisible);
    }
  };

  return (
    <Box
      className={styles.cutomSelectWrap}
      display="flex"
      flexDirection="column"
    >
      <label className={styles.shipToolLabel}>{label}</label>
      <FormControl fullWidth className={styles.selectFormControl}>
        {selectedOption === "" && (
          <InputLabel style={{ color: "#fff" }}></InputLabel>
        )}
        <Select
          onChange={onChange}
          value={selectedOption || ""}
          displayEmpty
          input={<OutlinedInput />}
          inputProps={{ "aria-label": "Without label" }}
          label="1"
          IconComponent={CustomIcon}
          className={styles.customSelectMain}
          classes={{
            select: styles.customSelect,
          }}
          MenuProps={{
            classes: {
              root: styles.selectRoot,
              paper: styles.selectMenu,
              list: styles.selectList,
            },
          }}
          disabled={disabled}
        >
          {selectedOption === "" && (
            <MenuItem disabled value="" className={styles.searchMenuItem}>
              <span>{title}</span>
            </MenuItem>
          )}
          {filteredMenuItems.map((item, index) => {
            return item.disabled ? (
              <Tooltip
                key={`${item.value}-${index}`}
                title={
                  item.value === "pathPlayback" && isPathAnalysisVisible
                    ? "Available when ship path is shown."
                    : "Disabled for Unattributed ship Type"
                }
                placement="top"
                slotProps={{
                  popper: {
                    sx: {
                      [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                        {
                          marginBottom: "-2px",
                        },
                    },
                  },
                }}
              >
                <div>
                  <MenuItem
                    value={item.value}
                    className={styles.selectMenuItem}
                    classes={{
                      disabled: styles.menuItemDisabled,
                    }}
                    key={`${item.value}-${index}`}
                    disabled={item.disabled}
                  >
                    {item.icon}
                    {item.title}
                  </MenuItem>
                </div>
              </Tooltip>
            ) : (
              <MenuItem
                value={item.value}
                className={styles.selectMenuItem}
                key={`${item.value}-${index}`}
                onClick={
                  onClick
                    ? () => {
                        if (item.disabled) return;
                        onClick(item.value);
                        handleItemClick(item.value);
                      }
                    : undefined
                }
              >
                {item.icon} {item.title}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default CustomSelectComponent;
