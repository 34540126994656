import { useEffect, useState } from "react";
import TextInput from "../../../../../components/TextInput/TextInput";
import Label from "../../../../../components/Label/Label";
import SelectInput from "../../../../../components/SelectInput/SelectInput";
import { FuturePathParams } from "../FuturePathPrediction";
import { useSnackbar } from "../../../../../context/SnackbarContext";
import { FUTURE_PATH_PREDICITION_TYPE } from "../../../../../utils/Constants";
import { Box, Button, CircularProgress } from "@mui/material";
import styles from "./FuturePathPredictionModal.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

const FuturePathPredictionModal = ({
  futurePathParams,
  setFuturePathParams,
  open,
  setOpen,
  EditFuturePathParams,
  setEditFuturePathParams,
  handleSubmit,
}: any) => {
  const [futurePathFormData, setFuturePathFormData] =
    useState<FuturePathParams>({});
  const [isSaved, setIsSaved] = useState(false);
  const [stableLoading, setStableLoading] = useState(false);

  const { setNewSnackBar } = useSnackbar();
  const { loading, error } = useSelector(
    (state: RootState) => state.futurePathPrediction,
  );
  const [loadingStartTime, setLoadingStartTime] = useState<number | null>(null);

  useEffect(() => {
    if (futurePathParams) setFuturePathFormData(futurePathParams);
  }, [futurePathParams]);

  useEffect(() => {
    if (error) {
      setStableLoading(false);
      setLoadingStartTime(null);
    }
  }, [error]);

  useEffect(() => {
    if (loading && !stableLoading) {
      setStableLoading(true);
      setLoadingStartTime(Date.now());
    } else if (!loading && stableLoading) {
      // Only stop loading if it's been at least 20 seconds since it started
      // or if we're changing component states (from editing to loader)
      const minLoadingTime = 20000;
      const timeElapsed = loadingStartTime ? Date.now() - loadingStartTime : 0;

      if (timeElapsed >= minLoadingTime || open !== "editFuturePath") {
        setStableLoading(false);
      } else {
        // If not enough time has passed, wait until it has
        const delay = minLoadingTime - timeElapsed;
        const timer = setTimeout(() => {
          setStableLoading(false);
        }, delay);

        return () => clearTimeout(timer);
      }
    }
  }, [loading, stableLoading, loadingStartTime, open]);

  const handleChange = (name: string, value: string | number) => {
    setFuturePathFormData({
      ...futurePathFormData,
      [name]: value,
    });
    setIsSaved(true);
  };

  const handleSubmitFormData = () => setFuturePathParams(futurePathFormData);
  const formValidation = () => {
    const { lat, lon, heading, length, ship_type, speed } = futurePathFormData;

    // Check if all required fields are present
    if (
      lat === undefined ||
      lat === null ||
      lon === undefined ||
      lon === null ||
      heading === undefined ||
      heading === null ||
      length === undefined ||
      length === null ||
      ship_type === undefined ||
      ship_type === null ||
      ship_type === "" ||
      speed === undefined ||
      speed === null
    ) {
      setNewSnackBar({
        message: "Please fill all the fields",
        severity: "error",
      });
      return false;
    }

    // Check if speed is below minimum required
    if (speed <= 5) {
      setNewSnackBar({
        message: `Speed of ${speed} is below minimum 5 knots required for future prediction`,
        severity: "error",
      });
      return false;
    }

    return true;
  };

  return (
    <Box className={styles.futurePathBody}>
      {open === "editFuturePath" && (
        <Box>
          {EditFuturePathParams && (
            <Box
              display="grid"
              gridTemplateColumns="repeat(2,1fr)"
              className={styles.EditPathMain}
            >
              <Box
                display="flex"
                flexDirection="column"
                className={styles.editMainWrapper}
              >
                <Label label="Latitude" />
                <TextInput
                  id="latitude"
                  name="lat"
                  type="number"
                  value={futurePathFormData.lat}
                  onChange={(e: any) =>
                    handleChange(e.target.name, parseFloat(e.target.value))
                  }
                  className={styles.editMainInput}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                className={styles.editMainWrapper}
              >
                <Label label="Longtitude" />
                <TextInput
                  id="longtitude"
                  name="lon"
                  type="number"
                  value={futurePathFormData.lon}
                  onChange={(e: any) =>
                    handleChange(e.target.name, parseFloat(e.target.value))
                  }
                  className={styles.editMainInput}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                className={styles.editMainWrapper}
              >
                <Label label="Speed" />
                <TextInput
                  id="speed"
                  name="speed"
                  type="number"
                  value={futurePathFormData.speed}
                  onChange={(e: any) =>
                    handleChange(e.target.name, parseFloat(e.target.value))
                  }
                  className={styles.editMainInput}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                className={styles.editMainWrapper}
              >
                <Label label="Length" />
                <TextInput
                  id="length"
                  name="length"
                  type="number"
                  value={futurePathFormData.length}
                  onChange={(e: any) =>
                    handleChange(e.target.name, parseFloat(e.target.value))
                  }
                  className={styles.editMainInput}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                className={styles.editMainWrapper}
              >
                <Label label="Heading" />
                <TextInput
                  id="heading"
                  name="heading"
                  type="number"
                  value={futurePathFormData.heading}
                  onChange={(e: any) =>
                    handleChange(e.target.name, parseFloat(e.target.value))
                  }
                  className={styles.editMainInput}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                className={styles.editMainWrapper}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Label label="Ship Type" />
                <SelectInput
                  id="shipType"
                  name="ship_type"
                  value={
                    (futurePathFormData?.ship_type?.toLowerCase() ===
                    "unattributed"
                      ? "other"
                      : futurePathFormData?.ship_type?.toLowerCase()) || "other"
                  }
                  onChange={(e: any) =>
                    handleChange(e.target.name, e.target.value)
                  }
                  className={styles.editMainSelect}
                  optionsArray={FUTURE_PATH_PREDICITION_TYPE}
                />
              </Box>
              <Button
                variant="outlined"
                className={styles.editMainButton}
                onClick={() => {
                  setFuturePathFormData(futurePathParams);
                  setIsSaved(false);
                }}
              >
                Reset to Default
              </Button>
              {isSaved && (
                <Button
                  variant="outlined"
                  className={styles.editMainButton}
                  onClick={() => {
                    setEditFuturePathParams(false);
                    handleSubmitFormData();
                  }}
                >
                  Save & Hide Parameters
                </Button>
              )}
            </Box>
          )}
          <Box display="flex" flexDirection="column" gap={2}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Button
                variant="contained"
                onClick={() => {
                  if (formValidation()) {
                    handleSubmit();
                    setOpen("futurePathLoader");
                    setEditFuturePathParams(false);
                    // Start the loading state immediately when user clicks
                    setStableLoading(true);
                    setLoadingStartTime(Date.now());
                  }
                }}
                className={styles.futureBodyViewButton}
                disabled={stableLoading}
              >
                {stableLoading ? (
                  <>
                    <CircularProgress
                      size={16}
                      style={{ marginRight: "8px" }}
                    />
                    Processing...
                  </>
                ) : (
                  "View Future Path Prediction"
                )}
              </Button>
              {!EditFuturePathParams && (
                <Button
                  variant="outlined"
                  className={styles.futureBodyEditButton}
                  onClick={() => {
                    setEditFuturePathParams(true);
                  }}
                  disabled={stableLoading}
                >
                  Edit Parameters
                </Button>
              )}
            </Box>
            <p className={styles.futureBodyText}>
              This action could take up to a few minutes
            </p>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FuturePathPredictionModal;
