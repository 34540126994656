import Draggable from "react-draggable";
import { Box, Skeleton } from "@mui/material";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";

const ShipCLipImage = styled.img`
  width: 100%;
  height: auto;
`;

const ModalHeader = styled.div`
  background-color: rgb(36, 38, 60);
  display: flex;
  align-items: center;
  padding: 16px 24px;
`;

const ModalHeaderText = styled.div`
  font-size: 14px;
  width: 400px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
`;

const ImageContainer = styled(Box)`
  height: 100%;
  cursor: move;
  border-bottom-radius: 8px;
`;

const SatelliteImageModal = styled(Box)`
  position: absolute;
  bottom: 25%;
  right: 5%;
  z-index: 199;
  width: 488px;
  height: 544px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow:
    4px 4px 15px rgba(0, 0, 0, 0.2),
    -2px -2px 10px rgba(0, 0, 0, 0.1);
`;

const DraggableImageModal = ({
  headerText,
  imageUrl,
  onClose,
}: {
  headerText: string;
  imageUrl: string;
  onClose: () => void;
}) => (
  <Draggable bounds="parent">
    <SatelliteImageModal>
      <ImageContainer>
        <ModalHeader>
          <ModalHeaderText>{headerText}</ModalHeaderText>
          <CloseIcon
            sx={{ cursor: "pointer", marginLeft: "auto" }}
            onClick={onClose}
          />
        </ModalHeader>
        {imageUrl === "" ? (
          <Skeleton
            variant="rounded"
            width={450}
            height={450}
            sx={{ bgcolor: "#393c56" }}
          />
        ) : (
          <ShipCLipImage src={imageUrl} alt="ShipImg" draggable={false} />
        )}
      </ImageContainer>
    </SatelliteImageModal>
  </Draggable>
);
export default DraggableImageModal;
