import { LightShip } from "../ships/light/LightShip";
import { AISShip } from "../ships/AIS/AISShip";
import { DarkShip } from "../ships/dark/DarkShip";
import { UnattributedShip } from "../ships/unattributed/UnattributedShip";
import { SpoofingShips } from "../ships/spoofing/SpoofingShip";

// import AisShip from "../ships/ais/AisShip";
import { LightShipSanctioned } from "../ships/light/LightShipSanctioned";
import { DarkShipSanctioned } from "../ships/dark/DarkShipSanctioned";
import { AISShipSanctioned } from "../ships/AIS/AISShipSanctioned";
import { svgToDataUri } from "../../utils/utils";

type ShipType = "ais" | "light" | "dark" | "unattributed" | "spoofing";

export const getColorUpdatedShipIcon = (
  color: any,
  currentEventTypeFilter = "light",
) => {
  const filter: ShipType = currentEventTypeFilter as ShipType;
  const svg = {
    ais: AISShip(color),
    light: LightShip(color),
    dark: DarkShip(color),
    unattributed: UnattributedShip(color),
    spoofing: SpoofingShips(color),
  };
  return svgToDataUri(svg[filter]);
};

type ShipTypeSanctioned = "ais" | "light" | "dark";
export const getColorUpdatedSanctionedShipIcon = (
  color: any,
  currentEventTypeFilter = "light",
) => {
  const filter: ShipTypeSanctioned =
    currentEventTypeFilter as ShipTypeSanctioned;

  const svg = {
    ais: AISShipSanctioned(color),
    light: LightShipSanctioned(color),
    dark: DarkShipSanctioned(color),
  };
  return svgToDataUri(svg[filter]);
};
