import {
  DEFAULT_SELECTED_SHIP_ICON_WIDTH_SPOOFING,
  DEFAULT_SELECTED_SHIP_ICON_HEIGHT_SPOOFING,
} from "../../../utils/Constants";

export const SpoofingShipSelected = (
  isActiveShip: boolean,
  width = DEFAULT_SELECTED_SHIP_ICON_WIDTH_SPOOFING,
  height = DEFAULT_SELECTED_SHIP_ICON_HEIGHT_SPOOFING
) => {
  return `<svg width="${width}" height="${height}" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="25" cy="25.5" r="25" fill="url(#paint0_radial_3218_29348)" fill-opacity="${
    isActiveShip ? "1" : "0.5"
  }" />
  <rect x="25.0002" y="15.5" width="12.7282" height="12.7282" transform="rotate(45 25.0002 15.5)" fill="#FF6D99" stroke="white" stroke-width="1.5" />
  <path d="M25.0002 25.25V21" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  <circle cx="25.0002" cy="28" r="1" fill="white" />
  <defs>
    <radialGradient id="paint0_radial_3218_29348" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25 25.5) rotate(90) scale(25)">
      <stop offset="0.27" stop-color="white" stop-opacity="0" />
      <stop offset="1" stop-color="white" />
    </radialGradient>
  </defs>
</svg>`;
};
