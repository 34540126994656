import React, {
  createContext,
  useEffect,
  useContext,
  useState,
  useCallback,
} from "react";
import { api } from "../services/api";
import { useSnackbar } from "./SnackbarContext";
import { useRemoteConfigurations } from "./RemoteConfiguration/useRemoteConfiguration";

// Create a context for the alert
const ViewAlertContext = createContext({
  viewAlerts: false,
  setViewAlerts: (viewAlerts: boolean) => {},
  alerts: [],
  tabValue: 0,
  handleTabChange: (newValue: number) => {},
  callAlerts: () => {},
  isAlertsLoading: false,
});

// Create a provider for components to consume and subscribe to changes
export const ViewAlertsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { setNewSnackBar } = useSnackbar();
  const { isFeatureEnabled } = useRemoteConfigurations();
  const [tabValue, setTabValue] = React.useState(0);
  const [isAlertsLoading, setIsAlertsLoading] = React.useState(false);

  // Set the initial state of the alert
  const [viewAlerts, setViewAlerts] = useState(false);

  // Set the initial state of the alerts
  const [alerts, setAlerts] = useState([]);

  const callAlerts = useCallback(async () => {
    try {
      setIsAlertsLoading(true);
      const response = await api.get(`/alerts`);
      setAlerts(response.data);
    } catch (error) {
      setNewSnackBar({ message: `${error}`, severity: "error" });
    }
    setIsAlertsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Call the alerts from the api
  useEffect(() => {
    if (!isFeatureEnabled("alerts")) return;

    callAlerts(); // Run immediately on mount

    const intervalId = setInterval(
      () => {
        callAlerts();
      },
      10 * 60 * 1000,
    ); // 10 minutes

    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callAlerts]);

  const handleTabChange = (newValue: number) => {
    setTabValue(newValue);
  };

  // Return the provider with the value of the alert and the function to set the alert
  return (
    <ViewAlertContext.Provider
      value={{
        viewAlerts,
        setViewAlerts,
        alerts,
        tabValue,
        handleTabChange,
        callAlerts,
        isAlertsLoading,
      }}
    >
      {children}
    </ViewAlertContext.Provider>
  );
};

// Create a hook to use the alert
export const useViewAlerts = () => {
  // Check if the hook is used inside the provider
  const context = useContext(ViewAlertContext);
  if (context === undefined) {
    throw new Error(
      "useCreateAlerts must be used within a CreateAlertsProvider",
    );
  }
  // Return the context
  return useContext(ViewAlertContext);
};
