import { useEffect, useState } from "react";
import { allPorts } from "../GeoJSONLayers/portData";
import { seaOfAzov } from "../GeoJSONLayers/seaOfAzov";
import { gulfOfGuinea } from "../GeoJSONLayers/gulfOfGuinea";
import { indianOcean } from "../GeoJSONLayers/inidanOcean";
import { persianGulf } from "../GeoJSONLayers/persianGulf";
import { daboDelgado } from "../GeoJSONLayers/daboDelgado";
import { globalTerritories } from "../GeoJSONLayers/territories";
import { aisStations } from "../GeoJSONLayers/aisStationData";
import { elecLink } from "../GeoJSONLayers/elecLink";
import { nemoLink } from "../GeoJSONLayers/nemoLink";
import { submarineCables } from "../GeoJSONLayers/submarine_cables";
import { NSL } from "../GeoJSONLayers/NSL";
import { IFA } from "../GeoJSONLayers/IFA";
import { IFA2 } from "../GeoJSONLayers/IFA2";
import { britNed } from "../GeoJSONLayers/britNed";
import { GeoJsonLayer } from "@deck.gl/layers/typed";
import { hexToRgb, getIconHeading, getClampedIconSize } from "../utils";
import useGlobalFilters from "../../../../context/useGlobalFilter";
import useCurrentMapState from "../../../../context/useCurrentMapState";
import { LayerToggleSwitches } from "../../../../types/types";

const getStaticLayers = (
  zoomLevel: number,
  layerToggleSwitches: LayerToggleSwitches,
) => [
  new GeoJsonLayer({
    id: "submarineCables",
    data: submarineCables,
    pickable: true,
    stroked: false,
    filled: true,
    extruded: true,
    lineWidthScale: 20,
    lineWidthMinPixels: 8,
    getLineColor: hexToRgb("#a8a8a8"),
    getRadius: 100,
    getLineWidth: 1,
    getElevation: 30,
    visible: layerToggleSwitches.submarineCablesVisible,
  }),
  new GeoJsonLayer({
    id: "elecLink-submarineCables",
    data: elecLink,
    pickable: true,
    stroked: false,
    filled: true,
    extruded: true,
    lineWidthScale: 20,
    lineWidthMinPixels: 8,
    getLineColor: hexToRgb("#168e9e"),
    getRadius: 100,
    getLineWidth: 1,
    getElevation: 30,
    visible: layerToggleSwitches.submarineCablesVisible,
  }),
  new GeoJsonLayer({
    id: "nemoLink-submarineCables",
    data: nemoLink,
    pickable: true,
    stroked: false,
    filled: true,
    extruded: true,
    lineWidthScale: 20,
    lineWidthMinPixels: 8,
    getLineColor: hexToRgb("#38e4ae"),
    getRadius: 100,
    getLineWidth: 1,
    getElevation: 30,
    visible: layerToggleSwitches.submarineCablesVisible,
  }),
  new GeoJsonLayer({
    id: "nslk-submarineCables",
    data: NSL,
    pickable: true,
    stroked: false,
    filled: true,
    extruded: true,
    lineWidthScale: 20,
    lineWidthMinPixels: 8,
    getLineColor: hexToRgb("#F85348"),
    getRadius: 100,
    getLineWidth: 1,
    getElevation: 30,
    visible: layerToggleSwitches.submarineCablesVisible,
  }),
  new GeoJsonLayer({
    id: "ifa-submarineCables",
    data: IFA,
    pickable: true,
    stroked: false,
    filled: true,
    extruded: true,
    lineWidthScale: 20,
    lineWidthMinPixels: 8,
    getLineColor: hexToRgb("#FFC0CB"),
    getRadius: 100,
    getLineWidth: 1,
    getElevation: 30,
    visible: layerToggleSwitches.submarineCablesVisible,
  }),
  new GeoJsonLayer({
    id: "ifa-two-submarineCables",
    data: IFA2,
    pickable: true,
    stroked: false,
    filled: true,
    extruded: true,
    lineWidthScale: 20,
    lineWidthMinPixels: 8,
    getLineColor: hexToRgb("#800080"),
    getRadius: 100,
    getLineWidth: 1,
    getElevation: 30,
    visible: layerToggleSwitches.submarineCablesVisible,
  }),
  new GeoJsonLayer({
    id: "britNed-submarineCables",
    data: britNed,
    pickable: true,
    stroked: false,
    filled: true,
    extruded: true,
    lineWidthScale: 20,
    lineWidthMinPixels: 8,
    getLineColor: hexToRgb("#fa9f42"),
    getRadius: 100,
    getLineWidth: 1,
    getElevation: 30,
    visible: layerToggleSwitches.submarineCablesVisible,
  }),
  new GeoJsonLayer({
    id: "high-risk-seaOfAzov-layer",
    data: seaOfAzov,
    pickable: true,
    stroked: true,
    filled: true,
    lineWidthMinPixels: 2,
    getLineColor: hexToRgb("#FF0000"),
    getFillColor: hexToRgb("#FF0000"),
    getLineWidth: 1,
    opacity: 0.3,
    visible: layerToggleSwitches.highRisk,
  }),
  new GeoJsonLayer({
    id: "high-risk-gulfOfGuinea-layer",
    data: gulfOfGuinea,
    pickable: true,
    stroked: true,
    filled: true,
    lineWidthMinPixels: 2,
    getLineColor: hexToRgb("#FF0000"),
    getFillColor: hexToRgb("#FF0000"),
    getLineWidth: 1,
    opacity: 0.3,
    visible: layerToggleSwitches.highRisk,
  }),
  new GeoJsonLayer({
    id: "high-risk-indianOcean-layer",
    data: indianOcean,
    pickable: true,
    stroked: true,
    filled: true,
    lineWidthMinPixels: 2,
    getLineColor: hexToRgb("#FF0000"),
    getFillColor: hexToRgb("#FF0000"),
    getLineWidth: 1,
    opacity: 0.3,
    visible: layerToggleSwitches.highRisk,
  }),
  new GeoJsonLayer({
    id: "high-risk-persian-gulf-layer",
    data: persianGulf,
    pickable: true,
    stroked: true,
    filled: true,
    lineWidthMinPixels: 2,
    getLineColor: hexToRgb("#FF0000"),
    getFillColor: hexToRgb("#FF0000"),
    getLineWidth: 1,
    opacity: 0.3,
    visible: layerToggleSwitches.highRisk,
  }),
  new GeoJsonLayer({
    id: "high-risk-daboDelgado-layer",
    data: daboDelgado,
    pickable: true,
    stroked: true,
    filled: true,
    lineWidthMinPixels: 2,
    getLineColor: hexToRgb("#FF0000"),
    getFillColor: hexToRgb("#FF0000"),
    getLineWidth: 1,
    opacity: 0.3,
    visible: layerToggleSwitches.highRisk,
  }),
  new GeoJsonLayer({
    id: "territories-layer",
    data: globalTerritories,
    pickable: true,
    stroked: true,
    filled: true,
    lineWidthMinPixels: 2,
    getLineColor: hexToRgb("#285eb5"),
    getFillColor: hexToRgb("#285eb5"),
    getLineWidth: 1,
    opacity: 0.1,
    visible: layerToggleSwitches.territories,
  }),
  new GeoJsonLayer({
    id: "ports",
    data: allPorts,
    pickable: true,
    pointType: "icon",
    getIconSize: getClampedIconSize(zoomLevel, 10),
    getIconAngle: (f: any) => getIconHeading(f.properties.heading || 0),
    getIconColor: hexToRgb("#FF6B48"),
    getIcon: (f: any) => ({
      url: "./port_default.svg",
      height: 191,
      width: 102,
      mask: true,
    }),
    visible: layerToggleSwitches.ports,
  }),
  new GeoJsonLayer({
    id: "infastructure-layer",
    data: aisStations,
    pickable: true,
    pointType: "icon",
    getIconSize: getClampedIconSize(zoomLevel, 10),
    getIconAngle: (f: any) => getIconHeading(f.properties.heading || 0),
    getIconColor: hexToRgb("#36a879"),
    getIcon: (f: any) => ({
      url: "./infrastructure.svg",
      height: 191,
      width: 102,
      mask: true,
    }),
    visible: layerToggleSwitches.infrastructure,
  }),
];

const useStaticGeoJson = () => {
  const { layerToggleSwitches } = useGlobalFilters();
  const { zoomLevel } = useCurrentMapState();
  const [staticLayers, setStaticLayers] = useState<GeoJsonLayer[]>([]);

  //TODO: Modify static file to persist coords to float instead of string
  aisStations.features = aisStations.features.map((feature: any) => ({
    ...feature,
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(feature.geometry.coordinates[0]),
        parseFloat(feature.geometry.coordinates[1]),
      ],
    },
  }));

  useEffect(() => {
    setStaticLayers(getStaticLayers(zoomLevel, layerToggleSwitches));
  }, [zoomLevel, layerToggleSwitches]);

  return { staticLayers };
};

export default useStaticGeoJson;
