import { createContext, useContext, useState } from "react";
import { WorkStation } from "../common/Common.store";
import { defaultImplementation } from "../utils/utils";

interface ISelectedWorkstationProviderProps {
  children: React.ReactNode;
}

interface ISelectedWorkstationContext {
  selectedWorkstation: Partial<WorkStation>;
  setSelectedWorkstation: React.Dispatch<
    React.SetStateAction<Partial<WorkStation>>
  >;
}

const SelectedWorkstationContext = createContext<ISelectedWorkstationContext>({
  selectedWorkstation: {},
  setSelectedWorkstation: defaultImplementation,
});

const useSelectedWorkstation = () => {
  const context = useContext<ISelectedWorkstationContext>(
    SelectedWorkstationContext,
  );
  if (!context) {
    throw new Error(
      "useSelectedWorkstation must be used with a SelectedWorkstationProvider",
    );
  }
  return context;
};

export const SelectedWorkstationProvider = ({
  children,
}: ISelectedWorkstationProviderProps) => {
  const [selectedWorkstation, setSelectedWorkstation] = useState<
    Partial<WorkStation>
  >({});

  return (
    <SelectedWorkstationContext.Provider
      value={{
        selectedWorkstation,
        setSelectedWorkstation,
      }}
    >
      {children}
    </SelectedWorkstationContext.Provider>
  );
};

export default useSelectedWorkstation;
