import React, { useEffect, useRef, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { updateAllProjects } from "../../../../common/Common.store";
import { setChangeSaved } from "../../Theia.store";
import ShipFilters from "../ShipFilters/ShipFilters";
import { MAP_STYLES } from "../../../../utils/Constants";
import ListenForOutsideClicks from "../../../../common/Listen";
import {
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  FormControlLabel,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Close, ExpandMore } from "@mui/icons-material";
import styles from "./FilterArea.module.scss";
import {
  FilterLayer,
  RightSideNavigationSelectedFilter,
  MapContext,
} from "../../MainMap";
import LightView from "../../../../assets/LightView.png";
import DarkMap from "../../../../assets/icons/DarkMap";
import SatelliteMap from "../../../../assets/icons/SatelliteMap";
import {
  getColorUpdatedShipIcon,
  getColorUpdatedSanctionedShipIcon,
} from "../../../../assets/icons/ShipFilterIcons";
import useGlobalFilters from "../../../../context/useGlobalFilter";
import AccordionItem from "./AccordionItem";
import useCurrentMapState from "../../../../context/useCurrentMapState";
import { ShipIconType } from "./AccordionItem";
import { DEFAULT_COLORS } from "../../../../constants/ShipConstants";

export interface ICurrentEventTypeFilterOptions {
  [key: string]: FilterParameters;
}
export interface FilterParameters {
  checked: boolean;
  noInfoShips: boolean;
  noImoShips: boolean;
  sanctionedShips: boolean;
  markerColor: string;
  iconStringNormal?: string;
  iconStringSanctioned?: string;
  length: number[];
  width: number[];
  heading: number[];
  country: { code: string; label: string };
  subType: { [key: string]: boolean };
  type: string[];
  providerFilters: {
    [key: string]: boolean;
  };
  isMoving?: string;
  shipSubType?: string[];
}
interface IFilterAreaProps {
  currentEventTypeFilterOptions: ICurrentEventTypeFilterOptions;
  setCurrentEventTypeFilterOptions: any;
  filterLayers: FilterLayer[];
  showFilter: boolean;
  setShowFilter: React.Dispatch<React.SetStateAction<boolean>>;
  setMapStyle: React.Dispatch<React.SetStateAction<string>>;
  showGlobeView: boolean;
  isSimilarShips: boolean;
}

const iconMap = {
  ais: "AIS",
  light: "light",
  dark: "dark",
  spoofing: "spoofing",
  unattributed: "unattributed",
  similar: "similar",
  bunkering: "bunkering",
};

const FilterArea = (props: IFilterAreaProps) => {
  const {
    currentEventTypeFilterOptions,
    setCurrentEventTypeFilterOptions,
    filterLayers,
    showFilter,
    setShowFilter,
    setMapStyle,
    showGlobeView,
    isSimilarShips,
  } = props;
  const { portDataIsLoading } = useCurrentMapState();

  const dispatch = useDispatch();
  const { currentSelectedRightNavBar, onCurrentSelectedRightNavBarChange } =
    useGlobalFilters();
  const { allProjects } = useSelector((state: RootState) => state.common);
  // Renamed contextFilterType to avoid naming clash with state variable
  const [currentEventTypeFilterState, setCurrentEventTypeFilterState] =
    useState<string | null>(null);
  const { tabs, selectedTabId } = useSelector(
    (state: RootState) => state.header,
  );
  const [expanded, setExpanded] = useState<number[]>([]);
  const [listening, setListening] = useState(false);
  const menuRef: any = useRef(null);
  const [selectedStyle, setSelectedStyle] = useState(MAP_STYLES.light);

  // Initialize state for parent checkboxes
  const [dataTypesChecked, setDataTypesChecked] = useState<boolean>(true);
  const [analyticsChecked, setAnalyticsChecked] = useState<boolean>(false);

  // Get access to the map context, renaming vars to avoid clashes
  const {
    showShipLegend,
    setShowShipLegend,
    currentSelectedShipColor: contextSelectedColor,
    currentEventTypeFilter: contextFilterType, // This holds the type currently being edited
  } = useContext(MapContext);

  const dataTypes = [
    {
      id: "ais",
      label: "AIS",
      tooltipTitle: "Available in polygons or when zoomed in",
    },
    {
      id: "light",
      label: "Light",
      tooltipTitle: "Light detection",
    },
    {
      id: "dark",
      label: "Dark",
      tooltipTitle: "Dark detection",
    },
    {
      id: "unattributed",
      label: "Unattributed",
      tooltipTitle: "Unattributed ships",
    },
    // TODO: Uncomment this when sanctioned ships are ready
    // {
    //   id: "sanctioned",
    //   label: "Sanctioned",
    //   tooltipTitle: "Sanctioned ships",
    // },
  ];

  const analyticsTypes = [
    {
      id: "spoofing",
      label: "Spoofing",
      tooltipTitle: "Spoofing detection",
    },
    {
      id: "bunkering",
      label: "Ship-To-Ship",
      tooltipTitle: "Bunkering detection",
    },
    {
      id: "similar",
      label: "Similar Ships",
      tooltipTitle: "Similar ships detection",
    },
  ];

  useEffect(() => {
    if (tabs[selectedTabId]) {
      const { folderId, workStationId } = tabs[selectedTabId];
      if (allProjects[folderId]?.workStations[workStationId]?.mapState) {
        const { mapStyle } =
          allProjects[folderId].workStations[workStationId].mapState;
        if (mapStyle) {
          setSelectedStyle(mapStyle);
        }
      }
    }
  }, [selectedTabId, tabs, allProjects]); // Added dependencies

  useEffect(() => {
    const handleOutsideClick = ListenForOutsideClicks({
      listening,
      setListening,
      menuRef,
      handleOutsideClick: () => {
        setCurrentEventTypeFilterState(null);
        onCurrentSelectedRightNavBarChange();
      },
    });

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [
    listening,
    setListening,
    menuRef,
    currentSelectedRightNavBar, // Added dependency
    onCurrentSelectedRightNavBarChange,
    setCurrentEventTypeFilterState, // Changed dependency
  ]);

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      event.stopPropagation();
      setExpanded((prevExpanded) => {
        if (isExpanded) {
          return [...prevExpanded, panel];
        } else {
          return prevExpanded.filter((p) => p !== panel);
        }
      });
    };

  const currentFilter =
    currentEventTypeFilterOptions[
      currentEventTypeFilterState ? currentEventTypeFilterState : ""
    ];

  const handleUpdateShipFilters = (
    updatedFilterState: ICurrentEventTypeFilterOptions,
  ) => {
    const { folderId, workStationId } = tabs[selectedTabId];

    // Initialize default values for any missing filters
    if (currentEventTypeFilterState) {
      const defaultFilter: FilterParameters = {
        checked: false,
        noInfoShips: false,
        noImoShips: false,
        sanctionedShips: false,
        markerColor: DEFAULT_COLORS[currentEventTypeFilterState] || "#FFFFFF", // Use default color
        iconStringNormal: "",
        iconStringSanctioned: "",
        length: [0, 500],
        width: [0, 100],
        heading: [0, 360],
        country: { code: "", label: "" },
        subType: {},
        type: [],
        providerFilters: {
          sentinel1: false,
          sentinel2: false,
          planetscope: false,
        },
        isMoving: "all",
        shipSubType: [],
      };

      if (!updatedFilterState[currentEventTypeFilterState]) {
        updatedFilterState[currentEventTypeFilterState] = defaultFilter;
      } else {
        // Ensure all required properties exist
        updatedFilterState[currentEventTypeFilterState] = {
          ...defaultFilter,
          ...updatedFilterState[currentEventTypeFilterState],
        };
      }

      // set color properties for icons
      const type = currentEventTypeFilterState;
      const color = updatedFilterState[type].markerColor;

      updatedFilterState[type].iconStringNormal = getColorUpdatedShipIcon(
        color,
        type,
      );
      updatedFilterState[type].iconStringSanctioned =
        getColorUpdatedSanctionedShipIcon(color, type);

      setCurrentEventTypeFilterOptions(updatedFilterState);

      const updatedProjects = {
        ...allProjects,
        [folderId]: {
          ...allProjects[folderId],
          workStations: {
            ...allProjects[folderId].workStations,
            [workStationId]: {
              ...allProjects[folderId].workStations[workStationId],
              mapState: {
                ...allProjects[folderId].workStations[workStationId].mapState,
                mapFilters: updatedFilterState,
              },
            },
          },
        },
      };
      dispatch(setChangeSaved(false));
      dispatch(updateAllProjects(updatedProjects));
    }
  };

  const handleDataTypesChange = (checked: boolean) => {
    const dataTypeKeys = dataTypes.map((dt) => dt.id);

    const updatedFilterState = { ...currentEventTypeFilterOptions };
    dataTypeKeys.forEach((key) => {
      const defaultColor = DEFAULT_COLORS[key] || "#FFFFFF";
      if (updatedFilterState[key]) {
        updatedFilterState[key] = {
          ...updatedFilterState[key],
          checked: checked,
        };
      } else {
        // Initialize if not exists
        updatedFilterState[key] = {
          checked: checked,
          noInfoShips: false,
          noImoShips: false,
          sanctionedShips: false,
          markerColor: defaultColor,
          iconStringNormal: getColorUpdatedShipIcon(defaultColor, key),
          iconStringSanctioned: getColorUpdatedSanctionedShipIcon(
            defaultColor,
            key,
          ),
          length: [0, 500],
          width: [0, 100],
          heading: [0, 360],
          country: { code: "", label: "" },
          subType: {},
          type: [],
          providerFilters: {
            sentinel1: true,
            sentinel2: true,
            planetscope: true,
          },
          isMoving: "all",
          shipSubType: [],
        };
      }
    });

    setCurrentEventTypeFilterOptions(updatedFilterState);
    setDataTypesChecked(checked);

    // Persist changes
    const { folderId, workStationId } = tabs[selectedTabId];
    const updatedProjects = {
      ...allProjects,
      [folderId]: {
        ...allProjects[folderId],
        workStations: {
          ...allProjects[folderId].workStations,
          [workStationId]: {
            ...allProjects[folderId].workStations[workStationId],
            mapState: {
              ...allProjects[folderId].workStations[workStationId].mapState,
              mapFilters: updatedFilterState,
            },
          },
        },
      },
    };
    dispatch(setChangeSaved(false));
    dispatch(updateAllProjects(updatedProjects));
  };

  const updateCheckedFilterValue = (eventType: string, oldValue: boolean) => {
    const { folderId, workStationId } = tabs[selectedTabId];

    // Create a new filter state with proper initialization
    const updatedFilterState = { ...currentEventTypeFilterOptions };
    const defaultColor = DEFAULT_COLORS[eventType] || "#FFFFFF";

    // Initialize the filter if it doesn't exist
    if (!updatedFilterState[eventType]) {
      updatedFilterState[eventType] = {
        checked: false,
        noInfoShips: false,
        noImoShips: false,
        sanctionedShips: false,
        markerColor: defaultColor,
        iconStringNormal: getColorUpdatedShipIcon(defaultColor, eventType),
        iconStringSanctioned: getColorUpdatedSanctionedShipIcon(
          defaultColor,
          eventType,
        ),
        length: [0, 500],
        width: [0, 100],
        heading: [0, 360],
        country: { code: "", label: "" },
        subType: {},
        type: [],
        providerFilters: {
          sentinel1: false,
          sentinel2: false,
          planetscope: false,
        },
        isMoving: "all",
        shipSubType: [],
      };
    }

    // Update the checked state
    updatedFilterState[eventType] = {
      ...updatedFilterState[eventType],
      checked: !oldValue,
    };

    setCurrentEventTypeFilterOptions(updatedFilterState);

    // Update the projects state
    const updatedProjects = {
      ...allProjects,
      [folderId]: {
        ...allProjects[folderId],
        workStations: {
          ...allProjects[folderId].workStations,
          [workStationId]: {
            ...allProjects[folderId].workStations[workStationId],
            mapState: {
              ...allProjects[folderId].workStations[workStationId].mapState,
              mapFilters: updatedFilterState,
            },
          },
        },
      },
    };

    dispatch(setChangeSaved(false));
    dispatch(updateAllProjects(updatedProjects));
  };

  const handleMapClick = (style: string) => {
    setMapStyle(style);
    setSelectedStyle(style);

    // Persist map style change
    const { folderId, workStationId } = tabs[selectedTabId];
    const updatedProjects = {
      ...allProjects,
      [folderId]: {
        ...allProjects[folderId],
        workStations: {
          ...allProjects[folderId].workStations,
          [workStationId]: {
            ...allProjects[folderId].workStations[workStationId],
            mapState: {
              ...allProjects[folderId].workStations[workStationId].mapState,
              mapStyle: style,
            },
          },
        },
      },
    };
    dispatch(setChangeSaved(false));
    dispatch(updateAllProjects(updatedProjects));
  };

  const getIconForType = (type: string): ShipIconType => {
    return (
      (iconMap[type.toLowerCase() as keyof typeof iconMap] as ShipIconType) ||
      "AIS"
    );
  };

  // Updated function to get icon color, considering context for live updates
  const getIconColor = (type: string): string => {
    // If this type is the one currently being edited in ShipFilters, use the temporary color from context
    if (type === contextFilterType && contextSelectedColor) {
      return contextSelectedColor;
    }
    // Otherwise, use the color stored in the main filter options state
    const filterOption = currentEventTypeFilterOptions[type];
    const defaultColor = DEFAULT_COLORS[type] || "#FFFFFF"; // Use defined defaults or white
    return filterOption?.markerColor || defaultColor;
  };

  // Function to handle Analytics parent checkbox
  const handleAnalyticsChange = (checked: boolean) => {
    const analyticsKeys = analyticsTypes.map((at) => at.id);

    const updatedFilterState = { ...currentEventTypeFilterOptions };
    analyticsKeys.forEach((key) => {
      const defaultColor = DEFAULT_COLORS[key] || "#FFFFFF";
      if (updatedFilterState[key]) {
        updatedFilterState[key] = {
          ...updatedFilterState[key],
          checked: checked,
        };
      } else {
        // Initialize if not exists
        updatedFilterState[key] = {
          checked: checked,
          noInfoShips: false,
          noImoShips: false,
          sanctionedShips: false,
          markerColor: defaultColor,
          iconStringNormal: getColorUpdatedShipIcon(defaultColor, key),
          iconStringSanctioned: getColorUpdatedSanctionedShipIcon(
            defaultColor,
            key,
          ),
          length: [0, 500],
          width: [0, 100],
          heading: [0, 360],
          country: { code: "", label: "" },
          subType: {},
          type: [],
          providerFilters: {
            sentinel1: true,
            sentinel2: true,
            planetscope: true,
          },
          isMoving: "all",
          shipSubType: [],
        };
      }
    });

    setAnalyticsChecked(checked);
    setCurrentEventTypeFilterOptions(updatedFilterState);

    // Update the projects state
    const { folderId, workStationId } = tabs[selectedTabId];
    const updatedProjects = {
      ...allProjects,
      [folderId]: {
        ...allProjects[folderId],
        workStations: {
          ...allProjects[folderId].workStations,
          [workStationId]: {
            ...allProjects[folderId].workStations[workStationId],
            mapState: {
              ...allProjects[folderId].workStations[workStationId].mapState,
              mapFilters: updatedFilterState,
            },
          },
        },
      },
    };

    dispatch(setChangeSaved(false));
    dispatch(updateAllProjects(updatedProjects));
  };

  // Initialization effect for filter options
  useEffect(() => {
    const allFilterKeys = [
      ...dataTypes.map((dt) => dt.id),
      ...analyticsTypes.map((at) => at.id),
    ];
    let needsInitialization = false;
    const initialState = { ...currentEventTypeFilterOptions };

    allFilterKeys.forEach((key) => {
      if (!initialState[key]) {
        needsInitialization = true;
        const defaultColor = DEFAULT_COLORS[key] || "#FFFFFF";
        initialState[key] = {
          checked:
            key === "ais" ||
            key === "light" ||
            key === "dark" ||
            key === "unattributed", // Default checked for data types
          noInfoShips: false,
          noImoShips: false,
          sanctionedShips: false,
          markerColor: defaultColor,
          iconStringNormal: getColorUpdatedShipIcon(defaultColor, key),
          iconStringSanctioned: getColorUpdatedSanctionedShipIcon(
            defaultColor,
            key,
          ),
          length: [0, 500],
          width: [0, 100],
          heading: [0, 360],
          country: { code: "", label: "" },
          subType: {},
          type: [],
          providerFilters: {
            sentinel1: true,
            sentinel2: true,
            planetscope: true,
          },
          isMoving: "all",
          shipSubType: [],
        };
      }
    });

    if (needsInitialization) {
      setCurrentEventTypeFilterOptions(initialState);
      // Persist initial state if necessary (optional, depends on desired behavior)
      // const { folderId, workStationId } = tabs[selectedTabId];
      // if (folderId && workStationId) { ... update projects ... }
    }
  }, [
    currentEventTypeFilterOptions,
    setCurrentEventTypeFilterOptions,
    dataTypes,
    analyticsTypes,
    tabs,
    selectedTabId,
    allProjects,
    dispatch,
  ]); // Added dependencies

  // Parent checkbox state calculation effect
  useEffect(() => {
    const dataTypeKeys = dataTypes.map((dt) => dt.id);
    const analyticsKeys = analyticsTypes.map((at) => at.id);

    const allDataTypesChecked = dataTypeKeys.every((key) => {
      const filterOption = currentEventTypeFilterOptions[key.toLowerCase()];
      return filterOption?.checked === true;
    });

    const allAnalyticsChecked = analyticsKeys.every((key) => {
      const filterOption = currentEventTypeFilterOptions[key.toLowerCase()];
      return filterOption?.checked === true;
    });

    setDataTypesChecked(allDataTypesChecked);
    setAnalyticsChecked(allAnalyticsChecked);
  }, [currentEventTypeFilterOptions, dataTypes, analyticsTypes]); // Added dependencies

  // Cleanup effect to normalize filter options (e.g., handle 'AIS' vs 'ais')
  useEffect(() => {
    if (currentEventTypeFilterOptions.AIS) {
      const updatedFilterState = { ...currentEventTypeFilterOptions };

      // If both exist, merge them favoring the uppercase version's values, then use lowercase
      if (updatedFilterState.ais) {
        // Create a new object instead of directly modifying existing object
        const finalColor =
          updatedFilterState.AIS.markerColor ||
          updatedFilterState.ais.markerColor ||
          DEFAULT_COLORS.ais;

        updatedFilterState.ais = {
          ...updatedFilterState.ais, // Keep existing lowercase data
          ...updatedFilterState.AIS, // Overwrite with uppercase data
          markerColor: finalColor,
          iconStringNormal: getColorUpdatedShipIcon(finalColor, "ais"),
          iconStringSanctioned: getColorUpdatedSanctionedShipIcon(
            finalColor,
            "ais",
          ),
        };
      } else {
        // If only uppercase exists, create a new lowercase object
        const finalColor =
          updatedFilterState.AIS.markerColor || DEFAULT_COLORS.ais;
        updatedFilterState.ais = {
          ...updatedFilterState.AIS,
          markerColor: finalColor,
          iconStringNormal: getColorUpdatedShipIcon(finalColor, "ais"),
          iconStringSanctioned: getColorUpdatedSanctionedShipIcon(
            finalColor,
            "ais",
          ),
        };
      }

      // Remove the uppercase version
      delete updatedFilterState.AIS;

      setCurrentEventTypeFilterOptions(updatedFilterState);

      // Update projects state if needed
      if (tabs[selectedTabId]) {
        const { folderId, workStationId } = tabs[selectedTabId];
        const updatedProjects = {
          ...allProjects,
          [folderId]: {
            ...allProjects[folderId],
            workStations: {
              ...allProjects[folderId].workStations,
              [workStationId]: {
                ...allProjects[folderId].workStations[workStationId],
                mapState: {
                  ...allProjects[folderId].workStations[workStationId].mapState,
                  mapFilters: updatedFilterState,
                },
              },
            },
          },
        };
        dispatch(setChangeSaved(false));
        dispatch(updateAllProjects(updatedProjects));
      }
    }
  }, [
    currentEventTypeFilterOptions,
    setCurrentEventTypeFilterOptions,
    tabs,
    selectedTabId,
    allProjects,
    dispatch,
  ]); // Added dependencies

  return (
    <Box display="flex" flexDirection="column" className={styles.filtersMain}>
      {currentSelectedRightNavBar === RightSideNavigationSelectedFilter.Ships &&
        Object.keys(currentEventTypeFilterOptions).length > 0 && (
          <Box className={styles.filterSelector}>
            {currentEventTypeFilterState ? (
              <ShipFilters
                currentEventTypeFilter={currentEventTypeFilterState}
                setCurrentEventTypeFilter={setCurrentEventTypeFilterState} // Pass the state setter
                handleUpdateShipFilters={handleUpdateShipFilters}
                currentEventTypeFilterOptions={currentEventTypeFilterOptions}
                setCurrentEventTypeFilterOptions={
                  setCurrentEventTypeFilterOptions
                } // Pass the main options setter
              />
            ) : (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  className={styles.shipFiltersHead}
                >
                  <span>Ship Filters</span>
                  <Close
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowFilter(!showFilter);
                      setCurrentEventTypeFilterState(null); // Use state setter
                      onCurrentSelectedRightNavBarChange();
                    }}
                  />
                </Box>

                {/* Data Types Section */}
                <Accordion
                  expanded={expanded.includes(0)}
                  onChange={handleChange(0)}
                  className={styles.accordionMain}
                  classes={{ expanded: styles.accordionExpanded }}
                  data-testid="data-types-accordion"
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMore className={styles.expandMoreIcon} />
                    }
                    className={styles.accordionWrap}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={dataTypesChecked}
                          onChange={(e) =>
                            handleDataTypesChange(e.target.checked)
                          }
                          size="medium"
                          className={styles.checkboxMain}
                          onClick={(e) => e.stopPropagation()}
                          data-testid="data-types-checkbox"
                          icon={<span className={styles.uncheckedIcon} />}
                          checkedIcon={<span className={styles.checkedIcon} />}
                        />
                      }
                      label="Data Types"
                      className={styles.accordionLabel}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          marginLeft: "8px",
                          fontSize: "14px",
                        },
                      }}
                    />
                  </AccordionSummary>
                  <AccordionDetails className={styles.accordionDetail}>
                    <Box className={styles.dataTypesContent}>
                      {dataTypes.map((type) => (
                        <AccordionItem
                          key={type.id}
                          checked={
                            currentEventTypeFilterOptions[type.id]?.checked
                          }
                          onCheckboxChange={() =>
                            updateCheckedFilterValue(
                              type.id,
                              currentEventTypeFilterOptions[type.id]?.checked,
                            )
                          }
                          icon={getIconForType(type.id) || ""}
                          color={getIconColor(type.id)}
                          label={type.label}
                          tooltipTitle={type.tooltipTitle}
                          onTuneClick={() =>
                            setCurrentEventTypeFilterState(type.id)
                          } // Use state setter
                          data-testid={`${type.id}-filter-item`}
                        />
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>

                {/* Analytics Section */}
                <Accordion
                  expanded={expanded.includes(1)}
                  onChange={handleChange(1)}
                  className={styles.accordionMain}
                  classes={{ expanded: styles.accordionExpanded }}
                  data-testid="analytics-accordion"
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMore className={styles.expandMoreIcon} />
                    }
                    className={styles.accordionWrap}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={analyticsChecked}
                          onChange={(e) =>
                            handleAnalyticsChange(e.target.checked)
                          }
                          size="medium"
                          className={styles.checkboxMain}
                          onClick={(e) => e.stopPropagation()}
                          data-testid="analytics-checkbox"
                          icon={<span className={styles.uncheckedIcon} />}
                          checkedIcon={<span className={styles.checkedIcon} />}
                        />
                      }
                      label="Analytics"
                      className={styles.accordionLabel}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          marginLeft: "8px",
                          fontSize: "14px",
                        },
                      }}
                    />
                  </AccordionSummary>
                  <AccordionDetails className={styles.accordionDetail}>
                    <Box className={styles.analyticsContent}>
                      {analyticsTypes.map((type) => (
                        <AccordionItem
                          key={type.id}
                          checked={
                            currentEventTypeFilterOptions[type.id]?.checked
                          }
                          onCheckboxChange={() =>
                            updateCheckedFilterValue(
                              type.id,
                              currentEventTypeFilterOptions[type.id]?.checked,
                            )
                          }
                          icon={getIconForType(type.id) || ""}
                          color={getIconColor(type.id)}
                          label={type.label}
                          tooltipTitle={type.tooltipTitle}
                          onTuneClick={() =>
                            setCurrentEventTypeFilterState(type.id)
                          } // Use state setter
                          data-testid={`${type.id}-filter-item`}
                        />
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Box
                  className={styles.legendCheckboxContainer}
                  display="flex"
                  alignItems="center"
                  padding="12px 16px"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showShipLegend}
                        onChange={(e) => {
                          setShowShipLegend(e.target.checked);
                        }}
                        size="medium"
                        className={styles.checkboxMain}
                        onClick={(e) => e.stopPropagation()}
                        data-testid="show-legend-checkbox"
                        icon={<span className={styles.uncheckedIcon} />}
                        checkedIcon={<span className={styles.checkedIcon} />}
                      />
                    }
                    label="Show legend on map"
                    className={styles.legendCheckboxLabel}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        marginLeft: "8px",
                        fontSize: "14px",
                        color: "#FFFFFF",
                      },
                    }}
                  />
                </Box>
              </>
            )}
          </Box>
        )}
      {currentSelectedRightNavBar ===
        RightSideNavigationSelectedFilter.Layers && (
        <Box className={styles.filterSelector}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={styles.shipFiltersHead}
          >
            <span>Map Layers</span>
            <Close
              onClick={(e) => {
                e.stopPropagation();
                onCurrentSelectedRightNavBarChange();
                setCurrentEventTypeFilterState(null); // Use state setter
              }}
            />
          </Box>

          <Box className={styles.mapLayersWrap}>
            <Box
              className={styles.LayerContentWrap}
              onClick={() => handleMapClick(MAP_STYLES.dark)}
            >
              <Box
                className={`${styles.mapLayer} ${
                  selectedStyle === MAP_STYLES.dark ? styles.selectedBox : ""
                }`}
              >
                <DarkMap />
              </Box>
              <Typography className={styles.mapTitle}>Dark</Typography>
            </Box>

            <Box
              className={`${styles.LayerContentWrap} ${
                showGlobeView ? styles.disabledWrap : ""
              }`}
              onClick={() =>
                showGlobeView ? {} : handleMapClick(MAP_STYLES.satellite)
              }
            >
              <Box
                className={`${styles.mapLayer} ${
                  selectedStyle === MAP_STYLES.satellite && !showGlobeView
                    ? styles.selectedBox
                    : ""
                }`}
              >
                <SatelliteMap />
              </Box>
              <Typography className={styles.mapTitle}>Satellite</Typography>
            </Box>

            <Box
              className={styles.LayerContentWrap}
              onClick={() => handleMapClick(MAP_STYLES.light)}
            >
              <Box
                className={`${styles.mapLayer} ${
                  selectedStyle === MAP_STYLES.light ||
                  (selectedStyle === MAP_STYLES.satellite && showGlobeView)
                    ? styles.selectedBox
                    : ""
                }`}
              >
                <img
                  className={styles.LightImg}
                  src={LightView}
                  alt="LightView"
                />
              </Box>
              <Typography className={styles.mapTitle}>Light</Typography>
            </Box>
          </Box>
          <Box className={styles.layerSelector}>
            {filterLayers.map((item) => (
              <Box display="flex" alignItems="center" key={item.id}>
                {" "}
                {/* Added key */}
                <FormControlLabel
                  key={item.id} // Keep key here too for label itself
                  className={styles.layerRow}
                  control={
                    <Checkbox
                      checked={item.value}
                      onChange={item.onChange}
                      size="medium"
                      className={styles.checkboxMain} // Use standard checkbox style
                      icon={<span className={styles.uncheckedIcon} />}
                      checkedIcon={<span className={styles.checkedIcon} />}
                    />
                  }
                  label={item.label}
                  sx={{
                    // Added style for label color
                    "& .MuiFormControlLabel-label": {
                      color: "#FFFFFF",
                    },
                  }}
                />
                {item.label === "Ports" && portDataIsLoading && (
                  <CircularProgress sx={{ color: "#ffffff" }} size={18} />
                )}
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

// Debounce hook remains unchanged
export function useDebounce(cb: any, delay: any) {
  const [debounceValue, setDebounceValue] = useState(cb);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceValue(cb);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [cb, delay]);
  return debounceValue;
}

export default FilterArea;
