import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import { fetchAndActivate, getAll } from "firebase/remote-config";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import { changeLoaderState } from "../../redux/globalState.store";
import { defaultImplementation, customConsoleError } from "../../utils/utils";
import { useSnackbar } from "../SnackbarContext";
import { remoteConfig } from "../../services/firebase";

interface IRemoteConfigurationsProviderProps {
  children: ReactNode;
}

interface IRemoteConfigurationsContext {
  isFeatureEnabled: (featureFlag: string) => boolean;
}

interface IRemoteConfigurations {
  [key: string]: boolean;
}

const RemoteConfigurationsContext = createContext<IRemoteConfigurationsContext>(
  {
    isFeatureEnabled: defaultImplementation,
  },
);

export const useRemoteConfigurations = () => {
  const context = useContext<IRemoteConfigurationsContext>(
    RemoteConfigurationsContext,
  );
  if (!context) {
    throw new Error(
      "useRemoteConfigurations must be used with a RemoteConfigurationsProvider",
    );
  }
  return context;
};

export const RemoteConfigurationsProvider = ({
  children,
}: IRemoteConfigurationsProviderProps) => {
  const [remoteConfigurations, setRemoteConfigurations] = useState<
    IRemoteConfigurations | undefined
  >();
  const { setNewSnackBar } = useSnackbar();
  const dispatch = useDispatch<ThunkDispatch<RootState, void, any>>();
  const { showLoader } = useSelector(
    (state: RootState) => state.globalTheiaState,
  );

  const isFeatureEnabled = (featureFlag: string): boolean => {
    if (!remoteConfigurations) {
      if (showLoader) {
        console.log(
          "Remote Configurations are being retrieved, returning base features.",
        );
        return false;
      }
      customConsoleError(
        "Remote Configurations not found, returning base features.",
      );
      return false;
    }

    return remoteConfigurations[featureFlag] || false;
  };

  useEffect(() => {
    const fetchFeatureFlags = async () => {
      dispatch(changeLoaderState(true));
      try {
        await fetchAndActivate(remoteConfig);
        const config = getAll(remoteConfig);
        const booleanMap = {} as IRemoteConfigurations;

        // Convert all values to booleans if possible
        Object.entries(config).forEach(([key, value]) => {
          const boolValue = value.asString().toLowerCase();
          // Check if the value is 'true' or 'false'
          if (boolValue === "true" || boolValue === "false") {
            booleanMap[key] = value.asBoolean();
          }
        });

        setRemoteConfigurations(booleanMap);
      } catch (error) {
        customConsoleError("Error fetching remote configuration:", error);
        setRemoteConfigurations(undefined);
        setNewSnackBar({
          message: "Sorry something went wrong, please try again later.",
          severity: "error",
        });
      } finally {
        dispatch(changeLoaderState(false));
      }
    };

    fetchFeatureFlags();
  }, [dispatch, setNewSnackBar]);

  return (
    <RemoteConfigurationsContext.Provider
      value={{
        isFeatureEnabled,
      }}
    >
      {children}
    </RemoteConfigurationsContext.Provider>
  );
};
