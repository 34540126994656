import { DateTime, Duration } from "luxon";

export const getToolTipDeckGl = ({ layer, object }: any) => {
  return object && layer && layer?.id === "droppedPins" && object.properties
    ? {
        html: `<div>
        <p style='display: flex; align-items: center; gap: 5px; padding: 5px; color:#fff;'>Point: ${object.properties?.title}</p>
        <p style='display: flex; align-items: center; gap: 5px; padding: 5px; color:#fff;'>lat:   ${object.properties?.lat}</p>
        <p style='display: flex; align-items: center; gap: 5px; padding: 5px; color:#fff;'>lng:   ${object.properties?.lng}</p>
       </div> `,
        style: {
          fontSize: "0.8em",
          padding: "15px",
          backgroundColor: "#24263c",
          minWidth: "100px",
          gap: "5px",
        },
      }
    : {
        style: {
          display: "none",
        },
      };
};

export const defaultImplementation = () => {
  throw new Error("Function not implemented");
};

export const calculateTimeSince = (timeToCalculateFrom: string): string => {
  const currentUUTCTime = DateTime.utc();
  const formatedTime = DateTime.fromISO(timeToCalculateFrom, { zone: "utc" });

  if (!formatedTime.isValid) {
    throw new Error("Invalid timestamp format");
  }

  const diff = currentUUTCTime
    .diff(formatedTime, ["days", "hours", "minutes"])
    .toObject();

  const days = diff.days ? Math.floor(diff.days) : 0;
  const hours = diff.hours ? Math.floor(diff.hours) : 0;
  const minutes = diff.minutes ? Math.floor(diff.minutes) : 0;
  const seconds = diff.seconds ? Math.floor(diff.seconds) : 0;

  if (days >= 1) {
    return `${days}d`;
  } else if (hours >= 1) {
    return `${hours}h`;
  } else if (minutes >= 1) {
    return `${minutes}m`;
  } else {
    return `${seconds}s`;
  }
};

export const getTodaysDate = (): string => {
  return DateTime.now().toFormat("yyyy-MM-dd");
};

export const containsNumber = (string: string): boolean => {
  return /\d/.test(string);
};

export const containsUppercase = (string: string): boolean => {
  for (let i = 0; i < string.length; i++) {
    if (
      string[i] === string[i].toUpperCase() &&
      string[i] !== string[i].toLowerCase()
    ) {
      return true;
    }
  }
  return false;
};

export const containsSpecailCharacters = (password: string): boolean => {
  // Check for specail characters using NIST Special Publication 800-63B (Digital Identity Guidelines)
  const specialCharacterRegex = /.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].*/;
  return specialCharacterRegex.test(password);
};

export const formatSeconds = (seconds: number): string => {
  return Duration.fromObject({ seconds }).toFormat("m:ss");
};

export const GLOBAL_AOI = [
  [
    [-150.8203125, 73.77577986189996],
    [186.67968750000003, 73.77577986189996],
    [186.67968750000003, -59.80063426102869],
    [-150.8203125, -59.80063426102869],
    [-150.8203125, 73.77577986189996],
  ],
];
export const sortData = <T>(
  data: T[],
  comparator: (a: T, b: T) => number,
): T[] => {
  return [...data].sort(comparator);
};

// Store original console.error and create a reference for testing
let errorFunc = console.error.bind(console);

/**
 * Custom console error function that can be disabled via environment variable
 * @param message - The error message or error object to log
 * @param optionalParams - Additional parameters to log
 */
export const customConsoleError = (
  message?: any,
  ...optionalParams: any[]
): void => {
  if (process.env.REACT_APP_DISABLE_CONSOLE_ERROR !== "true") {
    // TODO: Consider integrating a logging service here to capture and push errors for monitoring.
    errorFunc(message, ...optionalParams);
  }
};

// Export for testing purposes only
export const _setErrorFunc = (func: typeof console.error) => {
  errorFunc = func;
};

// sat:7d4577ad-528f-38f7-5b51-574fcd1fc672 => 7d4577ad-528f-38f7-5b51-574fcd1fc672
export const clearHeaders = (id: string) => {
  let newId = id;
  if (newId.startsWith("sat:")) {
    newId = newId.replace("sat:", "");
  }

  return newId;
};

export const svgToDataUri = (svg: string) => {
  return `data:image/svg+xml;base64,${btoa(svg)}`;
};
