import { PathData } from "../MainMap";
import { ArrowData, Ship } from "./deckglLayers";
import { customConsoleError } from "../../../utils/utils";
import {svgToDataUri} from "../../../utils/utils";
import {AISShipSelectedSanctioned} from "../../../assets/ships/AIS/AISShipSelectedSanctioned";
import { AISShipSelected } from "../../../assets/ships/AIS/AISShipSelected";
import {LightShipSelected} from "../../../assets/ships/light/LightShipSelected";
import {LightShipSelectedSanctioned} from "../../../assets/ships/light/LightShipSelectedSanctioned";
import {DarkShipSelected} from "../../../assets/ships/dark/DarkShipSelected";
import {DarkShipSelectedSanctioned} from "../../../assets/ships/dark/DarkShipSelectedSanctioned";
import {UnattributedShipSelected} from "../../../assets/ships/unattributed/UnattributedShipSelected";
import {SpoofingShipSelected} from "../../../assets/ships/spoofing/SpoofingShipSelected";
import {SanctionedShipSelected} from "../../../assets/ships/sanctioned/SanctionedShipSelected";

export function hexToRgb(hex: string): [number, number, number] {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) return [0, 0, 0];
  const { r, g, b } = (
    result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null
  ) as any;

  return [r, g, b];
}

export const getBunkeringImageUrl = (properties: any) => {
  const { ship1Status, ship2Status } = properties;
  switch (ship1Status + ship2Status) {
    case "redblue":
      return "redBlueNew.svg";
    case "bluered":
      return "blueRedNew.svg";
    case "redred":
      return "redRedNew.svg";
    case "orangered":
      return "orangeRedNew.svg";
    case "redorange":
      return "redOrangeNew.svg";
    case "orangeblue":
      return "orangeBlueNew.svg";
    case "blueorange":
      return "blueOrangeNew.svg";
    case "orangeorange":
      return "orangeOrangeNew.svg";
    case "blueblue":
      return "blueBlueNew.svg";
    case "aisais":
      return "greenGreenNew.svg";
  }
};

export const getIndividualShipColor = (ship1Status: any) => {
  if (ship1Status === "red") {
    return "#fa5849";
  }
  if (ship1Status === "orange") {
    return "#ffa500";
  }
  if (ship1Status === "blue") {
    return "#00a3e3";
  }
  if (ship1Status === "ais") {
    return "#00eb6c";
  }
};

export const getSelectedIcon = (paths: any, properties: any) => {
  if (properties?.isPathShip) return;
  if (properties.type === "opticalSTS") {
    const { ship1Status, ship2Status, isActiveShip } = properties;

    const ship1color = getIndividualShipColor(ship1Status);
    const ship2color = getIndividualShipColor(ship2Status);

    return {
      url:
        "data:image/svg+xml;base64," +
        btoa(`<svg width="200" height="200" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="25" cy="25.5" r="25" fill="url(#paint0_radial_4053_23602)" fill-opacity="${
    isActiveShip ? "1" : "0.4"
  }"/>
  <rect x="15.5" y="16" width="9.5" height="19" fill="${ship1color}"/>
  <rect x="25" y="16" width="9.5" height="19" fill="${ship2color}"/>
  <rect x="15.5" y="16" width="19" height="19" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
  <path d="M25 16V35" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
  <defs>
  <radialGradient id="paint0_radial_4053_23602" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25 25.5) rotate(90) scale(25)">
  <stop offset="0.27" stop-color="white" stop-opacity="0"/>
  <stop offset="1" stop-color="white"/>
  </radialGradient>
  </defs>
  </svg>
  
  `),
      height: 200,
      width: 200,
      mask: false,
    };
  }

  if (properties.type === "AISSTS") {
    
    
    const { isActiveShip } = properties;
    return {
      url:
        "data:image/svg+xml;base64," +
        btoa(`<svg width="200" height="200" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="25" cy="25.5" r="25" fill="url(#paint0_radial_4053_23602)" fill-opacity="${
    isActiveShip ? "1" : "0.4"
  }"/>
  <rect x="15.5" y="16" width="9.5" height="19" fill="#00eb6c"/>
  <rect x="25" y="16" width="9.5" height="19" fill="#00eb6c"/>
  <rect x="15.5" y="16" width="19" height="19" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
  <path d="M25 16V35" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
  <defs>
  <radialGradient id="paint0_radial_4053_23602" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25 25.5) rotate(90) scale(25)">
  <stop offset="0.27" stop-color="white" stop-opacity="0"/>
  <stop offset="1" stop-color="white"/>
  </radialGradient>
  </defs>
  </svg>
  
  `),
      height: 200,
      width: 200,
      mask: false,
    };
  }

  if (properties.type === "AIS") {
    const { isActiveShip, pathColor, isSanctionedShip } = properties;
    const color =
      paths &&
      properties?.selectedShipKey &&
      paths[properties.selectedShipKey]?.color
        ? paths[properties.selectedShipKey].color
        : pathColor || "white";
    if (isSanctionedShip) {
      return {
        url:svgToDataUri(AISShipSelectedSanctioned()),
        height: 200,
        width: 200,
        mask: false,
      };
    } else {
      return {
       url:svgToDataUri(AISShipSelected()),
        height: 200,
        width: 200,
        mask: false,
      };
    }
  }

  if (properties.type === "sanctioned") {
    const { isActiveShip, pathColor } = properties;
    const color =
      paths &&
      properties?.selectedShipKey &&
      paths[properties.selectedShipKey]?.color
        ? paths[properties.selectedShipKey].color
        : pathColor || "white";

    return {
      url:svgToDataUri(SanctionedShipSelected()),
      height: 200,
      width: 200,
      mask: false,
    };
  }
  if (properties.type === "alert") {
    return {
      url:
        "data:image/svg+xml;base64," +
        btoa(`<svg width="200" height="200" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="25" cy="25.5" r="25" fill="url(#paint0_radial_3218_29324)" fill-opacity="${"1"}"/>
      <path d="M24.8236 35.5H30.6473L30.6473 30.7413C30.6472 20.0159 24.8236 15.5 24.8236 15.5C24.8236 15.5 19 20.0159 19 30.7413L19 35.5H24.8236Z" fill="${"red"}" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
      <path d="M26.8183 30.1988L26.5242 29.8976L26.878 29.5128C27.3044 29.0416 27.4955 28.6509 27.5858 28.0685C27.7069 27.2365 27.4309 26.4076 26.8349 25.7972L26.5251 25.4799L26.8263 25.1858L27.1275 24.8917L27.4137 25.1849C28.0647 25.8515 28.4229 26.7647 28.4076 27.7211C28.3923 28.6775 28.11 29.3846 27.4623 30.1112L27.1124 30.5L26.8183 30.1988Z" fill="white"/>
      <path d="M22.8312 30.1988L23.1253 29.8976L22.7714 29.5128C22.3451 29.0416 22.1539 28.6509 22.0636 28.0685C21.9426 27.2365 22.2185 26.4076 22.8145 25.7972L23.1243 25.4799L22.8231 25.1858L22.522 24.8917L22.2357 25.1849C21.5847 25.8515 21.2265 26.7647 21.2418 27.7211C21.2572 28.6775 21.5394 29.3846 22.1871 30.1112L22.5371 30.5L22.8312 30.1988Z" fill="white"/>
      <circle cx="24.8232" cy="27.75" r="2" fill="white"/>
      <defs>
      <radialGradient id="paint0_radial_3218_29324" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25 25.5) rotate(90) scale(25)">
      <stop offset="0.27" stop-color="white" stop-opacity="0"/>
      <stop offset="1" stop-color="white"/>
      </radialGradient>
      </defs>
      </svg>
      
      `),
      height: 200,
      width: 200,
      mask: false,
    };
  }

  if (properties.type === "light") {
    const { isActiveShip, isSanctionedShip, pathColor } = properties;
    const color =
      paths &&
      properties?.selectedShipKey &&
      paths[properties.selectedShipKey]?.color
        ? paths[properties.selectedShipKey].color
        : pathColor || "white";
    if (isSanctionedShip) {
      return {
        url:svgToDataUri(LightShipSelectedSanctioned(isActiveShip)),
        height: 200,
        width: 200,
        mask: false,
      };
    } else {
      return {
        url:svgToDataUri(LightShipSelected(isActiveShip)),
        height: 300,
        width: 300,
        mask: false,
      };
    }
  }

  if (properties.type === "attributed") {
    const { isActiveShip, isSanctionedShip, pathColor } = properties;
    const color =
      paths &&
      properties?.selectedShipKey &&
      paths[properties.selectedShipKey]?.color
        ? paths[properties.selectedShipKey].color
        : pathColor || "white";

    if (isSanctionedShip) {
      return {
        url:svgToDataUri(DarkShipSelectedSanctioned(isActiveShip)),
        height: 200,
        width: 200,
        mask: false,
      };
    } else {
      return {
        url:svgToDataUri(DarkShipSelected(isActiveShip)),
        height: 200,
        width: 200,
        mask: false,
      };
    }
  }

  if (properties.type === "unattributed") {
    const { isActiveShip, pathColor } = properties;
    const color =
      paths &&
      properties?.selectedShipKey &&
      paths[properties.selectedShipKey]?.color
        ? paths[properties.selectedShipKey].color
        : pathColor || "#FA5849";
    return {
      url:svgToDataUri(UnattributedShipSelected(isActiveShip)),
      height: 200,
      width: 200,
      mask: false,
    };
  }

  if (properties.type === "spoofing") {
    const { isActiveShip, pathColor } = properties;
    const color =
      paths &&
      properties?.selectedShipKey &&
      paths[properties.selectedShipKey]?.color
        ? paths[properties.selectedShipKey].color
        : pathColor || "white";

    return {
      url:svgToDataUri(SpoofingShipSelected(isActiveShip)),
      height: 200,
      width: 205,
      mask: false,
    };
  }
};

export const getSelectedShipHeading = (properties: any) => {
  if (properties.type === "light" || properties.type === "attributed") {
    return properties?.detectionData?.heading
      ? properties?.detectionData?.heading
      : properties.heading;
  }

  if (
    properties.type === "unattributed" ||
    properties.type === "sanctioned" ||
    properties.type === "AIS"
  ) {
    return properties.heading;
  }

  if (properties.type === "spoofing" || properties.type === "AISSTS") {
    return 0;
  }

  if (properties.type === "opticalSTS") {
    return properties.frontend_rotation;
  }
};

export const isPointInPolygon = (
  point: [number, number],
  polygon: { type: string; coordinates: [[number, number][]] }
): boolean => {
  const [lng, lat] = point;
  const [polyCoords] = polygon.coordinates;

  let inside = false;
  for (let i = 0, j = polyCoords.length - 1; i < polyCoords.length; j = i++) {
    const [xi, yi] = polyCoords[i];
    const [xj, yj] = polyCoords[j];

    const intersect =
      yi > lat !== yj > lat && lng < ((xj - xi) * (lat - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
};

export const calculateAngle = (
  start: [number, number],
  end: [number, number],
  heading: number
): number => {
  if (heading < 0 || heading > 360) {
    heading = calculateBearing(start, end);
  }
  return (360 - heading) % 360;
};

const degToRad = (deg: number): number => (deg * Math.PI) / 180;
const radToDeg = (rad: number): number => (rad * 180) / Math.PI;

export const calculateBearing = (
  start: [number, number],
  end: [number, number]
): number => {
  if (!start || !end || start.length !== 2 || end.length !== 2) {
    customConsoleError("Invalid start or end coordinates:", start, end);
    return 0;
  }
  const [startLon, startLat] = start?.map(degToRad);
  const [endLon, endLat] = end?.map(degToRad);

  const dLon = endLon - startLon;
  const y = Math.sin(dLon) * Math.cos(endLat);
  const x =
    Math.cos(startLat) * Math.sin(endLat) -
    Math.sin(startLat) * Math.cos(endLat) * Math.cos(dLon);
  let bearing = Math.atan2(y, x);

  bearing = radToDeg(bearing);
  return (bearing + 360) % 360;
};

export const createLayersData = (
  data: PathData[],
  showAllPoints: boolean,
  zoomLevel: number
): ArrowData[] => {
  const headingData = data?.filter((d) => d.heading !== null);
  const path: [number, number][] =
    headingData?.map((d) => [d.longitude, d.latitude]) || [];
  const step = showAllPoints
    ? 1
    : Math.max(1, Math.floor(path.length / (zoomLevel * 2)));
  const arrows: ArrowData[] = [];
  if (path.length > 1) {
    const firstPoint = path[0];
    const secondPoint = path[1];
    const firstHeading = headingData[0]?.heading ?? 0;
    arrows.push({
      position: firstPoint,
      angle: calculateAngle(firstPoint, secondPoint, firstHeading),
      size: 10,
      timestamp: headingData[0]?.timestamp,
    });
  }

  for (let i = 0; i < path.length - 1; i += step) {
    const start = path[i];
    const end = path[i + 1];

    if (end) {
      const midPoint: [number, number] = [
        (start[0] + end[0]) / 2,
        (start[1] + end[1]) / 2,
      ];

      const heading = headingData[i]?.heading ?? 0;
      arrows.push({
        position: midPoint,
        angle: calculateAngle(start, end, heading),
        size: 10,
        timestamp: headingData[i]?.timestamp,
      });
    }
  }
  if (path.length > 1) {
    const lastPoint = path.at(-1);
    if (!lastPoint) return arrows;
    const secondLastPoint = path.at(-2);
    const lastHeading = headingData[path.length - 1]?.heading ?? 0;
    arrows.push({
      position: lastPoint as [number, number],
      angle: calculateAngle(
        secondLastPoint as [number, number],
        lastPoint as [number, number],
        lastHeading
      ),
      size: 10,
      timestamp: headingData[path.length - 1]?.timestamp,
    });
  }
  return arrows;
};
export const calculateMilesBetweenTwoCoordinates = (
  lat1: number,
  lon1: number,
  lat2?: number,
  lon2?: number
): number => {
  // haversine formula for calculating distnance between 2 points
  // https://en.wikipedia.org/wiki/Haversine_formula

  const toRadians = (degrees: number): number => {
    return degrees * (Math.PI / 180);
  };

  if (!lat2 || !lon2) {
    return 0;
  }
  const R = 6371.1; // Radius of the Earth in kilometers

  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // Distance in kilometers
};

export const getIconHeading = (heading: number): number => {
  return (360 - heading) % 360;
};

export const copyCoordinatesToClipboard = (coordinates: [number, number]) => {
  const roundToSixthDecimal = (num: number) => num.toFixed(6);
  const formatCoordinates = (coords: [number, number]) => {
    const [lng, lat] = coords;
    return `${lat >= 0 ? "+" : ""}${roundToSixthDecimal(lat)}, ${
      lng >= 0 ? "+" : ""
    }${roundToSixthDecimal(lng)}`;
  };
  const text = formatCoordinates(coordinates);
  navigator.clipboard
    .writeText(text)
    .then(() => {
      console.log(`Coordinates copied: ${text}`);
    })
    .catch((err) => {
      customConsoleError("Failed to copy coordinates: ", err);
    });
};

export const splitPathAtDateLine = (path: PathData[]): [number, number][][] => {
  if (!path || path.length === 0) {
    return [];
  }

  const segments: [number, number][][] = [];
  let currentSegment: [number, number][] = [];

  path.forEach(({ longitude, latitude }) => {
    if (longitude === undefined || latitude === undefined) {
      return;
    }

    if (currentSegment.length === 0) {
      currentSegment.push([longitude, latitude]);
      return;
    }

    const prevPoint = currentSegment.at(-1);
    const prevLongitude = prevPoint?.[0];

    // Check if the path crosses the Date Line
    if (prevLongitude && Math.abs(longitude - prevLongitude) > 180) {
      segments.push(currentSegment);
      currentSegment = [[longitude, latitude]];
    } else {
      currentSegment.push([longitude, latitude]);
    }
  });

  if (currentSegment.length > 0) {
    segments.push(currentSegment);
  }

  return segments;
};

export const getCoordinatesWithinArea = (
  ship: { synmax_ship_id: string },
  area: { type: string; coordinates: [[number, number][]] } | null,
  coordinates: [number, number],
  activeShips:
    | { [key: string]: { longitude: number; latitude: number } }
    | undefined
) => {
  if (area) {
    if (coordinates.length === 2) {
      const shipInPolygon = isPointInPolygon(coordinates, area);
      if (shipInPolygon) {
        const polygonShipData = activeShips?.[ship?.synmax_ship_id];
        if (polygonShipData) {
          coordinates = [polygonShipData?.longitude, polygonShipData?.latitude];
        }
      }
    }
  }
  return coordinates;
};

/**
 * Returns a clamped icon size between 10px and 50px based on zoom level and multiplier
 * @param zoomLevel The current zoom level
 * @param multiplier The multiplier to apply to the zoom level
 * @returns The clamped icon size in pixels
 */
export const getClampedIconSize = (
  zoomLevel: number,
  multiplier: number,
): number => {
  const size = zoomLevel * multiplier;
  return Math.min(size, 125);
};

/**
 * Filters unattributed ships based on visibility and area conditions
 * @param ship - Ship object to filter
 * @param shipPaths - Object containing path information for ships
 * @param showSimilarShips - Boolean flag indicating if similar ships should be shown
 * @param area - Area object defining boundaries
 * @returns boolean indicating if the ship should be visible
 */
export const filterUnattributedShip = (
  ship: Ship,
  shipPaths: { [key: string]: any },
  showSimilarShips: boolean,
  area: { type: string; coordinates: [[number, number][]] } | null,
): boolean => {
  const unattributedShipId = `unattributed-${ship?.properties?.object_id}`;
  const shipPath = shipPaths?.[unattributedShipId];

  // Only show if showShip is explicitly true or path doesn't exist
  const isVisible = shipPath ? shipPath.showShip === true : true;

  // If we have an area and !showSimilarShips, also check if the ship is outside the area
  if (!showSimilarShips && area) {
    const isOutsideArea = !isPointInPolygon(ship.geometry.coordinates, area);
    return isVisible && isOutsideArea;
  }

  return isVisible;
};
