import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import { defaultImplementation, customConsoleError } from "../utils/utils";
import {
  Auth,
  EmailAuthProvider,
  Unsubscribe,
  User,
  onAuthStateChanged,
  reauthenticateWithCredential,
  signOut,
  updatePassword,
} from "firebase/auth";
import {
  DocumentData,
  collection,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { isEmpty } from "lodash";
import { useSnackbar } from "./SnackbarContext";
import { UserRole } from "../utils/Constants";
import { auth, db } from "../services/firebase";
import { debounce } from "lodash";

interface FirebaseAuthContextProps {
  handleSetCurrentUser: (user: DocumentData) => void;
  currentUser: DocumentData;
  isInternalUser: () => boolean;
  setUserData: Dispatch<SetStateAction<DocumentData>>;
  handleUserLogOut: () => void;
  handleAuthStateChange: () => Unsubscribe;
  getCurrentUserLoggedIn: () => User | null;
  openChangePasswordModal: () => void;
  changePasswordModalIsOpen: boolean;
  handleClosePasswordModal: () => void;
  changeUserPassword: (
    currentPassword: string,
    newPassword: string,
  ) => Promise<void>;
  checkFirstLogin: () => boolean;
  showSessionTimeoutModal: boolean;
  setShowSessionTimeoutModal: (value: boolean) => void;
  handleKeepAlive: () => void;
  extendSession: () => Promise<void>;
  secondsInactiveCounter: number;
  setSecondsInactiveCounter: (value: number) => void;
}

const DEFAULT_USER_STATE = {
  uid: "",
  email: "",
  role: "",
  isLoggedOut: true,
};

const TOKEN_EXPIRY = 60 * 60 * 1000; // 1 hour in milliseconds

// Create a BroadcastChannel for session management to enable communication between different tabs or windows
// This is necessary to ensure that session-related actions (like logout) are synchronized across all open instances of the application
// https://developer.mozilla.org/en-US/docs/Web/API/BroadcastChannel
const SESSION_CHANNEL = new BroadcastChannel("session-management");

const INACTIVITY_TIMEOUT = 3300; // 55 minutes (in seconds) before showing modal
const COUNTDOWN_DURATION = 300; // 5 minutes (in seconds) countdown before logout

const FireBaseAuthContext = createContext<FirebaseAuthContextProps>({
  handleSetCurrentUser: defaultImplementation,
  currentUser: DEFAULT_USER_STATE,
  isInternalUser: defaultImplementation,
  setUserData: defaultImplementation,
  handleUserLogOut: defaultImplementation,
  handleAuthStateChange: defaultImplementation,
  getCurrentUserLoggedIn: defaultImplementation,
  openChangePasswordModal: defaultImplementation,
  changePasswordModalIsOpen: false,
  handleClosePasswordModal: defaultImplementation,
  changeUserPassword: defaultImplementation,
  checkFirstLogin: defaultImplementation,
  showSessionTimeoutModal: false,
  setShowSessionTimeoutModal: defaultImplementation,
  handleKeepAlive: defaultImplementation,
  extendSession: defaultImplementation,
  secondsInactiveCounter: 0,
  setSecondsInactiveCounter: defaultImplementation,
});

interface TokenManager {
  clear: () => void;
  protectedTokenRefresh: (user: User) => Promise<string | undefined>;
}

const createTokenManager = (): TokenManager => {
  const isRefreshingToken = { current: false };

  const clear = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenTimestamp");
  };

  const protectedTokenRefresh = async (user: User) => {
    if (isRefreshingToken.current) {
      return; // Exit if a refresh is already in progress
    }

    try {
      isRefreshingToken.current = true;
      const token = await user.getIdToken(true);
      localStorage.setItem("token", token);
      localStorage.setItem("tokenTimestamp", Date.now().toString());
      return token;
    } finally {
      isRefreshingToken.current = false;
    }
  };

  return { clear, protectedTokenRefresh };
};

const useFireBaseAuth = () => {
  const context = useContext<FirebaseAuthContextProps>(FireBaseAuthContext);
  const { setNewSnackBar } = useSnackbar();
  const tokenManager = createTokenManager();
  const isHandlingActivityRef = useRef(false);

  if (!context) {
    throw new Error("useFireBaseAuth must be used with a FireBaseAuthProvider");
  }

  const getCurrentUserLoggedIn = useCallback(() => {
    const token = localStorage.getItem("token");

    if (!token || !auth.currentUser) {
      return null;
    }

    return auth.currentUser;
  }, []);

  const handleAuthStateChange = useCallback(() => {
    return onAuthStateChanged(auth, async (user) => {
      if (!user) {
        context.handleSetCurrentUser(clearUserSession());
        return;
      }

      try {
        const userData = await fetchUserData(user.uid);
        if (!userData) {
          context.handleSetCurrentUser(clearUserSession());
          return;
        }

        if (isUserAuthorized(userData)) {
          await setupAuthorizedUser(user, userData, context);
        } else {
          await handleUnauthorizedUser(auth, context);
        }
      } catch (error) {
        context.handleSetCurrentUser(clearUserSession());
        customConsoleError("Auth state change error:", error);
      }
    });
  }, [context]);

  const clearUserSession = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isMFAVerified");
    localStorage.removeItem("tokenTimestamp");
    return {
      ...DEFAULT_USER_STATE,
      isLoggedOut: true,
    };
  };

  const fetchUserData = async (uid: string) => {
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  };

  const isUserAuthorized = (userData: DocumentData) => {
    const role = userData?.role;
    return (
      [
        UserRole.SUPER_USER,
        UserRole.ADMIN,
        UserRole.CO_WORKER,
        UserRole.THEIA_CUSTOMER,
      ].includes(role as UserRole) ||
      (role === UserRole.TEMPORARY_THEIA_USER &&
        userData?.expiry_date?.seconds &&
        userData.expiry_date.seconds > Date.now() / 1000)
    );
  };

  const setupAuthorizedUser = async (
    user: User,
    userData: DocumentData,
    context: FirebaseAuthContextProps,
  ) => {
    await tokenManager.protectedTokenRefresh(user);
    context.handleSetCurrentUser({
      ...user,
      ...userData,
      isLoggedOut: false,
    });
  };

  const handleUnauthorizedUser = async (
    auth: Auth,
    context: FirebaseAuthContextProps,
  ) => {
    setNewSnackBar({
      message: "Signing out...",
      severity: "error",
    });
    console.log("Unauthorized role - signing out");
    await auth.signOut();
    context.handleSetCurrentUser(clearUserSession());
  };

  const calculateTimeLeft = (tokenTimestamp: string | null): number => {
    if (!tokenTimestamp) return 0;
    const now = Date.now();
    return TOKEN_EXPIRY - (now - Number(tokenTimestamp));
  };

  const handleTokenExpiration = (
    timeLeft: number,
    context: FirebaseAuthContextProps,
  ) => {
    if (timeLeft <= 0) {
      localStorage.removeItem("token");
      localStorage.removeItem("tokenTimestamp");
      localStorage.removeItem("isMFAVerified");
      context.handleUserLogOut();
    }
  };

  useEffect(() => {
    const checkTokenExpiration = async () => {
      const token = localStorage.getItem("token");
      if (!token) return;

      const tokenTimestamp = localStorage.getItem("tokenTimestamp");
      const timeLeft = calculateTimeLeft(tokenTimestamp);
      handleTokenExpiration(timeLeft, context);
    };

    // Check immediately and then every 10 seconds
    checkTokenExpiration();
    const interval = setInterval(checkTokenExpiration, 10 * 1000);

    return () => clearInterval(interval);
  }, [context]);

  return {
    ...context,
    getCurrentUserLoggedIn,
    handleAuthStateChange,
  };
};

const FIVE_MINUTES_REMAINING = 3300; // Five minutes subtracted from one hour (55 minutes in seconds)
const FIVE_MINUTES = 300; // Five minutes in seconds

export const FireBaseAuthProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { setNewSnackBar } = useSnackbar();
  const tokenManager = createTokenManager();

  const getCurrentUserLoggedIn = useCallback((): User | null => {
    const isloggedIn = auth.currentUser;
    return isloggedIn;
  }, []);

  const currentUserIsLoggedIn = useMemo(
    () => getCurrentUserLoggedIn(),
    [getCurrentUserLoggedIn],
  );

  const [userData, setUserData] = useState<DocumentData>({});

  const [changePasswordModalIsOpen, setChangePasswordModalIsOpen] =
    useState<boolean>(false);

  const openChangePasswordModal = () => {
    setChangePasswordModalIsOpen(true);
  };

  const handleClosePasswordModal = () => {
    setChangePasswordModalIsOpen(false);
  };

  const isMFAVerified = localStorage.getItem("isMFAVerified");

  const [showSessionTimeoutModal, setShowSessionTimeoutModal] =
    useState<boolean>(false);

  const [secondsInactiveCounter, setSecondsInactiveCounter] =
    useState(COUNTDOWN_DURATION);
  const [isInactive, setIsInactive] = useState(false);
  const isHandlingActivityRef = useRef(false);

  const calculateTimeLeft = (tokenTimestamp: string | null): number => {
    if (!tokenTimestamp) return 0;
    const now = Date.now();
    return TOKEN_EXPIRY - (now - Number(tokenTimestamp));
  };

  const handleUserLogOut = useCallback(() => {
    SESSION_CHANNEL.postMessage({ type: "logout" });
    signOut(auth)
      .then(() => {
        setUserData(DEFAULT_USER_STATE);
        window.location.href = "/login";
        localStorage.setItem("isMFAVerified", "false");
      })
      .catch((error) => {
        customConsoleError("Error during logout:", error);
      });
  }, []);

  const resetInactivityTimer = useCallback(() => {
    setIsInactive(false);
    setSecondsInactiveCounter(COUNTDOWN_DURATION);
  }, []);

  // Create a stable version of extendSession
  const stableExtendSession = useCallback(async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        await tokenManager.protectedTokenRefresh(user);
      } catch (error) {
        customConsoleError("Error extending session:", error);
      }
    }
  }, [tokenManager]);

  const handleActivity = useCallback(async () => {
    if (isHandlingActivityRef.current) return;
    isHandlingActivityRef.current = true;

    try {
      resetInactivityTimer();
      await stableExtendSession();
      // Broadcast activity with timestamp to sync across tabs
      SESSION_CHANNEL.postMessage({
        type: "activity",
        timestamp: Date.now(),
      });
    } catch (error) {
      customConsoleError("Error extending session:", error);
    } finally {
      isHandlingActivityRef.current = false;
    }
  }, [stableExtendSession, resetInactivityTimer]);

  const debouncedHandleActivity = useMemo(
    () => debounce(handleActivity, FIVE_MINUTES),
    [handleActivity],
  );

  /**
   * Manages the inactivity detection system by monitoring user interactions.
   * Shows warning modal after 55 minutes of inactivity (FIVE_MINUTES_REMAINING).
   * If user doesn't respond within 5 minutes or doesn't click "Stay Logged In":
   * - At 60-minute mark: Auto logout
   * If user clicks "Stay Logged In":
   * - Resets inactivity timer back to 55 minutes
   * - Refreshes token for another hour
   */
  useEffect(() => {
    let timeout: number | null = null;

    const startTimer = () => {
      if (timeout) window.clearTimeout(timeout);
      timeout = window.setTimeout(() => {
        // Only show the modal if there's been no activity
        if (!isHandlingActivityRef.current) {
          setShowSessionTimeoutModal(true);
          setSecondsInactiveCounter(COUNTDOWN_DURATION);
          setIsInactive(true);
          // Broadcast timeout modal state to other tabs
          SESSION_CHANNEL.postMessage({
            type: "timeout_modal",
            show: true,
            timestamp: Date.now(),
          });
        }
      }, INACTIVITY_TIMEOUT * 1000); // Show modal after 20 seconds of inactivity
    };

    const handleUserActivity = () => {
      setIsInactive(false);
      setShowSessionTimeoutModal(false); // Hide modal if it's showing
      debouncedHandleActivity();
      startTimer(); // Reset inactivity timer back to 20 seconds
      // Broadcast activity to other tabs
      SESSION_CHANNEL.postMessage({
        type: "activity",
        timestamp: Date.now(),
      });
    };

    // Monitor user interactions
    window.addEventListener("click", handleUserActivity);
    window.addEventListener("keypress", handleUserActivity);
    window.addEventListener("scroll", handleUserActivity);

    startTimer(); // Initial timer start

    // Handle messages from other tabs
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === "activity") {
        // Reset inactivity state and timer when activity is detected in other tabs
        setIsInactive(false);
        setShowSessionTimeoutModal(false);
        setSecondsInactiveCounter(COUNTDOWN_DURATION);
        startTimer(); // Reset the inactivity timer
      } else if (event.data.type === "timeout_modal") {
        // Synchronize timeout modal state across tabs
        setShowSessionTimeoutModal(event.data.show);
        setSecondsInactiveCounter(COUNTDOWN_DURATION);
        setIsInactive(true);
      } else if (event.data.type === "logout") {
        setUserData(DEFAULT_USER_STATE);
        window.location.href = "/login";
      }
    };

    SESSION_CHANNEL.addEventListener("message", handleMessage);

    return () => {
      if (timeout) window.clearTimeout(timeout);
      window.removeEventListener("click", handleUserActivity);
      window.removeEventListener("keypress", handleUserActivity);
      window.removeEventListener("scroll", handleUserActivity);
      SESSION_CHANNEL.removeEventListener("message", handleMessage);
    };
  }, [debouncedHandleActivity, setUserData]);

  /**
   * useEffect hook to manage a countdown timer that initiates when the user becomes inactive.
   * The countdown lasts for 300 seconds (5 minutes) and decrements every second.
   * If the countdown reaches zero, it triggers an automatic logout.
   * The countdown is aborted if the user becomes active again.
   *
   */
  useEffect(() => {
    const abortController = new AbortController();
    let interval: number | null = null;

    const startCountdown = () => {
      if (abortController.signal.aborted) return;

      interval = window.setInterval(() => {
        if (abortController.signal.aborted) {
          if (interval) window.clearInterval(interval);
          return;
        }

        setSecondsInactiveCounter((prev) => {
          const newValue = prev <= 1 ? 0 : prev - 1;
          if (newValue === 0) {
            if (interval) window.clearInterval(interval);
            handleUserLogOut();
            setShowSessionTimeoutModal(false);
          }
          return newValue;
        });
      }, 1000);
    };

    if (isInactive) {
      startCountdown();
    } else if (interval) {
      window.clearInterval(interval);
    }

    return () => {
      abortController.abort();
      if (interval) window.clearInterval(interval);
    };
  }, [isInactive, handleUserLogOut]);

  useEffect(() => {
    if (secondsInactiveCounter === 0) {
      handleUserLogOut();
      setShowSessionTimeoutModal(false);
    }
  }, [secondsInactiveCounter, handleUserLogOut]);

  const handleKeepAlive = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        await tokenManager.protectedTokenRefresh(user);
        setShowSessionTimeoutModal(false);
        setIsInactive(false);
        setSecondsInactiveCounter(COUNTDOWN_DURATION);
      } catch (error) {
        customConsoleError("Error refreshing token:", error);
        handleUserLogOut();
      }
    }
  };

  const refreshToken = useCallback(async () => {
    if (currentUserIsLoggedIn !== null) {
      // Check if token refresh is actually needed (less than 10 minutes until expiry)
      const tokenTimestamp = localStorage.getItem("tokenTimestamp");
      const timeLeft = calculateTimeLeft(tokenTimestamp);
      if (timeLeft < 600000) {
        // Only refresh if less than 10 minutes remaining
        await stableExtendSession();
      }
    }
  }, [currentUserIsLoggedIn, stableExtendSession, calculateTimeLeft]);

  useEffect(() => {
    const tokenInterval = setInterval(refreshToken, 300000); // 5 minutes
    return () => clearInterval(tokenInterval);
  }, [refreshToken]);

  const setUserAsNotNew = async () => {
    const user = auth.currentUser;
    if (user !== null) {
      const docRef = doc(db, "users", user.uid);
      await updateDoc(docRef, {
        isNewUser: false,
      });
    }
  };

  const authenticateUserPassword = async (
    currentPassword: string,
  ): Promise<void> => {
    const user = auth.currentUser;
    try {
      if (user !== null && user.email) {
        const credential = EmailAuthProvider.credential(
          user.email,
          currentPassword,
        );

        // Reauthenticate with the provided password
        await reauthenticateWithCredential(user, credential);
      }
    } catch (error) {
      setNewSnackBar({
        message:
          "We are sorry, we were unable to validate your current password",
        severity: "error",
      });
      throw error;
    }
  };

  const updateUserPassword = async (newPassword: string): Promise<void> => {
    const user = auth.currentUser;
    try {
      if (user !== null) {
        await updatePassword(user, newPassword);
        setNewSnackBar({
          message: "Password has been updated",
          severity: "success",
        });
      }
    } catch (error) {
      setNewSnackBar({
        message: "We are sorry there was an error updating the password",
        severity: "error",
      });
      throw error;
    }
  };

  const changeUserPassword = async (
    currentPassword: string,
    newPassword: string,
  ): Promise<void> => {
    try {
      await authenticateUserPassword(currentPassword);
      await setUserAsNotNew();
      await updateUserPassword(newPassword);
    } catch (error) {
      customConsoleError("Error in changeUserPassword", error);
    }
  };

  const checkFirstLogin = (): boolean => {
    if (userData?.isNewUser) {
      return true;
    } else {
      return false;
    }
  };

  const initializeNewUser = async (uid: string, email: string) => {
    await setDoc(doc(db, "users", uid), {
      email,
      role: "new_user",
      firstName: "",
      lastName: "",
      isNewUser: true,
    });
  };

  const handleUserAccess = (role: string, userData: DocumentData) => {
    if (
      role === UserRole.SUPER_USER ||
      role === UserRole.ADMIN ||
      role === UserRole.CO_WORKER ||
      role === UserRole.THEIA_CUSTOMER ||
      (role === UserRole.TEMPORARY_THEIA_USER &&
        userData.expiry_date.seconds > Date.now() / 1000)
    ) {
      return true;
    }
    return false;
  };

  const handleUnauthorizedAccess = async () => {
    setNewSnackBar({
      message: "Email info@synmax.com For Access",
      severity: "error",
    });
    if (auth.currentUser) {
      await signOut(auth);
    }
  };

  const handleSetCurrentUser = async (user: any) => {
    if (!user || user.isLoggedOut) {
      setUserData(DEFAULT_USER_STATE);
      return;
    }

    const { uid, email } = user;
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);
    const userData = docSnap.data() || {};

    setUserData({ ...userData, uid });

    if (auth.currentUser) {
      await tokenManager.protectedTokenRefresh(auth.currentUser);
    }

    if (uid && (!userData || isEmpty(userData))) {
      await initializeNewUser(uid, email);
      return;
    }

    const role = userData?.role || "";

    if (handleUserAccess(role, userData)) {
      return; // Token is already handled by protectedTokenRefresh above
    }

    await handleUnauthorizedAccess();
  };

  const isInternalUser = () => {
    const superRoles = ["super_user", "admin", "co-worker"];
    return superRoles.includes(userData?.role || "");
  };

  const isUserSessionValid = (
    userData: DocumentData,
    isMFAVerified: string | null,
  ): boolean => {
    return !!(
      userData &&
      userData.uid &&
      userData?.role &&
      userData?.role !== "new_user" &&
      isMFAVerified !== "false" &&
      !userData?.isNewUser
    );
  };

  const handleUserSecretCheck = async (
    userDocData: DocumentData | undefined,
  ) => {
    if (!userDocData?.secret) {
      try {
        await signOut(auth);
        setUserData({});
        window.location.href = "/login";
      } catch (error) {
        console.log(error, "err");
      }
    }
  };

  // This effect depends on userData to monitor user authorization status.
  // It re-runs whenever userData changes to ensure the user's session is managed correctly.
  useEffect(() => {
    if (!isUserSessionValid(userData, isMFAVerified)) return;

    const userCollectionRef = collection(db, "users");
    const userDocRef = doc(userCollectionRef, userData.uid);
    const userUnsubscribe = onSnapshot(userDocRef, (snapshot) => {
      const userDocData = snapshot.data();
      if (!userDocData) return;
      handleUserSecretCheck(userDocData);
    });

    return () => {
      userUnsubscribe();
    };
  }, [userData, isMFAVerified]);

  const handleAuthStateChange = () => {
    return onAuthStateChanged(auth, handleSetCurrentUser);
  };

  return (
    <FireBaseAuthContext.Provider
      value={{
        handleSetCurrentUser,
        currentUser: userData,
        isInternalUser,
        setUserData,
        handleUserLogOut,
        handleAuthStateChange,
        getCurrentUserLoggedIn,
        openChangePasswordModal,
        changePasswordModalIsOpen,
        handleClosePasswordModal,
        changeUserPassword,
        checkFirstLogin,
        showSessionTimeoutModal,
        setShowSessionTimeoutModal,
        handleKeepAlive,
        extendSession: stableExtendSession,
        secondsInactiveCounter,
        setSecondsInactiveCounter,
      }}
    >
      {children}
    </FireBaseAuthContext.Provider>
  );
};

export default useFireBaseAuth;
