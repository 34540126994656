import { Divider } from "@mui/material";
import styles from "./SearchOptionRow.module.scss";

interface SearchOptionRowProps {
  columns: { title: string; data: string | number }[];
  rowHeader: React.ReactElement;
  props: {
    key?: string;
    [key: string]: any;
  };
  searchValue: any;
  isAISSearch: boolean;
  loadShipsOnMap: (
    isAISSearch: boolean,
    searchValue: any,
    date?: string,
  ) => void;
  date?: string;
}

const SearchOptionRow = ({
  columns,
  rowHeader,
  props: { key, ...restProps },
  searchValue,
  isAISSearch,
  loadShipsOnMap,
  date,
}: SearchOptionRowProps) => {
  return (
    <li
      {...restProps}
      className={styles.searchListItem}
      onClick={() => loadShipsOnMap(isAISSearch, searchValue, date)}
    >
      <div className={styles.searchItemRow}>
        <div className={styles.searchItemTitle}>{rowHeader}</div>
        <div className={styles.searchItemRowData}>
          {columns.map((column, index) => (
            <div
              key={`${column.title}-${index}`}
              className={styles.searchColumn}
            >
              <div className={styles.searchRowTitle}>{column.title}</div>
              <div className={styles.searchRowData}>
                {column.data === null ? "No Info" : column.data}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Divider className={styles.searchItemDivider} orientation="horizontal" />
    </li>
  );
};

export default SearchOptionRow;
