import { Box, styled } from "@mui/material";
import useShipDetails from "../../../../context/useShipDetails";
import {
  SHIP_DETAIL_EXPANDED_LEFT,
  SIDEBAR_EXPANDED_WIDTH,
} from "../../../../utils/Constants";

const HoveredCoordinates = styled(Box, {
  shouldForwardProp: (prop) => prop !== "leftAlign",
})<{ leftAlign: number }>(({ leftAlign }) => ({
  display: "flex",
  alignItems: "center",
  position: "absolute",
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  background: "#24263c",
  backdropFilter: "blur(25px)",
  zIndex: 90,
  left: leftAlign,
  bottom: 140,
  borderRadius: "5px",
  padding: "6px",
}));

const Coordinate = styled(Box)(() => ({
  minWidth: "70px",
}));

const roundToSixthDecimal = (num: number): number => {
  return Math.round(num * 1_000_000) / 1_000_000;
};

// Normalize longitude to [-180, 180] range
// 1. Add 180 to shift range to [0, 360]
// 2. Use modulo 360 to wrap around full circles
// 3. Add 360 and modulo again to handle negative values
// 4. Subtract 180 to shift back to [-180, 180] range
export const normalizeLongitude = (longitude: number): number => {
  return ((((longitude + 180) % 360) + 360) % 360) - 180;
};

// Clamp latitude to [-90, 90] range
// Uses Math.min/max to ensure latitude stays within valid range:
// - Math.max ensures it's not less than -90 degrees
// - Math.min ensures it's not more than 90 degrees
export const normalizeLatitude = (latitude: number): number => {
  return Math.max(-90, Math.min(90, latitude));
};

// Normalize coordinates to valid ranges
export const normalizeCoordinates = (
  coordinates: [number, number],
): [number, number] => {
  return [
    normalizeLongitude(coordinates[0]),
    normalizeLatitude(coordinates[1]),
  ];
};

const UserHoveredCoordinates: React.FC<{
  currentUserHoverCoordinates: [number, number];
}> = ({ currentUserHoverCoordinates }) => {
  const { shipCollapsed, collapsed } = useShipDetails();

  const getLeftAlignment = (): number => {
    let leftAlign = 140;

    if (shipCollapsed) {
      leftAlign += SHIP_DETAIL_EXPANDED_LEFT;
    }

    if (collapsed) {
      leftAlign += SIDEBAR_EXPANDED_WIDTH;
    }

    return leftAlign;
  };

  return (
    <HoveredCoordinates leftAlign={getLeftAlignment()}>
      <Coordinate>
        {currentUserHoverCoordinates[1] > 0 ? "+" : ""}
        {roundToSixthDecimal(currentUserHoverCoordinates[1])}
      </Coordinate>
      <Coordinate>
        {currentUserHoverCoordinates[0] > 0 ? "+" : ""}
        {roundToSixthDecimal(currentUserHoverCoordinates[0])}
      </Coordinate>
    </HoveredCoordinates>
  );
};

export default UserHoveredCoordinates;
