// React imports
import { useEffect, useState } from "react";
// React Router imports
import { Navigate, useRoutes } from "react-router-dom";
// Components
import Admin from "../pages/Admin/Admin";
import AuthWrapper from "../pages/Auth/AuthWrapper";
import ChangePasswordSignIn from "../components/ChangePasswordSignIn/ChangePasswordSignIn";
import EmailCheck from "../pages/Auth/EmailCheck/EmailCheck";
import ForgetPassword from "../pages/Auth/ForgetPassword/ForgetPassword";
import LayoutWrapper from "../layout/LayoutWrapper/LayoutWrapper";
import MFA from "../pages/MFA/MFA";
import MFAWrapper from "../pages/MFA/MFAWrapper";
import SignIn from "../pages/Auth/SignIn/SignIn";
import SignUp from "../pages/Auth/SignUp/SignUp";
import Theia from "../pages/Theia/Theia";
import UpdateForgottenPassword from "../components/UpdateForgottenPassword/UpdateForgottenPassword";
// Hooks
import useFireBaseAuth from "../context/useFireBaseAuth";
import { useDispatch } from "react-redux";
import { changeLoaderState } from "../redux/globalState.store";
import { customConsoleError } from "../utils/utils";

export default function Routes() {
  const { getCurrentUserLoggedIn } = useFireBaseAuth();
  const [isVerifying, setIsVerifying] = useState(true);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const currentLoggedInUser = getCurrentUserLoggedIn();
  const { currentUser } = useFireBaseAuth();
  const isMFAVerified = localStorage.getItem("isMFAVerified");
  const { role } = currentUser;
  const dispatch = useDispatch();

  // Only verify on mount and when auth state changes
  useEffect(() => {
    const verify = async () => {
      dispatch(changeLoaderState(true));
      try {
        if (currentLoggedInUser) {
          setIsTokenValid(true);
        } else {
          setIsTokenValid(false);
        }
      } catch (error) {
        customConsoleError("Token verification failed:", error);
        setIsTokenValid(false);
      } finally {
        setIsVerifying(false);
        dispatch(changeLoaderState(false));
      }
    };
    verify();
  }, [currentLoggedInUser, dispatch]);

  const routes = useRoutes([
    {
      element: <AuthWrapper />,
      children: [
        {
          path: "login",
          element:
            currentLoggedInUser && isTokenValid ? (
              isMFAVerified === "true" ? (
                <Navigate to="/theia" />
              ) : (
                <Navigate to="/mfa" />
              )
            ) : (
              <SignIn />
            ),
        },
        { path: "register", element: <SignUp /> },
        { path: "resend-email", element: <EmailCheck /> },
        {
          path: "change-password",
          element: <ChangePasswordSignIn />,
        },
        {
          path: "forgot-password",
          element: <ForgetPassword />,
        },
        {
          path: "update-forgot-password",
          element: <UpdateForgottenPassword />,
        },
        ...(role === "admin"
          ? [
              {
                path: "admin",
                element: <Admin />,
              },
            ]
          : []),
      ],
    },
    {
      element: <MFAWrapper />,
      children: [{ path: "mfa", element: <MFA /> }],
    },
    {
      element: <LayoutWrapper />,
      children: [
        {
          path: "theia",
          element:
            !currentLoggedInUser || !isTokenValid ? (
              <Navigate to="/login" />
            ) : isMFAVerified === "true" ? (
              <Theia />
            ) : (
              <Navigate to="/mfa" />
            ),
        },
      ],
    },
    { path: "*", element: <Navigate to="/login" /> },
  ]);

  if (isVerifying) {
    return null; // Return null since the global loader will be shown
  }

  return routes;
}
