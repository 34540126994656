import React, { useEffect, useState } from "react";
import styles from "./EventTimeline.module.scss";
import { Box, Button, CircularProgress, MenuItem } from "@mui/material";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { EventData } from "../ShipDetails";
import { getEvents } from "../../RenderShips/apiCalls";
import moment from "moment";

const EventTimeline = ({
  tabId,
  handleViewEventClick,
}: {
  tabId: string;
  handleViewEventClick: (event: EventData) => void;
}) => {
  const [timeValue, setTimeValue] = useState("maxTime");
  const [eventType, setEventType] = useState("allEvent");
  const [eventsData, setEventsData] = useState<EventData[]>([]);
  const [filteredData, setFilteredData] = useState<EventData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const icon = () => {
    return <KeyboardArrowDownIcon />;
  };

  const processEvents = async (data: any) => {
    const footprintArray =
      data?.["footprint"]?.results?.map((item: any) => ({
        ...item,
        timestamp: moment(item?.day).unix() * 1000,
      })) ?? [];
    const spoofingArray =
      data?.["spoofing"]?.results?.map((mainObj: any) => {
        const secondObj = footprintArray?.find(
          (secondItem: any) => secondItem.timestamp === mainObj.timestamp,
        );
        return secondObj ? { ...mainObj, ...secondObj } : mainObj;
      }) ?? [];

    const allEvents = [
      ...(data?.["ais-dark"]?.results?.map((event: any) => {
        const timeDiffInSeconds = event?.time_diff;
        const timeDiff = moment.duration(timeDiffInSeconds, "seconds");
        const hours = timeDiff.hours();
        const minutes = timeDiff.minutes();
        const updatedTimeDiff = `${hours}h ${minutes}m`;
        return {
          event: "AIS Dark",
          timestamp: moment(event.timestamp_t0 * 1000).toISOString(),
          timestamp2: moment(event.timestamp_t1 * 1000).toISOString(),
          status: event.status,
          timeDiff: updatedTimeDiff,
          latitude_t0: event.latitude_t0,
          latitude_t1: event.latitude_t1,
          longitude_t0: event.longitude_t0,
          longitude_t1: event.longitude_t1,
          synmax_ship_id: event.synmax_ship_id,
        };
      }) ?? []),
      ...(data?.["ais-port-calls"]?.results?.map((event: any) => {
        const durationInSeconds = event?.duration;
        const duration = moment.duration(durationInSeconds, "seconds");
        const hours = duration.hours();
        const minutes = duration.minutes();
        const updatedDuration = `${hours}h ${minutes}m`;
        return {
          event: "Port of Calls",
          timestamp: moment(event.timestamp_t0 * 1000).toISOString(),
          port: event.port_name,
          mmsi: event.mmsi,
          status: event.status,
          duration: updatedDuration,
        };
      }) ?? []),
      ...(data?.["optical-sts"]?.results?.map((event: any) => {
        const mappedEvent = {
          event: "Bunkering",
          type: "Optical Detection",
          timestamp: moment(event.acquired).toISOString(),
          acquired: event.acquired,
          day: event.acquired,
          object_id: event?.ship1_object_id,
          detectionData: {
            acquired: event.acquired,
            timestamp: event.acquired,
            length: event?.length,
            width: event?.width,
            ship_type: event?.ship_type,
          },
          isObservationShip: false,
          ship2Attri: event?.ship2_attribution,
          ship2ObjId: event?.ship2_object_id,
          ship2Dark: event?.ship2_dark,
          ship1Attri: event?.ship1_attribution,
          ship1ObjId: event?.ship1_object_id,
          ship1Dark: event?.ship1_dark,
          bunkeringId: event?.bunkering_id,
          frontendRotation: event?.frontend_rotation,
          longitude: event?.ship1_lon,
          latitude: event?.ship1_lat,
          longitude2: event?.ship2_lon,
          latitude2: event?.ship2_lat,
        };
        return mappedEvent;
      }) ?? []),
      ...(data?.["ais-sts"]?.results?.map((event: any) => ({
        event: "Bunkering",
        type: "AIS Detection",
        timestamp: moment(event.timestamp_t0 * 1000).toISOString(),
        status: event.status,
        longitude: event.longitude,
        latitude: event.latitude,
        ship1Attri: event?.synmax_ship_id_1,
        ship2Attri: event?.synmax_ship_id_2,
      })) ?? []),
      ...(spoofingArray?.map((event: any) => ({
        event: "Spoofing",
        type: event.f
          ? "Footprint"
          : event.o
            ? "Optical"
            : event.a
              ? "AIS"
              : event.m // analyst created manual event
                ? "AIS+"
                : "Unknown",
        timestamp: moment(event.timestamp).toISOString(), // it gives time in milliseconds only
        longitude: event.longitude,
        latitude: event.latitude,
        synmax_ship_id: event.synmax_ship_id,
      })) ?? []),
    ];
    return allEvents.sort((a, b) => {
      return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const eventTypes = [
        "ais-dark",
        "optical-sts",
        "ais-sts",
        "ais-port-calls",
        "spoofing",
        "footprint",
      ];
      const eventsDataArray = await Promise.all(
        eventTypes.map((type) =>
          getEvents({ event_types: [type], ship_ids: [tabId] }),
        ),
      );
      const combinedEventsData = eventsDataArray.reduce((acc, curr) => {
        return { ...acc, ...curr };
      }, {});
      const sortedEvents = await processEvents(combinedEventsData);
      setEventsData(sortedEvents);
      setLoading(false);
    };
    setEventsData([]);
    fetchData();
  }, [tabId]);

  useEffect(() => {
    let filtered = eventsData;

    if (eventType !== "allEvent") {
      filtered = filtered.filter((event) => event.event === eventType);
    }

    const now = new Date();
    let startDate: Date;
    switch (timeValue) {
      case "6M":
        startDate = new Date(now.setMonth(now.getMonth() - 6));
        break;
      case "3M":
        startDate = new Date(now.setMonth(now.getMonth() - 3));
        break;
      case "1M":
        startDate = new Date(now.setMonth(now.getMonth() - 1));
        break;
      default:
        startDate = new Date(0);
    }
    filtered = filtered.filter(
      (event) => new Date(event.timestamp) >= startDate,
    );
    setFilteredData(filtered);
  }, [timeValue, eventType, eventsData]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress style={{ color: "#fff" }} />
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          margin: "-22px -16px 0 -16px",
        }}
      >
        <Select
          className={styles.customSelect}
          value={timeValue}
          onChange={(e) => setTimeValue(e.target.value)}
          IconComponent={icon}
          MenuProps={{
            classes: {
              root: styles.selectRoot,
              paper: styles.selectMenu,
              list: styles.selectList,
            },
          }}
        >
          <MenuItem className={styles.selectMenuItem} value={"maxTime"}>
            Max Time
          </MenuItem>
          <MenuItem className={styles.selectMenuItem} value={"6M"}>
            6 Months
          </MenuItem>
          <MenuItem className={styles.selectMenuItem} value={"3M"}>
            3 Months
          </MenuItem>
          <MenuItem className={styles.selectMenuItem} value={"1M"}>
            1 Months
          </MenuItem>
        </Select>
        <Select
          className={styles.customSelect}
          value={eventType}
          onChange={(e) => setEventType(e.target.value)}
          IconComponent={icon}
          MenuProps={{
            classes: {
              root: styles.selectRoot,
              paper: styles.selectMenu,
              list: styles.selectList,
            },
          }}
        >
          <MenuItem className={styles.selectMenuItem} value={"allEvent"}>
            All Event Types
          </MenuItem>
          <MenuItem className={styles.selectMenuItem} value={"Bunkering"}>
            Ship-To-Ship
          </MenuItem>
          <MenuItem className={styles.selectMenuItem} value={"Port of Calls"}>
            Port of Calls
          </MenuItem>
          <MenuItem className={styles.selectMenuItem} value={"Spoofing"}>
            Spoofing
          </MenuItem>
          <MenuItem className={styles.selectMenuItem} value={"AIS Dark"}>
            AIS Dark
          </MenuItem>
        </Select>
      </Box>
      <Box className={styles.eventMain}>
        {filteredData.map((event: any, index: number) => (
          <Box key={index}>
            {event.event === "AIS Dark" && (
              <Box
                display="flex"
                flexDirection="column"
                className={styles.eventWrap}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="14px"
                  className={styles.eventCard}
                >
                  <span className={styles.eventCardHead}>
                    {event.timestamp}
                  </span>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(3, 1fr)"
                    className={styles.eventDetailWrap}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="2px"
                      className={styles.detailWrap}
                    >
                      <span className={styles.detailHead}>Event</span>
                      <span className={`${styles.detailValue} ${styles.dark} `}>
                        AIS Dark
                      </span>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="2px"
                      className={styles.detailWrap}
                    >
                      <span className={styles.detailHead}>Start</span>
                      <span className={styles.detailValue}>
                        {moment(event.timestamp).format("YYYY-MM-DD HH:mm:ss")}
                      </span>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="2px"
                      className={styles.detailWrap}
                    >
                      <span className={styles.detailHead}>End</span>
                      <span className={styles.detailValue}>
                        {moment(event.timestamp2).format("YYYY-MM-DD HH:mm:ss")}
                      </span>
                    </Box>
                  </Box>
                  <Button
                    className={styles.eventCardButton}
                    variant="outlined"
                    onClick={() => handleViewEventClick(event)}
                  >
                    View Event
                  </Button>
                </Box>
              </Box>
            )}
            {event.event === "Spoofing" && (
              <Box
                display="flex"
                flexDirection="column"
                className={styles.eventWrap}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="14px"
                  className={styles.eventCard}
                >
                  <span className={styles.eventCardHead}>
                    {event.timestamp}
                  </span>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(4, 1fr)"
                    className={styles.eventDetailWrap}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="2px"
                      className={styles.detailWrap}
                    >
                      <span className={styles.detailHead}>Event</span>
                      <span
                        className={`${styles.detailValue} ${styles.spoofing}`}
                      >
                        Spoofing
                      </span>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="2px"
                      className={styles.detailWrap}
                    >
                      <span className={styles.detailHead}>Event Type</span>
                      <span className={`${styles.detailValue}`}>
                        {event.type}
                      </span>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="2px"
                      className={styles.detailWrap}
                    >
                      <span className={styles.detailHead}>Longitude</span>
                      <span className={styles.detailValue}>
                        {event.longitude}
                      </span>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="2px"
                      className={styles.detailWrap}
                    >
                      <span className={styles.detailHead}>Latitude</span>
                      <span className={styles.detailValue}>
                        {event.latitude}
                      </span>
                    </Box>
                  </Box>
                  <Button
                    className={styles.eventCardButton}
                    variant="outlined"
                    onClick={() => handleViewEventClick(event)}
                  >
                    View Event
                  </Button>
                </Box>
              </Box>
            )}
            {event.event === "Port of Calls" && (
              <Box
                display="flex"
                flexDirection="column"
                className={styles.eventWrap}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="14px"
                  className={styles.eventCard}
                >
                  <span className={styles.eventCardHead}>
                    {event.timestamp}
                  </span>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(5, 1fr)"
                    className={styles.eventDetailWrap}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="2px"
                      className={styles.detailWrap}
                    >
                      <span className={styles.detailHead}>Event</span>
                      <span
                        className={`${styles.detailValue} ${styles.portOfCalls} `}
                      >
                        Port of Calls
                      </span>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="2px"
                      className={styles.detailWrap}
                    >
                      <span className={styles.detailHead}>Port</span>
                      <span className={`${styles.detailValue}`}>
                        {event.port}
                      </span>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="2px"
                      className={styles.detailWrap}
                    >
                      <span className={styles.detailHead}>MMSI</span>
                      <span className={styles.detailValue}>{event.mmsi}</span>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="2px"
                      className={styles.detailWrap}
                    >
                      <span className={styles.detailHead}>Status</span>
                      <span className={styles.detailValue}>{event.status}</span>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="2px"
                      className={styles.detailWrap}
                    >
                      <span className={styles.detailHead}>Duration</span>
                      <span className={styles.detailValue}>
                        {event.duration}
                      </span>
                    </Box>
                  </Box>
                  <Box />
                </Box>
              </Box>
            )}
            {event.event === "Bunkering" &&
              event.type === "Optical Detection" && (
                <Box
                  display="flex"
                  flexDirection="column"
                  className={styles.eventWrap}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="14px"
                    className={styles.eventCard}
                  >
                    <span className={styles.eventCardHead}>
                      {event.timestamp}
                    </span>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(4, 1fr)"
                      className={styles.eventDetailWrap}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap="2px"
                        className={styles.detailWrap}
                      >
                        <span className={styles.detailHead}>Event </span>
                        <span
                          className={`${styles.detailValue} ${styles.bunkering}`}
                        >
                          Ship-To-Ship
                        </span>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap="2px"
                        className={styles.detailWrap}
                      >
                        <span className={styles.detailHead}>Event Type</span>
                        <span className={`${styles.detailValue}`}>
                          {event.type}
                        </span>
                      </Box>

                      <Box
                        display="flex"
                        flexDirection="column"
                        gap="2px"
                        className={styles.detailWrap}
                      >
                        <span className={styles.detailHead}>
                          Ship 2 Attribution
                        </span>
                        <span className={styles.detailValue}>
                          {event.ship2Attri}
                        </span>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap="2px"
                        className={styles.detailWrap}
                      >
                        <span className={styles.detailHead}>
                          Ship 2 ObjectID
                        </span>
                        <span className={styles.detailValue}>
                          {" "}
                          {event.ship2ObjId}{" "}
                        </span>
                      </Box>
                    </Box>
                    <Button
                      className={styles.eventCardButton}
                      variant="outlined"
                      onClick={() => handleViewEventClick(event)}
                    >
                      View Event
                    </Button>
                  </Box>
                </Box>
              )}
            {event.event === "Bunkering" && event.type === "AIS Detection" && (
              <Box
                display="flex"
                flexDirection="column"
                className={styles.eventWrap}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="14px"
                  className={styles.eventCard}
                >
                  <span className={styles.eventCardHead}>
                    {event.timestamp}
                  </span>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(4, 1fr)"
                    className={styles.eventDetailWrap}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="2px"
                      className={styles.detailWrap}
                    >
                      <span className={styles.detailHead}>Event </span>
                      <span
                        className={`${styles.detailValue} ${styles.bunkering}`}
                      >
                        Ship-To-Ship
                      </span>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="2px"
                      className={styles.detailWrap}
                    >
                      <span className={styles.detailHead}>Event Type</span>
                      <span className={`${styles.detailValue}`}>
                        {event.type}
                      </span>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="2px"
                      className={styles.detailWrap}
                    >
                      <span className={styles.detailHead}>Longitude</span>
                      <span className={styles.detailValue}>
                        {event.longitude}
                      </span>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="2px"
                      className={styles.detailWrap}
                    >
                      <span className={styles.detailHead}>Latitude</span>
                      <span className={styles.detailValue}>
                        {event.latitude}
                      </span>
                    </Box>
                  </Box>
                  <Button
                    className={styles.eventCardButton}
                    variant="outlined"
                    onClick={() => handleViewEventClick(event)}
                  >
                    View Event
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </>
  );
};

export default EventTimeline;
