import {
  DEFAULT_SELECTED_SHIP_ICON_WIDTH,
  DEFAULT_SELECTED_SHIP_ICON_HEIGHT,
} from "../../../utils/Constants";

export const DarkShipSelected = (
  isActiveShip: boolean,
  color = "#00A3E3",
  width = DEFAULT_SELECTED_SHIP_ICON_WIDTH,
  height = DEFAULT_SELECTED_SHIP_ICON_HEIGHT
) => {
  return `<svg width="${width}" height="${height}" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="25" cy="25.5" r="25" fill="url(#paint0_radial_3218_29332)" fill-opacity="${
    isActiveShip ? "1" : "0.5"
  }" />
  <path d="M25.0004 35.5H30.824L30.824 30.7413C30.824 20.0159 25.0004 15.5 25.0004 15.5C25.0004 15.5 19.1768 20.0159 19.1768 30.7413L19.1768 35.5H25.0004Z" fill="#FFA500" stroke="white" stroke-width="1.5" stroke-miterlimit="10" />
  <path d="M26.995 30.1988L26.7009 29.8976L27.0548 29.5128C27.4811 29.0416 27.6723 28.6509 27.7626 28.0685C27.8836 27.2365 27.6077 26.4076 27.0116 25.7972L26.7019 25.4799L27.003 25.1858L27.3042 24.8917L27.5905 25.1849C28.2414 25.8515 28.5997 26.7647 28.5844 27.7211C28.569 28.6775 28.2867 29.3846 27.6391 30.1112L27.2891 30.5L26.995 30.1988Z" fill="#111326" />
  <path d="M23.0079 30.1988L23.302 29.8976L22.9482 29.5128C22.5218 29.0416 22.3306 28.6509 22.2403 28.0685C22.1193 27.2365 22.3952 26.4076 22.9913 25.7972L23.3011 25.4799L22.9999 25.1858L22.6987 24.8917L22.4124 25.1849C21.7615 25.8515 21.4032 26.7647 21.4186 27.7211C21.4339 28.6775 21.7162 29.3846 22.3639 30.1112L22.7138 30.5L23.0079 30.1988Z" fill="#111326" />
  <circle cx="25" cy="27.75" r="2" fill="#111326" />
  <defs>
    <radialGradient id="paint0_radial_3218_29332" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25 25.5) rotate(90) scale(25)">
      <stop offset="0.27" stop-color="white" stop-opacity="0" />
      <stop offset="1" stop-color="white" />
    </radialGradient>
  </defs>
</svg>`;
};
