import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import useFireBaseAuth from "../../context/useFireBaseAuth";
import styles from "./sessionTimeoutModal.module.scss";

const SessionTimeoutModal = () => {
  const { showSessionTimeoutModal, handleKeepAlive } = useFireBaseAuth();

  return (
    <Dialog
      maxWidth="sm"
      open={showSessionTimeoutModal}
      aria-labelledby="session-timeout-dialog-title"
      aria-describedby="session-timeout-dialog-description"
      classes={{
        root: styles["timeout-dialog"],
        paper: styles["timeout-paper"],
      }}
    >
      <DialogTitle
        id="session-timeout-dialog-title"
        classes={{ root: styles["timeout-dialog-title"] }}
      >
        Session Timeout Warning
      </DialogTitle>
      <DialogContent classes={{ root: styles["timeout-dialog-content"] }}>
        <DialogContentText
          id="session-timeout-dialog-description"
          classes={{ root: styles["timeout-dialog-content-text"] }}
        >
          Your session will expire soon. Would you like to stay logged in?
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: styles["dialog-actions"] }}>
        <Button
          onClick={handleKeepAlive}
          variant="contained"
          color="primary"
          classes={{ root: styles["keep-alive-button"] }}
        >
          Keep me logged in
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionTimeoutModal;
