import axios from "axios";
import { REACT_APP_BASE_URL } from "../../../../../utils/Constants";

export interface Port {
  latitude: number;
  longitude: number;
  port_name: string;
  unlocode: string;
}

export const fetchPortData = async (): Promise<Port[]> => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${REACT_APP_BASE_URL}/v1/ports/`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data.ports;
  } catch (error: any) {
    console.log("error fetching port data:", error);
    throw new Error("error fetching Port data:");
  }
};
