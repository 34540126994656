import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAnalyzeShape,
  setIsOpenPolygonShipAnalysisModal,
  setPolygonDataForSlider,
  setLastFetchedIndex,
  fetchPolygonAnalysisData,
  setResetPolygonData,
} from "./PolygonShipAnalysis.store";
import { setMinimizePolygonModal } from "../MainMap.store";
import { RootState } from "../../../redux/store";
import Modal from "../../../components/Modal/Modal";
import PolygonShipAnalysisSelectDate from "./PolygonShipAnalysisSelectDate/PolygonShipAnalysisSelectDate";
import PolygonShipAnalysisSlider from "./PolygonShipAnalysisSlider/PolygonShipAnalysisSlider";
import { DateRange, MapContext } from "../MainMap";
import styles from "./PolygonShipAnalysis.module.scss";
import { useSnackbar } from "../../../context/SnackbarContext";
import moment from "moment";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  getUTCStartOfDay,
  getUTCEndOfDay,
  formatUTCDateTime,
  distributeDataIntoTimeSlots,
} from "./utils";
import { customConsoleError } from "../../../utils/utils";

interface PolygonShipAnalysisProps {
  dateRange: DateRange;
  setDateRange: (val: DateRange) => void;
  handleSimilarShips: () => Promise<void>;
  showSimilarShipsButton: boolean;
  lastSelectedOption: string;
  setLastSelectedOption: (lastSelectedOption: string) => void;
}

const PolygonShipAnalysis = ({
  dateRange,
  setDateRange,
  handleSimilarShips,
  showSimilarShipsButton,
  lastSelectedOption,
  setLastSelectedOption,
}: PolygonShipAnalysisProps) => {
  // Redux hooks
  const dispatch = useDispatch<ThunkDispatch<RootState, void, any>>();
  const { minimizePolygonModal } = useSelector(
    (state: RootState) => state.mainMap,
  );
  const { polygonData, error, totalPages } = useSelector(
    (state: RootState) => state.polygonShipAnalysis,
  );

  // Context
  const { setShowShips, setShowSimilarShips, features } =
    useContext(MapContext);
  const { setNewSnackBar } = useSnackbar();

  // State
  const [showPolygonShipAnalysisSlider, setShowPolygonShipAnalysisSlider] =
    useState<boolean>(false);
  const [skip, setSkip] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [polygonSliderValue, setPolygonSliderValue] = useState<number>(0);

  // Constants
  const limit = 30000;
  const isLastPage = totalPages !== 0 ? skip + limit > totalPages : false;

  // Effects
  useEffect(() => {
    settingPolygonDataForSlider();
  }, [polygonData]);

  useEffect(() => {
    if (error) {
      setNewSnackBar({ message: error, severity: "error" });
    }
  }, [error]);

  // Handlers
  const handleClose = () => {
    dispatch(setIsOpenPolygonShipAnalysisModal(false));
    dispatch(setAnalyzeShape([]));
    setDateRange({
      startDate: new Date(Date.now() - 60 * 60 * 1000),
      endDate: new Date(),
    });
    setShowShips(true);
    setShowSimilarShips(true);
  };

  const resetValuesForSlider = () => {
    setPolygonSliderValue(0);
    setSkip(0);
    dispatch(setResetPolygonData());
    setShowPolygonShipAnalysisSlider(false);
  };

  // Data fetching helpers
  const prepareShipData = (
    area: any,
    dateRange: DateRange,
    limit: number,
    skip: number,
  ) => {
    const startMoment = moment.utc(dateRange.startDate).startOf("day");
    const endMoment = moment.utc(dateRange.endDate).endOf("day");

    return {
      area,
      start: formatUTCDateTime(startMoment),
      end: formatUTCDateTime(endMoment),
      limit,
      skip: skip + limit,
      isExist: true,
    };
  };

  const loadMoreData = async () => {
    if (skip + limit > totalPages || loading) return;

    const area = features.data?.[lastSelectedOption]?.geometry;
    const shipData = prepareShipData(area, dateRange, limit, skip);

    setLoading(true);
    try {
      setSkip((prev) => prev + limit);
      await dispatch(fetchPolygonAnalysisData(shipData));
    } catch (error) {
      customConsoleError("Error loading more data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Data processing
  const settingPolygonDataForSlider = () => {
    const startDateStr = getUTCStartOfDay(dateRange.startDate);
    const endDateStr = getUTCEndOfDay(dateRange.endDate);

    const startEpoch = startDateStr.unix();
    const endEpoch = endDateStr.unix();

    const INTERVAL = 15 * 60;
    const size = Math.ceil((endEpoch - startEpoch) / INTERVAL);
    const { slots, lastPopulatedIndex } = distributeDataIntoTimeSlots(
      polygonData,
      startEpoch,
      INTERVAL,
    );

    dispatch(setPolygonDataForSlider(slots));
    dispatch(setLastFetchedIndex(lastPopulatedIndex));
  };

  return (
    <Modal
      headerTitle="Polygon Ship Analysis"
      handleClose={handleClose}
      onClickMinimize={() => {
        dispatch(setMinimizePolygonModal(true));
      }}
      onMinimizeClick={minimizePolygonModal}
      className={styles.polygonShip}
    >
      <PolygonShipAnalysisSelectDate
        setShowPolygonShipAnalysisSlider={setShowPolygonShipAnalysisSlider}
        dateRange={dateRange}
        setDateRange={setDateRange}
        showPolygonShipAnalysisSlider={showPolygonShipAnalysisSlider}
        lastSelectedOption={lastSelectedOption}
        setLastSelectedOption={setLastSelectedOption}
        limit={limit}
        skip={skip}
        resetValuesForSlider={resetValuesForSlider}
      />
      <PolygonShipAnalysisSlider
        startDate={new Date(dateRange.startDate)}
        endDate={new Date(dateRange.endDate)}
        handleSimilarShips={handleSimilarShips}
        showSimilarShipsButton={showSimilarShipsButton}
        showPolygonShipAnalysisSlider={showPolygonShipAnalysisSlider}
        loadMoreData={loadMoreData}
        timelineLoading={loading}
        isLastPage={isLastPage}
        polygonSliderValue={polygonSliderValue}
        setPolygonSliderValue={setPolygonSliderValue}
      />
    </Modal>
  );
};

export default PolygonShipAnalysis;
