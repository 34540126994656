import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useSnackbar } from "../../../context/SnackbarContext";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../../services/firebase";

const useSignUp = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errMessage, setErrMessage] = useState<string | null>(null);
  const [passwordIsValid, setPasswordIsValid] = useState(true);
  const auth = getAuth();
  const navigate = useNavigate();
  const { setNewSnackBar } = useSnackbar();

  const createNewUser = () => {
    setIsLoading(true);
    setErrMessage(null);

    if (password !== confirmPassword) {
      setIsLoading(false);
      setErrMessage("The passwords you provided do not match.");
      setNewSnackBar({
        message: "Password and Confirm Password are not same",
        severity: "error",
      });
    } else {
      createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          const user = userCredential.user;
          const { uid, email } = user;
          const docRef = doc(db, "users", uid);
          const docSnap = await getDoc(docRef);
          if (!docSnap.exists() && uid && docRef) {
            try {
              await setDoc(docRef, {
                email,
                role: "new_user",
                firstName,
                lastName,
                isNewUser: true,
              });
            } catch (error: any) {
              console.log(error);
            }
          }
          localStorage.setItem("firstName", firstName);
          localStorage.setItem("lastName", lastName);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
          const errorMessage = error.message;
          setErrMessage(errorMessage);
        });
    }
  };

  const clearState = () => {
    setPassword("");
    setConfirmPassword("");
    setFirstName("");
    setLastName("");
    setEmail("");
  };

  const setCurrentPassword = (newPassword: string): void => {
    setPassword(newPassword);
  };

  const hanldeChangeConfirmPassword = (newPassword: string): void => {
    setConfirmPassword(newPassword);
  };

  const hanldeFirstNameCVhange = (newName: string): void => {
    setFirstName(newName);
  };

  const hanldeLastNameCVhange = (newName: string): void => {
    setLastName(newName);
  };

  const handleEmailChange = (newEmail: string): void => {
    setEmail(newEmail);
  };

  const handleLoginClick = () => {
    navigate("/login");
    clearState();
    setErrMessage(null);
  };

  return {
    createNewUser,
    firstName,
    hanldeFirstNameCVhange,
    lastName,
    hanldeLastNameCVhange,
    email,
    handleEmailChange,
    password,
    setCurrentPassword,
    setPasswordIsValid,
    confirmPassword,
    hanldeChangeConfirmPassword,
    isLoading,
    errMessage,
    handleLoginClick,
  };
};

export default useSignUp;
