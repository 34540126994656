import { Box, Typography } from "@mui/material";
import React, { useContext, useState, useRef } from "react";
import Draggable from "react-draggable";
import clsx from "clsx";
import { ICurrentEventTypeFilterOptions } from "../FilterArea/FilterArea";
import LightShipIcon from "../../../../assets/ships/light/LightShip";
import DarkShipIcon from "../../../../assets/ships/dark/DarkShip";
import AISShipIcon from "../../../../assets/ships/AIS/AISShip";
import SpoofingShips from "../../../../assets/ships/spoofing/SpoofingShip";
import UnattributedShipIcon from "../../../../assets/ships/unattributed/UnattributedShip";
import SanctionedShipIcon from "../../../../assets/ships/sanctioned/SanctionedShip";
import SimilarShipsIcon from "../../../../assets/ships/similar/SimilarShips";
import BunkeringIcon from "../../../../assets/ships/bunkering/Bunkering";
import { MapContext } from "../../MainMap";
import {
  SHIP_TYPE_DISPLAY_NAMES,
  ShipType,
  DEFAULT_COLORS,
} from "../../../../constants/ShipConstants";
import styles from "./ShipLegend.module.scss";

export interface LegendItem {
  id: string;
  label: string;
  color: string;
}

interface ShipIconProps {
  width?: number;
  height?: number;
  color?: string;
}

interface ShipLegendProps {
  onClose: () => void;
  currentEventTypeFilterOptions: ICurrentEventTypeFilterOptions;
}

const shipIconMap = (type: string, color: string) => {
  const iconMap: Record<ShipType, (props: ShipIconProps) => JSX.Element> = {
    AIS: (props) => <AISShipIcon {...props} />,
    ais: (props) => <AISShipIcon {...props} />,
    light: (props) => <LightShipIcon {...props} />,
    dark: (props) => <DarkShipIcon {...props} />,
    spoofing: (props) => <SpoofingShips {...props} />,
    unattributed: (props) => <UnattributedShipIcon {...props} />,
    similar: (props) => <SimilarShipsIcon {...props} />,
    sanctioned: (props) => <SanctionedShipIcon {...props} />,
    bunkering: (props) => <BunkeringIcon {...props} />,
  };

  const icon = type.toLowerCase() as ShipType;

  if (type === "spoofing" || type === "bunkering") {
    return iconMap[icon]?.({ color, width: 24, height: 36 }) || null;
  }
  return iconMap[icon]?.({ color, width: 16, height: 24 }) || null;
};

const ShipLegend: React.FC<ShipLegendProps> = ({
  onClose,
  currentEventTypeFilterOptions,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const nodeRef = useRef(null);

  const { currentSelectedShipColor, currentEventTypeFilter } =
    useContext(MapContext);

  // Create legend items from current filter options, now considering the live context color values
  const legendItems: LegendItem[] = Object.keys(currentEventTypeFilterOptions)
    .filter((key) => currentEventTypeFilterOptions[key]?.checked)
    .map((key) => {
      // Use current selected color if this is the ship type being edited
      const isEditingThisType = currentEventTypeFilter === key;
      const liveColor = isEditingThisType ? currentSelectedShipColor : null;
      const persistedColor = currentEventTypeFilterOptions[key]?.markerColor;
      const defaultColor = DEFAULT_COLORS[key] || "#FFFFFF";

      const color = liveColor || persistedColor || defaultColor;

      return {
        id: key,
        label: SHIP_TYPE_DISPLAY_NAMES[key] || key,
        color,
      };
    });

  // If no items are checked, don't render the legend
  if (legendItems.length === 0) {
    return null;
  }

  return (
    <Draggable
      nodeRef={nodeRef}
      bounds="body"
      onStart={() => setIsDragging(true)}
      onStop={() => setIsDragging(false)}
      defaultPosition={{ x: 0, y: 0 }}
    >
      <Box
        ref={nodeRef}
        className={clsx(styles.legendContainer, {
          [styles.dragging]: isDragging,
        })}
      >
        {legendItems.map((item) => (
          <Box key={item.id} className={styles.legendItem}>
            <Box className={styles.iconWrapper}>
              {shipIconMap(item.id, item.color)}
            </Box>
            <Typography className={styles.itemLabel}>{item.label}</Typography>
          </Box>
        ))}
      </Box>
    </Draggable>
  );
};

export default ShipLegend;
