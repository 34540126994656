import React, { useState } from "react";
import CopyIcon from "../../assets/icons/CopyIcon";
import { customConsoleError } from "../../utils/utils";

const CopyToClipboard = ({ data }: { data: string }) => {
  const [isClicked, setIsClicked] = useState(false);

  const copyToClipboard = (data: string) => {
    navigator.clipboard
      .writeText(data)
      .then(() => {
        console.log("copied to clipboard:", data);
        setIsClicked(true);
        setTimeout(() => {
          setIsClicked(false);
        }, 2000);
      })
      .catch((error) => {
        customConsoleError("Failed to copy:", data, error);
      });
  };

  return (
    <CopyIcon
      onClick={() => copyToClipboard(data)}
      disabled={isClicked}
      stroke={isClicked ? "#0094FF" : "white"}
    />
  );
};

export default CopyToClipboard;
