import {
  DEFAULT_SHIP_ICON_WIDTH,
  DEFAULT_SHIP_ICON_HEIGHT,
} from "../../../utils/Constants";

export const DarkShipSanctioned = (
  color = "#FFA500",
  width = DEFAULT_SHIP_ICON_WIDTH,
  height = DEFAULT_SHIP_ICON_HEIGHT
) => {
  return `<svg
      width="${width}"
      height="${height}"
      viewBox="0 0 14 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.9999 21.5L12.8235 21.5L12.8235 16.7413C12.8235 6.01595 6.99989 1.5 6.99989 1.5C6.99989 1.5 1.17627 6.01595 1.17628 16.7413L1.17628 21.5H6.9999Z"
        fill="${color}"
        stroke="#111326"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M8.99453 16.1988L8.70042 15.8976L9.05429 15.5128C9.48062 15.0416 9.67182 14.6509 9.7621 14.0685C9.88313 13.2365 9.60721 12.4076 9.01115 11.7972L8.70136 11.4799L9.00255 11.1858L9.30373 10.8917L9.58999 11.1849C10.2409 11.8515 10.5992 12.7647 10.5839 13.7211C10.5685 14.6775 10.2862 15.3846 9.63857 16.1112L9.28863 16.5L8.99453 16.1988Z"
        fill="#111326"
      />
      <path
        d="M5.00742 16.1988L5.30153 15.8976L4.94767 15.5128C4.52133 15.0416 4.33014 14.6509 4.23986 14.0685C4.11883 13.2365 4.39475 12.4076 4.9908 11.7972L5.30059 11.4799L4.99941 11.1858L4.69822 10.8917L4.41196 11.1849C3.76101 11.8515 3.40274 12.7647 3.41808 13.7211C3.43342 14.6775 3.7157 15.3846 4.36338 16.1112L4.71332 16.5L5.00742 16.1988Z"
        fill="#111326"
      />
      <circle cx="6.99951" cy="13.75" r="2" fill="#111326" />
      <path d="M12.6763 21.5L3.17627 7.5" stroke="#111326" stroke-width="1.5" />
    </svg>`;
};
