import {
  DEFAULT_SELECTED_SHIP_ICON_WIDTH,
  DEFAULT_SELECTED_SHIP_ICON_HEIGHT,
} from "../../../utils/Constants";

export const AISShipSelectedSanctioned = (
  color = "#00EB6C",
  width = DEFAULT_SELECTED_SHIP_ICON_WIDTH,
  height = DEFAULT_SELECTED_SHIP_ICON_HEIGHT
) => {
  return `<svg width="${width}" height="${height}" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="25" cy="25.5" r="25" fill="url(#paint0_radial_3218_29519)" fill-opacity="0.5" />
  <path d="M24.9994 35.5H30.823L30.823 30.7413C30.823 20.0159 24.9994 15.5 24.9994 15.5C24.9994 15.5 19.1758 20.0159 19.1758 30.7413L19.1758 35.5H24.9994Z" fill="#00EB6C" stroke="white" stroke-width="1.5" stroke-miterlimit="10" />
  <path d="M30.6758 35.5L21.1758 21.5" stroke="white" stroke-width="1.5" />
  <defs>
    <radialGradient id="paint0_radial_3218_29519" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25 25.5) rotate(90) scale(25)">
      <stop offset="0.27" stop-color="white" stop-opacity="0" />
      <stop offset="1" stop-color="white" />
    </radialGradient>
  </defs>
</svg>`;
};
