import { useRef, useEffect } from "react";
import {
  DEFAULT_SHIP_ICON_WIDTH,
  DEFAULT_SHIP_ICON_HEIGHT,
} from "../../../utils/Constants";

export const Bunkering = (
  ship1 = "#00A3E3",
  ship2 = "#F75349",
  width = DEFAULT_SHIP_ICON_WIDTH,
  height = DEFAULT_SHIP_ICON_HEIGHT,
) => {
  return `<svg
      width="${width}"
      height="${height}"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1.5" y="1" width="9.5" height="19" fill="${ship1}" />
      <rect x="11" y="1" width="9.5" height="19" fill="${ship2}" />
      <rect
        x="1.5"
        y="1"
        width="19"
        height="19"
        stroke="#111326"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M11 1V20"
        stroke="#111326"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>`;
};

const BunkeringIcon = ({ width, height }: any) => {
  const svgWrapperRef: any = useRef();
  useEffect(() => {
    if (svgWrapperRef.current) {
      svgWrapperRef.current.innerHTML = Bunkering(
        undefined,
        undefined,
        width,
        height,
      );
    }
  }, []);

  return <div ref={svgWrapperRef}></div>;
};

export default BunkeringIcon;
