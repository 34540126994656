import { useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Dialog, CircularProgress, Box } from "@mui/material";

import { useCreateAlerts } from "../../../../context/CreateAlertContext";
import { useViewAlerts } from "../../../../context/ViewAlertsContext";
import {
  DialogTitleStyled,
  DialogContentStyled,
  DialogContentTextStyled,
  DialogActionsStyled,
  StyledButtonCancel,
  StyledButtonDelete,
  StyledMenu,
  StyledMenuItem,
} from "./AlertCardMenuStyled";

const AlertDialog = ({
  open,
  setOpen,
  alert,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  alert: any;
}) => {
  const { deleteAlert, createAlertInProgress } = useCreateAlerts();

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      await deleteAlert(alert.alert_id);
      setOpen(false);
    } catch (error) {
      console.error("Error deleting alert:", error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitleStyled id="alert-dialog-title">
        {"Delete Alert ?"}
      </DialogTitleStyled>
      <DialogContentStyled>
        <DialogContentTextStyled id="alert-dialog-description">
          Are you sure you want to delete this alert? This action cannot be
          undone.
        </DialogContentTextStyled>
      </DialogContentStyled>
      <DialogActionsStyled>
        <StyledButtonCancel onClick={handleClose} variant="outlined" autoFocus>
          Cancel
        </StyledButtonCancel>
        <StyledButtonDelete
          disabled={createAlertInProgress}
          onClick={handleDelete}
          sx={{
            color: "white",
          }}
        >
          Delete Alert
          {createAlertInProgress && (
            <Box sx={{ display: "flex" }}>
              <CircularProgress
                sx={{
                  marginLeft: "0.5em",
                }}
                size={20}
              />
            </Box>
          )}
        </StyledButtonDelete>
      </DialogActionsStyled>
    </Dialog>
  );
};

const AlertCardMenu = ({ alert }: any) => {
  const [deleteAlert, setDeleteAlert] = useState(false);
  const { handleTabChange } = useViewAlerts();
  const { updateAlert, setIsUpdate } = useCreateAlerts();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const editAlert = () => {
    handleTabChange(1);
    updateAlert(alert);
    setIsUpdate(true);
  };

  return (
    <>
      <AlertDialog open={deleteAlert} setOpen={setDeleteAlert} alert={alert} />
      <MoreHorizIcon
        id="basic-button"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          cursor: "pointer",
        }}
      />
      <StyledMenu
        id="alert-card-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "alert-card-menu-button",
        }}
      >
        <StyledMenuItem onClick={editAlert}>Edit Alert</StyledMenuItem>
        <StyledMenuItem onClick={() => setDeleteAlert(true)}>
          Delete Alert
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};

export default AlertCardMenu;
