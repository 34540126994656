import styled from "styled-components";
import {
  Card,
  Box,
  StepConnector,
  Typography,
  StepLabel,
  Button,
} from "@mui/material";

export const StepperAlertInfo = styled.div`
  width: 100%;
  padding-top: 1em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  alignitems: flex-start;
`;

export const DividerComponent = styled.div`
  height: 0.1em;
  width: 100%;
  background-color: rgba(57, 60, 86, 1);
`;

export const SpaceDiv = styled.div`
  height: 1em;
`;

export const TriggeredAlertsHeader = styled(Box)`
  padding-top: 1em;
  display: flex;
  width: 100%;
`;

export const StyledStepConnector = styled(StepConnector)`
  & .MuiStepConnector-line {
    border-left: 2px dashed #ffffff;
    margin-top: 0.2em;
  }
`;

export const TriggeredAlertTypography = styled(Typography)`
  margin-left: 0.4em;
`;

export const StepIconComponent = styled.div`
  width: 0.8em;
  height: 0.8em;
  border-radius: 20%;
  margin-left: 0.4em;
`;
export const StepLabelComponent = styled(StepLabel)`
  .Mui-active .MuiStepLabel-label {
    color: "#FFFFFF !important"; // Active step color
  }

  .Mui-completed .MuiStepLabel-label {
    color: "#FFFFFF !important"; // Completed step color
  }
`;

export const LatLongBox = styled(Box)`
  display: flex;
  width: 100%;
  aligncontent: flex-start;
  mb: 2;
`;

export const LongitudeBox = styled(Box)`
  margin-right: 1em;
`;
export const ViewMapButton = styled(Button)`
  margin-top: 1em;
  margin-right: 1em;
  width: 100%;
  text-transform: none;

  &.MuiButton-outlined {
    color: #ffffff;
    border-color: #ffffff;
  }
`;

export const HeaderTypography = styled(Typography)`
  display: flex;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const TriggeredAlertButton = styled(Button)`
  width: 100%;
  height: 100%;
  margin-top: 1em;
  gap: 8px;
  border-radius: 4px;
  opacity: 1; // Changed from 100 to 1, as opacity ranges from 0 to 1
  color: #ffffff;
  margin-top: 1em; // Redundant but added here as per your original code
  margin-right: 1em;
  &.MuiButton-outlined {
    color: #ffffff;
    border-color: #ffffff;
  }
  text-transform: none;
`;

export const StyledCard = styled(Card)`
  background-color: #24263c;
  color: #ffffff;
  margin-bottom: 1em;
`;

export const CardContentBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const StyledTypographyButton = styled(Typography)`
  cursor: pointer;
`;

export const TriggeredTypography = styled(Typography)`
  font-size: 0.8em;
  margin-left: 0.6em;
`;

export const TriggeredTypographyMinimized = styled(Typography)`
  font-size: 0.8em;
`;
