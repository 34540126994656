import { Dispatch, SetStateAction, useCallback, useState } from "react";
import moment from "moment";
import { getBunkeringEvent, updateActiveStatus } from "../utils";
import {
  ShipDetail,
  ShipDetailsInterFace,
} from "../../../context/useShipDetails";
import { api } from "../../../services/api";
import { PathData } from "../MainMap";

const useShipIconClick = (
  setShipDetailTabValue: Dispatch<SetStateAction<string | null>>,
  setSelectedShip: Dispatch<
    SetStateAction<{ [key: string]: ShipDetailsInterFace }>
  >,
  setSelectedEvent: Dispatch<
    SetStateAction<{ [key: string]: ShipDetailsInterFace }>
  >,
  setShipDetails: Dispatch<SetStateAction<{ [key: string]: ShipDetail }>>,
  getShipInfo: (ids: string[], date: string) => Promise<any>,
  fetchAndSetShipPath: (
    ids: string[],
    startDate: string,
    endDate: string,
    color: string,
  ) => Promise<void>,
  getUniqueColor: () => string,
  onShipClick: (tabId?: string) => void,
  setShipPaths: Dispatch<
    SetStateAction<{
      [key: string]: {
        path: PathData[];
        color: string;
        analysis: boolean;
        dateRange: { startDate: string; endDate: string };
        showPath?: boolean;
        showAllpoints?: boolean;
        showShip?: boolean;
      };
    }>
  >,
) => {
  const [shipDetailsTabLoading, setShipDetailsTabLoading] =
    useState<boolean>(false);

  const handleShipIconClick = useCallback(
    async (id: string, f: any) => {
      try {
        setShipDetailsTabLoading(true);
        onShipClick();
        if (id === "alertLayer") {
          const shipId = f.object.properties.synmax_ship_id;
          const timestamp = f.object.properties.timestamp;
          const startOfDay = new Date(timestamp);
          startOfDay.setUTCHours(0, 0, 0, 0);
          const startTime = moment
            .utc(startOfDay)
            .format("YYYY-MM-DD HH:mm:ss");
          const endTime = moment
            .utc(f.object.properties.timestamp)
            .format("YYYY-MM-DD HH:mm:ss");
          if (shipId) {
            setShipDetailTabValue(shipId);
            setSelectedShip((prevShips) => ({
              ...prevShips,
              [shipId]: {},
            }));
            const shipLocation = await api.get(
              `/ship_location/${shipId}?start=${startTime}&end=${endTime}&limit=1&skip=0`,
            );

            const shipDetails = await getShipInfo(
              [shipId],
              timestamp.split("T")[0],
            );
            setShipDetails((prev) => ({
              ...prev,
              ...shipDetails,
            }));
            const shipPathColor = getUniqueColor();

            await fetchAndSetShipPath(
              [shipId],
              startTime,
              endTime,
              shipPathColor,
            );
            const updatedShipDetails = {
              ...shipDetails,
              [shipId]: {
                detectionData: shipLocation.data[0],
                ...f.object.properties,
                ...shipDetails[shipId],
                type: f.object.properties.type,
                active: true,
                attribution: shipLocation.data[0].synmax_ship_id,
                acquired: shipLocation.data[0].timestamp,
                longitude: shipLocation.data[0].longitude,
                latitude: shipLocation.data[0].latitude,
                heading: shipLocation.data[0].heading,
                ship_info: shipLocation.data[0].ship_info,
                isSanctionedShip: shipLocation.data[0].is_sanctioned_ship,
              },
            };

            setSelectedShip((prevShips) => ({
              ...updateActiveStatus(prevShips, shipId),
              ...updatedShipDetails,
            }));
            setSelectedEvent((prevEvents) => ({
              ...prevEvents,
              ...updateActiveStatus(prevEvents, shipId),
            }));
          }
        }
        if (id === "polygon" || id === "liveAIS") {
          const shipId = f.object.properties.synmax_ship_id;
          if (shipId) {
            setShipDetailTabValue(shipId);
            setSelectedShip((prevShips) => ({
              ...prevShips,
              [shipId]: {},
            }));
            const shipDetails = await getShipInfo(
              [shipId],
              moment.utc(f.object.properties.timestamp).format("YYYY-MM-DD"),
            );
            const startTimestamp =
              f.object.properties.timestamp - 24 * 60 * 60 * 1000;
            const startDate = `${moment
              .utc(startTimestamp)
              .format("YYYY-MM-DD HH:mm:ss")}`;
            const endDate = `${moment
              .utc(f.object.properties.timestamp)
              .format("YYYY-MM-DD HH:mm:ss")}`;
            const shipPathColor = getUniqueColor();
            await fetchAndSetShipPath(
              [shipId],
              startDate,
              endDate,
              shipPathColor,
            );
            const updatedShipDetails = {
              ...shipDetails,
              [shipId]: {
                ...f.object.properties,
                ...shipDetails[shipId],
                type: f.object.properties.type,
                active: true,
              },
            };
            setSelectedShip((prevShips) => ({
              ...updateActiveStatus(prevShips, shipId),
              ...updatedShipDetails,
            }));
            setSelectedEvent((prevEvents) => ({
              ...prevEvents,
              ...updateActiveStatus(prevEvents, shipId),
            }));
          }
        } else if (id === "unattributed") {
          const shipId = `unattributed-${f.object.properties.object_id}`;
          setShipDetailTabValue(shipId);
          setSelectedShip((prevShips) => ({
            ...(prevShips as { [key: string]: ShipDetailsInterFace }),
            [shipId]: {} as ShipDetailsInterFace,
          }));
          const updatedShipDetails = {
            [shipId]: {
              ...f.object.properties,
              synmax_ship_id: shipId,
              active: true,
            },
          };
          setSelectedShip((prevShips) => ({
            ...updateActiveStatus(prevShips, shipId),
            ...updatedShipDetails,
          }));
          setSelectedEvent((prevEvents) => ({
            ...prevEvents,
            ...updateActiveStatus(prevEvents, shipId),
          }));
          // Initialize path settings for unattributed ships
          setShipPaths((prev) => {
            const existingShipPath = prev[shipId];
            return {
              ...prev,
              [shipId]: {
                path: existingShipPath?.path || [],
                color: existingShipPath?.color || "#FA5849",
                analysis: existingShipPath?.analysis || false,
                dateRange: existingShipPath?.dateRange || {
                  startDate: "",
                  endDate: "",
                },
                showPath: existingShipPath?.showPath || false,
                showAllpoints: existingShipPath?.showAllpoints || false,
                showShip: existingShipPath?.showShip ?? true,
              },
            };
          });
        } else if (id === "AISSTS") {
          const shipId = `${f.object.properties.synmax_ship_id_1}_${f.object.properties.synmax_ship_id_2}`;
          const shipId1 = f.object.properties.synmax_ship_id_1;
          const shipId2 = f.object.properties.synmax_ship_id_2;
          if (shipId) {
            setShipDetailTabValue(shipId);
            setSelectedShip((prevShips) => ({
              ...(prevShips as { [key: string]: ShipDetailsInterFace }),
              [shipId]: {} as ShipDetailsInterFace,
            }));
            const dateString = moment(f.object.properties.timestamp_t0).format(
              "YYYY-MM-DD",
            );
            const shipDetails = await getShipInfo(
              [shipId1, shipId2],
              dateString,
            );
            const updatedShipDetails = {
              [shipId1]: {
                ...f.object.properties,
                ...shipDetails[shipId1],
                type: f.object.properties.type,
              },
              [shipId2]: {
                ...f.object.properties,
                ...shipDetails[shipId2],
                type: f.object.properties.type,
              },
              active: true,
              synmax_ship_id: shipId,
              ...f.object.properties,
              ship1_status: "AIS",
              ship2_status: "AIS",
            };

            const startTimestamp =
              f.object.properties.timestamp_t0 - 24 * 60 * 60;
            const startDate = `${moment
              .utc(startTimestamp)
              .format("YYYY-MM-DD HH:mm:ss")}`;
            const endDate = `${moment
              .utc(f.object.properties.timestamp_t0)
              .format("YYYY-MM-DD HH:mm:ss")}`;
            const shipPathColor1 = getUniqueColor();
            const shipPathColor = getUniqueColor();
            await fetchAndSetShipPath(
              [shipId1],
              startDate,
              endDate,
              shipPathColor1,
            );
            await fetchAndSetShipPath(
              [shipId2],
              startDate,
              endDate,
              shipPathColor,
            );
            setSelectedShip((prevShips) => ({
              ...updateActiveStatus(prevShips, shipId),
              [shipId]: updatedShipDetails,
            }));
            setSelectedEvent((prevEvents) => ({
              ...prevEvents,
              ...updateActiveStatus(prevEvents, shipId),
            }));
          }
        } else if (id === "attributed" || id === "light") {
          const shipId = f.object.properties.detectionData.attribution;
          if (shipId) {
            setShipDetailTabValue(shipId);
            setSelectedShip((prevShips) => ({
              ...prevShips,
              [shipId]: {},
            }));
            const dateSting = moment
              .utc(f.object.properties.detectionData.acquired)
              .format("YYYY-MM-DD");
            const shipDetails = await getShipInfo([shipId], dateSting);
            setShipDetails((prev) => ({
              ...prev,
              ...shipDetails,
            }));
            const startTimestamp =
              f.object.properties.detectionData.acquired - 24 * 60 * 60 * 1000;
            const startDate = `${moment
              .utc(startTimestamp)
              .format("YYYY-MM-DD HH:mm:ss")}`;
            const endDate = `${moment
              .utc(f.object.properties.detectionData.acquired)
              .format("YYYY-MM-DD HH:mm:ss")}`;
            const shipPathColor = getUniqueColor();
            await fetchAndSetShipPath(
              [shipId],
              startDate,
              endDate,
              shipPathColor,
            );
            const updatedShipDetails = {
              ...shipDetails,
              [shipId]: {
                ...f.object.properties.detectionData,
                ...f.object.properties,
                ...shipDetails[shipId],
                type: f.object.properties.type,
                active: true,
              },
            };
            setSelectedShip((prevShips) => ({
              ...updateActiveStatus(prevShips, shipId),
              ...updatedShipDetails,
            }));
            setSelectedEvent((prevEvents) => ({
              ...prevEvents,
              ...updateActiveStatus(prevEvents, shipId),
            }));
          }
        } else if (id === "spoofingEvents") {
          const shipId = f.object.properties.synmax_ship_id;
          if (shipId) {
            setShipDetailTabValue(shipId);
            setSelectedEvent((prevEvents) => ({
              ...prevEvents,
              [shipId]: {},
            }));
            const dateSting = moment
              .utc(f.object.properties.timestamp)
              .format("YYYY-MM-DD");
            const shipDetails = await getShipInfo([shipId], dateSting);
            setShipDetails((prev) => ({
              ...prev,
              ...shipDetails,
            }));
            const startTimestamp =
              f.object.properties.timestamp - 24 * 60 * 60 * 1000;
            const startDate = `${moment
              .utc(startTimestamp)
              .format("YYYY-MM-DD HH:mm:ss")}`;
            const endDate = `${moment
              .utc(f.object.properties.timestamp)
              .format("YYYY-MM-DD HH:mm:ss")}`;

            const shipPathColor = getUniqueColor();
            await fetchAndSetShipPath(
              [shipId],
              startDate,
              endDate,
              shipPathColor,
            );
            const updatedShipDetails = {
              ...shipDetails,
              [shipId]: {
                ...f.object.properties,
                ...shipDetails[shipId],
                type: f.object.properties.type,
                active: true,
              },
            };
            setSelectedEvent((prevEvents) => ({
              ...updateActiveStatus(prevEvents, shipId),
              ...updatedShipDetails,
            }));
            setSelectedShip((prevShips) => ({
              ...prevShips,
              ...updateActiveStatus(prevShips, shipId),
            }));
          }
        } else if (id === "opticalSTS") {
          const shipId = f.object.properties.bunkering_id;
          const shipId1 =
            f.object.properties.ship1_attribution !== "None" &&
            f.object.properties.ship1_attribution
              ? f.object.properties.ship1_attribution
              : `unattributed-${f.object.properties.ship1_object_id}`;
          const shipId2 =
            f.object.properties.ship2_attribution !== "None" &&
            f.object.properties.ship1_attribution
              ? f.object.properties.ship2_attribution
              : `unattributed-${f.object.properties.ship2_object_id}`;
          if (shipId) {
            setShipDetailTabValue(shipId);
            setSelectedShip((prevShips) => ({
              ...(prevShips as { [key: string]: ShipDetailsInterFace }),
              [shipId]: {} as ShipDetailsInterFace,
            }));
            const dateSting = moment(f.object.properties.acquired).format(
              "YYYY-MM-DD",
            );
            const ship1Details =
              f.object.properties.ship1_attribution !== "None" &&
              (await getShipInfo([shipId1], dateSting));
            setShipDetails((prev) => ({
              ...prev,
              ...ship1Details,
            }));
            if (f.object.properties.ship1_attribution !== "None") {
              const startTimestamp =
                f.object.properties.acquired - 24 * 60 * 60 * 1000;
              const startDate = `${moment
                .utc(startTimestamp)
                .format("YYYY-MM-DD HH:mm:ss")}`;
              const endDate = `${moment
                .utc(f.object.properties.acquired)
                .format("YYYY-MM-DD HH:mm:ss")}`;
              const shipPathColor = getUniqueColor();
              await fetchAndSetShipPath(
                [shipId1],
                startDate,
                endDate,
                shipPathColor,
              );
            }
            if (f.object.properties.ship2_attribution !== "None") {
              const startTimestamp =
                f.object.properties.acquired - 24 * 60 * 60 * 1000;
              const startDate = `${moment
                .utc(startTimestamp)
                .format("YYYY-MM-DD HH:mm:ss")}`;
              const endDate = `${moment
                .utc(f.object.properties.acquired)
                .format("YYYY-MM-DD HH:mm:ss")}`;
              const shipPathColor = getUniqueColor();
              await fetchAndSetShipPath(
                [shipId2],
                startDate,
                endDate,
                shipPathColor,
              );
            }

            const ship2Details =
              f.object.properties.ship2_attribution !== "None" &&
              (await getShipInfo([shipId2], dateSting));
            setShipDetails((prev) => ({
              ...prev,
              ...ship2Details,
            }));
            const updatedShipDetails = {
              [shipId1]: {
                ...f.object.properties,
                attribution: f.object.properties.ship1_attribution,
                heading: f.object.properties.ship1_heading,
                latitude: f.object.properties.ship1_lat,
                longitude: f.object.properties.ship1_lon,
                length: f.object.properties.ship1_length,
                moving: f.object.properties.ship1_moving,
                object_id: f.object.properties.ship1_object_id,
                ship_type: f.object.properties.ship1_ship_type,
                width: f.object.properties.ship1_width,
                status: f.object.properties.ship1Status,
                type:
                  f.object.properties.ship1Status === "blue"
                    ? "light"
                    : f.object.properties.ship1Status === "orange"
                      ? "attributed"
                      : "unattributed",
                synmax_ship_id_1: shipId1,
                isShip1: true,
                isOpticalBunkering: true,
                ...ship1Details[shipId1],
              },
              [shipId2]: {
                ...f.object.properties,
                attribution: f.object.properties.ship2_attribution,
                heading: f.object.properties.ship2_heading,
                latitude: f.object.properties.ship2_lat,
                longitude: f.object.properties.ship2_lon,
                length: f.object.properties.ship2_length,
                moving: f.object.properties.ship2_moving,
                object_id: f.object.properties.ship2_object_id,
                ship_type: f.object.properties.ship2_ship_type,
                width: f.object.properties.ship2_width,
                status: f.object.properties.ship2Status,
                type:
                  f.object.properties.ship2Status === "blue"
                    ? "light"
                    : f.object.properties.ship2Status === "orange"
                      ? "attributed"
                      : "unattributed",
                synmax_ship_id_2: shipId2,
                isShip1: false,
                isOpticalBunkering: true,
                ...ship2Details[shipId2],
              },
              ...f.object.properties,
              active: true,
              synmax_ship_id: shipId,
              latitude: f.object.properties.ship1_lat,
              longitude: f.object.properties.ship1_lon,
              synmax_ship_id_1: shipId1,
              synmax_ship_id_2: shipId2,
              object_id_1: f.object.properties.ship1_object_id,
              object_id_2: f.object.properties.ship2_object_id,
              ship1_status: getBunkeringEvent(f.object.properties.ship1Status),
              ship2_status: getBunkeringEvent(f.object.properties.ship2Status),
            };
            setSelectedShip((prevShips) => ({
              ...updateActiveStatus(prevShips, shipId),
              [shipId]: updatedShipDetails,
            }));
            setSelectedEvent((prevEvents) => ({
              ...prevEvents,
              ...updateActiveStatus(prevEvents, shipId),
            }));
          }
        } else if (id === "sanctionedShips") {
          const shipId = f.object.properties.synmax_ship_id;
          if (shipId) {
            setShipDetailTabValue(shipId);
            setSelectedShip((prevShips) => ({
              ...prevShips,
              [shipId]: {},
            }));
            const dateString = moment(
              f.object.properties.timestamp * 1000,
            ).format("YYYY-MM-DD");
            const shipDetails = await getShipInfo([shipId], dateString);
            setShipDetails((prev) => ({
              ...prev,
              ...shipDetails,
            }));
            const startTimestamp =
              f.object.properties.timestamp * 1000 - 24 * 60 * 60 * 1000;
            const startDate = `${moment
              .utc(startTimestamp)
              .format("YYYY-MM-DD HH:mm:ss")}`;
            const endDate = `${moment
              .utc(f.object.properties.timestamp * 1000)
              .format("YYYY-MM-DD HH:mm:ss")}`;
            const shipPathColor = getUniqueColor();
            await fetchAndSetShipPath(
              [shipId],
              startDate,
              endDate,
              shipPathColor,
            );
            const updatedShipDetails = {
              ...shipDetails,
              [shipId]: {
                ...f.object.properties,
                ...shipDetails[shipId],
                type: f.object.properties.type,
                active: true,
              },
            };
            setSelectedShip((prevShips) => ({
              ...updateActiveStatus(prevShips, shipId),
              ...updatedShipDetails,
            }));
            setSelectedEvent((prevEvents) => ({
              ...prevEvents,
              ...updateActiveStatus(prevEvents, shipId),
            }));
          }
        } else if (id === "similarShips") {
          const shipId = `unattributed-${f.object.properties.object_id}`;
          setShipDetailTabValue(shipId);
          setSelectedShip((prevShips) => ({
            ...(prevShips as { [key: string]: ShipDetailsInterFace }),
            [shipId]: {} as ShipDetailsInterFace,
          }));
          const updatedShipDetails = {
            [shipId]: {
              ...f.object.properties,
              synmax_ship_id: shipId,
              active: true,
              type: "unattributed",
            },
          };
          setSelectedShip((prevShips) => ({
            ...updateActiveStatus(prevShips, shipId),
            ...updatedShipDetails,
          }));
          setSelectedEvent((prevEvents) => ({
            ...prevEvents,
            ...updateActiveStatus(prevEvents, shipId),
          }));
        }
      } catch (err) {
        console.log("Error fetching ship details: ", err);
      } finally {
        setShipDetailsTabLoading(false);
      }
    },
    [
      setShipDetailTabValue,
      setSelectedShip,
      setSelectedEvent,
      getShipInfo,
      fetchAndSetShipPath,
      getUniqueColor,
      onShipClick,
      setShipDetails,
      setShipPaths,
    ],
  );

  return {
    handleShipIconClick,
    shipDetailsTabLoading,
    setShipDetailsTabLoading,
  };
};

export default useShipIconClick;
