import useCurrentMapState from "../../context/useCurrentMapState";
import DraggableImageModal from "../DraggableImageModal/DraggableImageModal";

const ShipClipModal = ({
  satelliteImageName,
  imageUrl,
}: {
  satelliteImageName: string;
  imageUrl: string;
}) => {
  const { selectSatelliteImageModal } = useCurrentMapState();

  const handleModalClose = () => {
    selectSatelliteImageModal(null);
  };

  return (
    <DraggableImageModal
      headerText={satelliteImageName}
      imageUrl={imageUrl}
      onClose={handleModalClose}
    />
  );
};

export default ShipClipModal;
