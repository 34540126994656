import { useRef, useEffect } from "react";
import {
  DEFAULT_SHIP_ICON_WIDTH,
  DEFAULT_SHIP_ICON_HEIGHT,
} from "../../../utils/Constants";

export const UnattributedShip = (
  color = "#F75349",
  width = DEFAULT_SHIP_ICON_WIDTH,
  height = DEFAULT_SHIP_ICON_HEIGHT,
) => {
  return `<svg
      width="${width}"
      height="${height}"
      viewBox="0 0 14 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.9999 21.5L12.8235 21.5L12.8235 16.7413C12.8235 6.01595 6.99989 1.5 6.99989 1.5C6.99989 1.5 1.17627 6.01595 1.17628 16.7413L1.17628 21.5H6.9999Z"
        fill="${color}"
        stroke="#111326"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <circle
        cx="6.97607"
        cy="13"
        r="2.5"
        stroke="#111326"
        stroke-width="1.5"
      />
      <path
        d="M9.31696 17.9748C9.54407 18.3212 10.009 18.4179 10.3554 18.1908C10.7018 17.9637 10.7985 17.4987 10.5714 17.1523L9.31696 17.9748ZM7.6721 15.4659L9.31696 17.9748L10.5714 17.1523L8.92653 14.6435L7.6721 15.4659Z"
        fill="#111326"
      />
    </svg>`;
};

const UnattributedShipIcon = ({ color, width, height }: any) => {
  const svgWrapperRef: any = useRef();
  useEffect(() => {
    if (svgWrapperRef.current) {
      svgWrapperRef.current.innerHTML = UnattributedShip(color, width, height);
    }
  }, [color, width, height]);

  return <div ref={svgWrapperRef}></div>;
};

export default UnattributedShipIcon;
