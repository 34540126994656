import { useState, useEffect, useCallback } from "react";
import { filterShips } from "../RenderShips/deckglLayers";
import { ICurrentEventTypeFilterOptions } from "../MapComponents/FilterArea/FilterArea";

export interface ShipCountsInView {
  total: number;
  ais: number;
  light: number;
  dark: number;
  unattributed: number;
  spoofing: number;
  bunkering: number;
  sanctioned: number;
}

interface FilteredDetection {
  filteredDarkShips: any[] | { features: any[] };
  filteredLightShips: any[] | { features: any[] };
  filteredSpoofingShips: any[] | { features: any[] };
  filteredUnattributedShips: any[] | { features: any[] };
}

interface UseShipCountProps {
  currentEventTypeFilterOptions: ICurrentEventTypeFilterOptions;
  detections: any;
  spoofingEvents: any;
  AISSTS: any;
  opticalSTS: any;
  showGlobeView: boolean;
}

export const useShipCount = ({
  currentEventTypeFilterOptions,
  detections,
  spoofingEvents,
  AISSTS,
  opticalSTS,
  showGlobeView,
}: UseShipCountProps) => {
  const [shipCountsInCurrentView, setShipCountsInCurrentView] =
    useState<ShipCountsInView>({
      total: 0,
      ais: 0,
      light: 0,
      dark: 0,
      unattributed: 0,
      spoofing: 0,
      bunkering: 0,
      sanctioned: 0,
    });

  const [filteredDetection, setFilteredDetection] = useState<FilteredDetection>(
    {
      filteredDarkShips: [],
      filteredLightShips: [],
      filteredSpoofingShips: [],
      filteredUnattributedShips: [],
    },
  );

  // Handle filtering of detections based on current options
  useEffect(() => {
    if (currentEventTypeFilterOptions && detections) {
      const { spoofing, light, dark, unattributed } =
        currentEventTypeFilterOptions;

      const filteredLightShips = filterShips(
        detections?.lightGeoJson,
        light,
        "light",
        true,
      );

      const filteredDarkShips = filterShips(
        detections?.attributedGeoJson,
        dark,
        "dark",
        true,
      );

      const filteredSpoofingShips = filterShips(
        spoofingEvents,
        spoofing,
        "spoofing",
        true,
      );

      const filteredUnattributedShips = filterShips(
        detections?.unattributedGeoJson,
        unattributed,
        "unattributed",
        true,
      );

      setFilteredDetection({
        filteredDarkShips,
        filteredLightShips,
        filteredSpoofingShips,
        filteredUnattributedShips,
      });
    }
  }, [currentEventTypeFilterOptions, detections, spoofingEvents]);

  // Calculate ship counts based on filtered data
  const calculateShipCounts = useCallback(
    (filterToApply: (ship: any) => boolean = () => true) => {
      const filteredLightDetections = currentEventTypeFilterOptions?.light
        ?.checked
        ? (Array.isArray(filteredDetection.filteredLightShips)
            ? filteredDetection.filteredLightShips
            : filteredDetection.filteredLightShips?.features || []
          ).filter(filterToApply).length
        : 0;

      const filteredUnattributed = currentEventTypeFilterOptions?.unattributed
        ?.checked
        ? (Array.isArray(filteredDetection.filteredUnattributedShips)
            ? filteredDetection.filteredUnattributedShips
            : filteredDetection.filteredUnattributedShips?.features || []
          ).filter(filterToApply).length
        : 0;

      const spoofingShipsInCurrentView = currentEventTypeFilterOptions?.spoofing
        ?.checked
        ? (Array.isArray(filteredDetection.filteredSpoofingShips)
            ? filteredDetection.filteredSpoofingShips
            : filteredDetection.filteredSpoofingShips?.features || []
          ).filter(filterToApply).length
        : 0;

      const bunkeringAISShipsIncCurrentView =
        currentEventTypeFilterOptions?.AIS?.checked && AISSTS?.features
          ? AISSTS.features.filter(filterToApply).length
          : 0;

      const darkShipsInCurrentView = currentEventTypeFilterOptions?.dark
        ?.checked
        ? (Array.isArray(filteredDetection.filteredDarkShips)
            ? filteredDetection.filteredDarkShips
            : filteredDetection.filteredDarkShips?.features || []
          ).filter(filterToApply).length
        : 0;

      const opticalSTSBunnkeringInCurrentView =
        currentEventTypeFilterOptions?.bunkering?.checked &&
        opticalSTS?.features
          ? opticalSTS.features.filter(filterToApply).length
          : 0;

      const totalShipsInCurrentView =
        filteredLightDetections +
        filteredUnattributed +
        spoofingShipsInCurrentView +
        bunkeringAISShipsIncCurrentView +
        darkShipsInCurrentView +
        opticalSTSBunnkeringInCurrentView;

      setShipCountsInCurrentView({
        total: totalShipsInCurrentView,
        light: filteredLightDetections,
        unattributed: filteredUnattributed,
        sanctioned: 0, // TBD: change back but for now, set sanctioned to 0 since the layer has been removed
        spoofing: spoofingShipsInCurrentView,
        bunkering: opticalSTSBunnkeringInCurrentView,
        ais: bunkeringAISShipsIncCurrentView,
        dark: darkShipsInCurrentView,
      });
    },
    [
      currentEventTypeFilterOptions,
      filteredDetection,
      AISSTS?.features,
      opticalSTS?.features,
    ],
  );

  // Update counts when in globe view or when filtered detections change
  useEffect(() => {
    if (showGlobeView && detections && spoofingEvents && AISSTS && opticalSTS) {
      calculateShipCounts();
    }
  }, [
    detections,
    spoofingEvents,
    AISSTS,
    opticalSTS,
    showGlobeView,
    currentEventTypeFilterOptions,
    filteredDetection,
    calculateShipCounts,
  ]);

  return {
    shipCountsInCurrentView,
    filteredDetection,
    calculateShipCounts,
  };
};
