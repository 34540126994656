import React from "react";
import { Box, Checkbox, IconButton, Tooltip } from "@mui/material";
import { HelpOutline, TuneOutlined } from "@mui/icons-material";
import styles from "./AccordionItem.module.scss";
import LightShipIcon from "../../../../assets/ships/light/LightShip";
import DarkShipIcon from "../../../../assets/ships/dark/DarkShip";
import AISShipIcon from "../../../../assets/ships/AIS/AISShip";
import SpoofingShips from "../../../../assets/ships/spoofing/SpoofingShip";
import UnattributedShipIcon from "../../../../assets/ships/unattributed/UnattributedShip";
import SanctionedShipIcon from "../../../../assets/ships/sanctioned/SanctionedShip";
import SimilarShipsIcon from "../../../../assets/ships/similar/SimilarShips";
import BunkeringIcon from "../../../../assets/ships/bunkering/Bunkering";

type ShipType =
  | "AIS"
  | "light"
  | "dark"
  | "spoofing"
  | "unattributed"
  | "similar"
  | "bunkering"
  | "sanctioned";

interface ShipIconProps {
  width?: number;
  height?: number;
  color?: string;
}

const shipIconMap = (type: string, color: string) => {
  const iconMap: Record<ShipType, (props: ShipIconProps) => JSX.Element> = {
    AIS: (props) => <AISShipIcon {...props} />,
    light: (props) => <LightShipIcon {...props} />,
    dark: (props) => <DarkShipIcon {...props} />,
    spoofing: (props) => <SpoofingShips {...props} />,
    unattributed: (props) => <UnattributedShipIcon {...props} />,
    similar: (props) => <SimilarShipsIcon {...props} />,
    sanctioned: (props) => <SanctionedShipIcon {...props} />,
    bunkering: (props) => <BunkeringIcon {...props} />,
  };
  const icon: ShipType = type as ShipType;

  if (type === "spoofing") {
    return iconMap[type]?.({ color, width: 24, height: 36, }) || null;
  }
  if (type === "bunkering") {
    return iconMap[type]?.({ color, width: 24, height: 36, }) || null;
  }
  return iconMap[icon]?.({ color, width: 16, height: 24, }) || null;
};


export type ShipIconType = keyof typeof shipIconMap;
interface AccordionItemProps {
  checked: boolean;
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  icon: ShipIconType;
  color: string;
  label: string;
  tooltipTitle: string;
  onTuneClick: () => void;
}

const AccordionItem: React.FC<AccordionItemProps> = ({
  checked,
  onCheckboxChange,
  icon,
  color,
  label,
  tooltipTitle,
  onTuneClick,
}) => {
  return (
    <Box
      className={styles.dataTypeItem}
      data-testid={`${label.toLowerCase()}-item`}
      onClick={(e) => {
        // Prevent toggling if clicking on the tune icon
        if (!(e.target as HTMLElement).closest(`.${styles.tuneIcon}`)) {
          onCheckboxChange(e as any);
        }
      }}
    >
      <Box className={styles.leftGroup}>
        <Checkbox
          checked={checked}
          onChange={onCheckboxChange}
          size="medium"
          className={styles.checkboxMain}
          data-testid={`${label.toLowerCase()}-checkbox`}
          icon={<span className={styles.uncheckedIcon} />}
          checkedIcon={<span className={styles.checkedIcon} />}
        />
        <div
          style={{ marginTop: icon === "spoofing" ? "0.3em" : 0 }}
        >
          {shipIconMap(icon, color)}
        </div>
        <span className={styles.labelText}>{label}</span>
        <Tooltip
          title={tooltipTitle}
          classes={{
            tooltip: styles.tooltipStyle,
          }}
        >
          <IconButton size="small" className={styles.tooltipIcon}>
            <HelpOutline />
          </IconButton>
        </Tooltip>
      </Box>
      <IconButton
        size="small"
        onClick={onTuneClick}
        className={`${styles.tuneIcon} ${
          label === "Ship-To-Ship" ? styles.tuneIconDisabled : ""
        }`}
        disabled={label === "Ship-To-Ship"}
      >
        <TuneOutlined />
      </IconButton>
    </Box>
  );
};

export default AccordionItem;
