import { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { RootState } from "./redux/store";
import Spinner from "./components/Spinner/Spinner";
import Routes from "./routes";
import SnackBar from "./components/SnackBar";
import useFireBaseAuth from "./context/useFireBaseAuth";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { extendSessionInterceptor } from "./services/api";

export const REACT_APP_ENV = process.env.REACT_APP_ENV;

export const frontendAPIURL =
  REACT_APP_ENV === "dev"
    ? "https://frontend-api-j75nm34yfa-uc.a.run.app/"
    : "https://frontend-api-y5rlib2gfa-uc.a.run.app/";

export default function App() {
  const { handleAuthStateChange, extendSession } = useFireBaseAuth();
  const { showLoader } = useSelector(
    (state: RootState) => state.globalTheiaState,
  );

  useEffect(() => {
    handleAuthStateChange();
  }, []);

  useEffect(() => {
    extendSessionInterceptor(extendSession);
  }, [extendSession]);

  return (
    <div className="App">
      {showLoader && <Spinner />}
      <SnackBar />
      <BrowserRouter>
        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
      </BrowserRouter>
    </div>
  );
}
