import { useMemo } from "react";
import { isPointInPolygon } from "../RenderShips/utils";
import {
  BunkeringInterface,
  ShipDetailsInterFace,
} from "../../../context/useShipDetails";
import moment from "moment";
import { DateRange } from "../MainMap";

interface Ship {
  latitude: number;
  longitude: number;
}

interface Layer {
  synmax_ship_id: string;
  latitude: number;
  longitude: number;
}

// This hook identifies new coordinates in the polygon based on the slider value
const useSelectedShipInPolygon = (
  layerforPolygonShipAnalysis: Layer[][],
  sliderforPolygonShipAnalysis: number,
  selectedShip: { [key: string]: ShipDetailsInterFace | BunkeringInterface },
  area: { type: string; coordinates: [[number, number][]] },
  dateRange: DateRange
) => {
  // This function returns the active ships in the polygon
  const activeShips = useMemo(() => {
    // Check if the selected ship and layer for polygon ship analysis are valid
    if (
      !selectedShip ||
      Object.keys(selectedShip).length === 0 ||
      !Array.isArray(layerforPolygonShipAnalysis) ||
      layerforPolygonShipAnalysis.length === 0
    ) {
      return {};
    }
    // Create an object to store the updated ships
    const updatedShips: { [key: string]: Ship } = {};

    Object.entries(selectedShip).forEach(([shipId, ship]) => {
      // Check if the ship has valid coordinates
      if (!ship.longitude || !ship.latitude) {
        return;
      }

      // Create an array of coordinates from the ship
      const coordinates: [number, number] = [ship.longitude, ship.latitude];

      // Check if the coordinates are in the polygon area
      if (!isPointInPolygon(coordinates, area)) {
        return;
      }

      let activeCoordinates: Ship | null = null;
      let index = sliderforPolygonShipAnalysis;

      let found = false;
      let startDate = new Date(dateRange.startDate);
      let endDate = new Date(dateRange.endDate);
      const parsedStartDate = new Date(startDate);
      const parsedEndDate = new Date(endDate);
      const startDateStr = moment.utc(parsedStartDate).startOf("day");
      const endDateStr = moment.utc(parsedEndDate).endOf("day");

      const startEpoch = startDateStr.unix();
      const endEpoch = endDateStr.unix();

      const totalSeconds = endEpoch - startEpoch;
      const sliderMax = Math.ceil(totalSeconds / (15 * 60));

      let step = 0;

      while (!found && (index - step >= 0 || index + step <= sliderMax)) {
        if (index - step >= 0) {
          const currentLayer = layerforPolygonShipAnalysis[index - step];
          if (currentLayer) {
            const activeShip = currentLayer.find(
              (item: Layer) => item.synmax_ship_id === shipId
            );
            if (activeShip) {
              activeCoordinates = {
                latitude: activeShip.latitude,
                longitude: activeShip.longitude,
              };
              updatedShips[shipId] = activeCoordinates;
              found = true;
            }
          }
        }
        if (index + step <= sliderMax) {
          const currentLayer = layerforPolygonShipAnalysis[index + step];
          if (currentLayer) {
            const activeShip = currentLayer.find(
              (item: Layer) => item.synmax_ship_id === shipId
            );
            if (activeShip) {
              activeCoordinates = {
                latitude: activeShip.latitude,
                longitude: activeShip.longitude,
              };
              updatedShips[shipId] = activeCoordinates;
              found = true;
            }
          }
        }
        if (found) break; // Exit early if a valid ship is found
        step++;
      }
    });
    return updatedShips;
  }, [
    selectedShip,
    sliderforPolygonShipAnalysis,
    layerforPolygonShipAnalysis,
    area,
    dateRange,
  ]);

  return activeShips;
};

export default useSelectedShipInPolygon;
