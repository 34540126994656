import { useDispatch } from "react-redux";
import { setMinimizePolygonModal } from "../../MainMap.store";
import {
  setAnalyzeShape,
  setIsOpenPolygonShipAnalysisModal,
} from "../PolygonShipAnalysis.store";
import { DateRange } from "../../MainMap";
import { Box } from "@mui/material";
import { CloseOutlined, CropFreeOutlined } from "@mui/icons-material";
import styles from "./PolygonShipAnalysisMinimize.module.scss";

interface PolygonShipAnalysisMinimizeProps {
  setDateRange: (val: DateRange) => void;
}

const PolygonShipAnalysisMinimize = ({
  setDateRange,
}: PolygonShipAnalysisMinimizeProps) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setIsOpenPolygonShipAnalysisModal(false));
    dispatch(setMinimizePolygonModal(false));
    dispatch(setAnalyzeShape([]));
    setDateRange({
      startDate: new Date(),
      endDate: new Date(),
    });
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      className={styles.polygonShipMinimize}
      gap={3}
    >
      Polygon Ship Analysis
      <CropFreeOutlined
        className={styles.polygonShipMinimizeIcon}
        onClick={() => dispatch(setMinimizePolygonModal(false))}
      />
      <CloseOutlined
        className={styles.polygonShipMinimizeIcon}
        onClick={handleClose}
      />
    </Box>
  );
};

export default PolygonShipAnalysisMinimize;
