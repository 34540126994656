import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PolygonLayer } from "@deck.gl/layers/typed";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../../../redux/store";
import { useSnackbar } from "../../../../context/SnackbarContext";
import {
  fetchFuturePathData,
  fetchFuturePathPredictionData,
  fetchFuturePathProgressData,
  setFuturePathIntialValue,
  setIntervalId,
  setLayersFuturePath,
  setPathCluster,
} from "./FuturePathPrediction.store";
import FuturePathPredictionModal from "./FuturePathPredictionModal/FuturePathPredictionModal";
import FuturePathPredictionSlider from "./FuturePathPredictionSlider/FuturePathPredictionSlider";
import Loader from "../../../../components/ShipTools/Loader/Loader";
import {
  AllDetailsTabs,
  BunkeringInterface,
} from "../../../../context/useShipDetails";

export interface FuturePathParams {
  action?: string;
  lat?: number;
  lon?: number;
  speed?: number;
  length?: number;
  heading?: number;
  ship_type?: string;
  object_id?: string | undefined;
}

export type OpenType =
  | "editFuturePath"
  | "closeModal"
  | "futurePathLoader"
  | "futurePathSlider";

const FuturePathPrediction = ({
  shipDetailTabValue,
  shipDetailsTabs,
  bunkeringValue,
}: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, void, any>>();
  const { setNewSnackBar } = useSnackbar();
  const { openPathToolModal } = useSelector(
    (state: RootState) => state.mainMap,
  );
  const {
    futurePathPredictionData,
    futurePathProgressData,
    futurePathData,
    intervalId,
    error,
  } = useSelector((state: RootState) => state.futurePathPrediction);

  const [futurePathParams, setFuturePathParams] = useState<FuturePathParams>(
    {},
  );
  const [open, setOpen] = useState<OpenType>("closeModal");
  const [EditFuturePathParams, setEditFuturePathParams] =
    useState<boolean>(false);

  // Use refs to store abort controllers
  const abortControllerRef = useRef<AbortController | null>(null);
  const progressAbortControllerRef = useRef<AbortController | null>(null);

  // Cleanup function to abort any ongoing requests
  const abortAllRequests = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }

    if (progressAbortControllerRef.current) {
      progressAbortControllerRef.current.abort();
      progressAbortControllerRef.current = null;
    }
  };

  useEffect(() => {
    if (openPathToolModal.futurePrediction) {
      setOpen("editFuturePath");
    } else {
      clearInterval(intervalId);
      abortAllRequests();
      setOpen("closeModal");
    }

    // Cleanup on component unmount
    return () => {
      abortAllRequests();
      clearInterval(intervalId);
    };
  }, [openPathToolModal.futurePathPrediction, intervalId]);

  useEffect(() => {
    if (shipDetailsTabs.length > 0) {
      shipDetailsTabs.map((detailTab: AllDetailsTabs | BunkeringInterface) => {
        if (detailTab.synmax_ship_id === shipDetailTabValue) {
          const isBunkering =
            detailTab.type === "AISSTS" || detailTab.type === "opticalSTS";

          const id =
            bunkeringValue === "2"
              ? detailTab?.synmax_ship_id_2
              : detailTab?.synmax_ship_id_1;

          const tab: AllDetailsTabs =
            isBunkering &&
            detailTab.hasOwnProperty("synmax_ship_id_1") &&
            detailTab.hasOwnProperty("synmax_ship_id_2") &&
            id
              ? (detailTab as BunkeringInterface)[id]
              : detailTab;

          const speed =
            Array.isArray(tab?.speeds) && tab.speeds.length > 0
              ? tab.speeds.reduce((a, c) => a + c, 0) / tab.speeds.length
              : 0;

          // Just set the ship type, defaulting to "other" if none is provided
          const shipType = tab.ship_type || "other";

          setFuturePathParams({
            action: "start",
            heading: tab.heading,
            lat: tab.latitude,
            length: tab.length,
            lon: tab.longitude,
            ship_type: shipType,
            speed: parseFloat(Math.floor(speed).toFixed(2)),
            object_id: tab.object_id ? String(tab.object_id):undefined,
          });
        }
      });
    }
  }, [shipDetailsTabs]);

  const handleSubmit = () => {
    // Cancel any existing requests
    abortAllRequests();

    // Create a new abort controller for this submission
    abortControllerRef.current = new AbortController();

    dispatch(setFuturePathIntialValue());

    // Ensure futurePathParams has all required fields and proper structure
    const shipData = {
      ...futurePathParams,
      action: "start",
      // Ensure numeric values are properly formatted
      lat: Number(futurePathParams.lat),
      lon: Number(futurePathParams.lon),
      speed: Number(futurePathParams.speed),
      heading: Number(futurePathParams.heading),
      length: Number(futurePathParams.length),
      ship_type: String(futurePathParams.ship_type),
      object_id: futurePathParams.object_id ?  String(futurePathParams.object_id): undefined,
    };

    // Set loader state before making the request
    setOpen("futurePathLoader");

    dispatch(
      fetchFuturePathPredictionData({
        shipData,
        signal: abortControllerRef.current.signal,
      }),
    )
      .unwrap()
      .catch((error: any) => {
        // Don't show error if request was intentionally aborted
        if (error?.name === "AbortError") return;

        clearInterval(intervalId);
        setOpen("editFuturePath");

        const errorMessage =
          "We are sorry, we ran into an issue with future path prediction";
        setNewSnackBar({
          message: errorMessage,
          severity: "error",
        });
      });
  };

  useEffect(() => {
    if (futurePathPredictionData.success) {
      // Create new abort controller for progress requests
      progressAbortControllerRef.current = new AbortController();

      // Set up interval for polling progress
      const intervalInstance = setInterval(() => {
        // Only proceed if we're in the loading state
        if (open !== "futurePathLoader") {
          clearInterval(intervalInstance);
          return;
        }

        dispatch(
          fetchFuturePathProgressData({
            action: "progress",
            task_id: futurePathPredictionData.task_id,
            signal: progressAbortControllerRef.current?.signal,
          }),
        )
          .unwrap()
          .catch((error: any) => {
            // Don't show error if request was intentionally aborted
            if (error?.name === "AbortError") return;

            // Handle API failures during progress polling
            clearInterval(intervalInstance);
            dispatch(setIntervalId(null));

            // Only show error and change state if we're still in loading state
            if (open === "futurePathLoader") {
              setOpen("editFuturePath");
              setNewSnackBar({
                message:
                  "Failed to get prediction progress. Please try again later.",
                severity: "error",
              });
            }
          });
      }, 2000);

      dispatch(setIntervalId(intervalInstance));

      return () => {
        clearInterval(intervalInstance);
        dispatch(setIntervalId(null));

        // Abort any ongoing progress requests
        if (progressAbortControllerRef.current) {
          progressAbortControllerRef.current.abort();
          progressAbortControllerRef.current = null;
        }
      };
    }
  }, [futurePathPredictionData.success]);

  useEffect(() => {
    if (futurePathProgressData.status === "Finished") {
      clearInterval(intervalId);
      handleFuturePathLayers();
    }
    if (futurePathProgressData.status === "Failed") {
      clearInterval(intervalId);
      abortAllRequests();

      // Only show error and change state if we're still in loading state
      if (open === "futurePathLoader") {
        setOpen("editFuturePath");
        setNewSnackBar({
          message: "Future path prediction failed. Please try again.",
          severity: "error",
        });
      }
    }
  }, [futurePathProgressData.status]);

  useEffect(() => {
    if (futurePathData?.clusters?.length > 0)
      dispatch(setPathCluster(futurePathData.clusters));
  }, [futurePathData]);

  useEffect(() => {
    if (futurePathData?.futures?.length > 0) getPolygonLayers();
  }, [futurePathData]);

  const getPolygonLayers = () => {
    let finalLayers: any = [];
    futurePathData.futures.length &&
      futurePathData.futures.map((slider: any) => {
        let layers: any = [];
        slider.clusters.map((cluster: any, i: any) => {
          const { probability, wkt } = cluster;
          const wktString: any = wkt[0];
          const regex = /(-?\d+\.\d+)\s(-?\d+\.\d+)/g;
          const matches = [...wktString.matchAll(regex)];
          const coordinatesArray = matches.map((match) => [
            parseFloat(match[2]),
            parseFloat(match[1]),
          ]);

          const newDatapoints: any = [];
          coordinatesArray.forEach((point: any) =>
            newDatapoints.push([point[1], point[0]]),
          );
          layers = [
            ...layers,
            new PolygonLayer({
              id: "PolygonLayer",
              data: [{ data: newDatapoints }],
              getPolygon: (d) => d.data,
              getElevation: 10,
              getFillColor: [255, 0, 0],
              getLineColor: [0, 0, 0],
              getLineWidth: 20,
              lineWidthMinPixels: 1,
              pickable: true,
              opacity: probability,
            }),
          ];
        });
        finalLayers.push(layers);
      });
    dispatch(setLayersFuturePath(finalLayers));
    setOpen("futurePathSlider");
  };

  const handleFuturePathLayers = async () => {
    clearInterval(intervalId);

    // Create a new abort controller for the result fetch
    const resultController = new AbortController();

    try {
      await dispatch(
        fetchFuturePathData({
          action: "result",
          task_id: futurePathPredictionData.task_id,
          signal: resultController.signal,
        }),
      ).unwrap();
    } catch (error: any) {
      // Don't show error if request was intentionally aborted
      if (error?.name === "AbortError") return;

      setOpen("editFuturePath");

      const errorMessage =
        "Failed to retrieve prediction results. Please try again.";
      setNewSnackBar({
        message: errorMessage,
        severity: "error",
      });
    }
  };

  return (
    <>
      <FuturePathPredictionModal
        futurePathParams={futurePathParams}
        setFuturePathParams={setFuturePathParams}
        open={open}
        setOpen={setOpen}
        EditFuturePathParams={EditFuturePathParams}
        setEditFuturePathParams={setEditFuturePathParams}
        handleSubmit={handleSubmit}
      />
      {open === "futurePathLoader" && (
        <Loader
          handleCancelButtonClick={() => {
            clearInterval(intervalId);
            abortAllRequests();
            setOpen("editFuturePath");
          }}
          loadingMessage={futurePathProgressData?.message || "Loading  ...."}
          loaderProgress={futurePathProgressData?.progress || 0}
        />
      )}
      {open === "futurePathSlider" && (
        <FuturePathPredictionSlider
          maxValue={futurePathData?.futures?.length}
          minValue={0}
        />
      )}
    </>
  );
};

export default FuturePathPrediction;
