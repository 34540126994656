import { api } from "../../../../services/api";

// Timeout durations for API calls in milliseconds
const INITIAL_API_TIMEOUT = 15000; // 15 seconds for initial connection and progress checks
const RESULT_API_TIMEOUT = 60000; // 60 seconds for final results

export const fetchFuturePathPrediction = async (
  data: any,
  signal?: AbortSignal,
) => {
  try {
    // Ensure data is properly structured with shipData property
    const payload = data.shipData ? data : { shipData: data };

    // Initial prediction request - use shorter timeout to detect server issues quickly
    const response = await api.post("/ship-tools", payload, {
      timeout: INITIAL_API_TIMEOUT,
      signal,
    });
    return response.data;
  } catch (error: any) {
    console.error("Error fetching future path prediction:", error);
    throw error;
  }
};

export const fetchFuturePathProgress = async (
  data: any,
  signal?: AbortSignal,
) => {
  try {
    // Progress checks - use shorter timeout to detect server issues quickly
    const response = await api.post("/ship-tools/path-progress", data, {
      timeout: INITIAL_API_TIMEOUT,
      signal,
    });
    return response.data;
  } catch (error: any) {
    console.error("Error fetching future path progress:", error);
    throw error;
  }
};

export const fetchFuturePath = async (data: any, signal?: AbortSignal) => {
  try {
    // Final result fetch - allow longer timeout since this might take more time
    const response = await api.post("/ship-tools/path-progress", data, {
      timeout: RESULT_API_TIMEOUT,
      signal,
    });
    return response.data;
  } catch (error: any) {
    console.error("Error fetching future path:", error);
    throw error;
  }
};
