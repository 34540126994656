import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addTab, updateTab, deleteTab, fetchTabs } from "./Header.services";
import { updateWorkStationData } from "../../common/Common.store";
import { updateSharedData } from "../../pages/Shared/Shared.store";

interface Tab {
  id: string;
  name: string;
  value: number;
  workStationId: string;
  folderId: string;
}

export type TabType = Record<string, Tab>;

export type AddTabType = {
  userId: string;
  tabName: string;
  tabValue: number;
  folderId?: string;
  workStationId?: string;
};

export type DeleteTabType = {
  userId: string;
  tabId: string;
};

export const addTabData = createAsyncThunk(
  "header/addTabData",
  async (tabData: AddTabType, { dispatch, getState }: any) => {
    try {
      const data = await addTab(tabData);
      const userId = getState()?.theia?.user?.uid;
      if (data.addTabData.folderId && data.addTabData.workStationId) {
        const updateWorkData = {
          userId,
          folderId: data.addTabData.folderId,
          workStationId: data.addTabData.workStationId,
          tabId: data.addTabData.id,
          isTabId: true,
          type: data.addTabData.type,
        };
        dispatch(updateWorkStationData(updateWorkData));
      } else {
        const updateData = {
          userId,
          workStationId: data.addTabData.workStationId as string,
          tabId: data.addTabData.id,
          isTabId: true,
          type: data.addTabData.type,
        };
        dispatch(updateSharedData(updateData));
      }

      return data;
    } catch (error) {
      throw error;
    }
  },
);

export const updateTabData = createAsyncThunk(
  "header/updateTabData",
  async (tabData: any) => {
    try {
      const data = await updateTab(tabData);
      return data;
    } catch (error) {
      throw error;
    }
  },
);

export const deleteTabData = createAsyncThunk(
  "header/deleteTabData",
  async (tabData: DeleteTabType) => {
    try {
      const data = await deleteTab(tabData);
      return data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchTabsData = createAsyncThunk(
  "header/fetchTabsData",
  async (userId: string) => {
    try {
      const data = await fetchTabs(userId);
      return data;
    } catch (error) {
      throw error;
    }
  },
);

export const headerSlice = createSlice({
  name: "header",
  initialState: {
    tabValue: 0,
    isDashboard: true,
    tabs: {} as TabType,
    loading: false,
    error: "",
    isTabFetch: false,
    selectedTabId: "" as string,
    prevTabId: "" as string,
    isPrevTabId: false,
    isDashboardClick: false,
    isTabSwitch: false,
    isTabDelete: false,
    type: "" as string,
  },
  reducers: {
    setTabValue: (state, action) => {
      state.tabValue = action.payload;
    },
    setIsDashboard: (state, action) => {
      state.isDashboard = action.payload;
    },
    setSelectedTabId: (state, action) => {
      state.selectedTabId = action.payload;
    },
    setTabs: (state, action) => {
      state.tabs = action.payload;
    },
    setPrevTabId: (state, action) => {
      state.prevTabId = action.payload;
    },
    setIsPrevTabId: (state, action) => {
      state.isPrevTabId = action.payload;
    },
    setIsDashboardClick: (state, action) => {
      state.isDashboardClick = action.payload;
    },
    setIsTabSwitch: (state, action) => {
      state.isTabSwitch = action.payload;
    },
    setIsTabDelete: (state, action) => {
      state.isTabDelete = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //ADD TAB
      .addCase(addTabData.pending, (state) => {
        state.loading = true;
      })
      .addCase(addTabData.fulfilled, (state, action: PayloadAction<any>) => {
        const addTabData = action.payload.addTabData;
        state.loading = false;
        state.tabs = {
          ...state.tabs,
          [addTabData.collectionId]: {
            id: addTabData.id,
            name: addTabData.name,
            value: addTabData.value,
            workStationId: addTabData.workStationId,
            folderId: addTabData.folderId,
            type: addTabData.type,
          },
        };
        state.selectedTabId = addTabData.collectionId;
        state.type = addTabData.type;
      })
      .addCase(addTabData.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      //UPDATE TAB
      .addCase(updateTabData.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTabData.fulfilled, (state, action: PayloadAction<any>) => {
        const updateTabData = action.payload.updateTabData;
        state.loading = false;
        if (updateTabData.tabId) {
          state.tabs = {
            ...state.tabs,
            [updateTabData.tabId]: {
              ...state.tabs[updateTabData.tabId],
              name: updateTabData.newWorkStationName,
            },
          };
          state.type = updateTabData.type;
        }
      })
      .addCase(updateTabData.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      //DELETE TAB
      .addCase(deleteTabData.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTabData.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        const deleteTabId = action.payload.deleteTabId;
        const tabs = state.tabs;
        if (tabs[deleteTabId]) {
          delete tabs[deleteTabId];
        }
      })
      .addCase(deleteTabData.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      //GET TAB
      .addCase(fetchTabsData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTabsData.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        const allTabs = action.payload;
        state.tabs = allTabs;
        if (Object.keys(allTabs).length > 0) {
          const tabKeys = Object.keys(allTabs);
          let lowestKey = tabKeys[0];
          tabKeys.forEach((key) => {
            if (allTabs[key].value < allTabs[lowestKey].value) {
              lowestKey = key;
            }
          });
          state.tabValue = allTabs[lowestKey].value;
        }
        state.isTabFetch = true;
      })
      .addCase(fetchTabsData.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setTabValue,
  setIsDashboard,
  setSelectedTabId,
  setTabs,
  setPrevTabId,
  setIsPrevTabId,
  setIsDashboardClick,
  setIsTabSwitch,
  setIsTabDelete,
} = headerSlice.actions;

export const headerReducer = headerSlice.reducer;
