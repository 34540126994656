const AlertsIconBasic = ({ size = 20 }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.667 17.4993H8.33363M11.6705 14.166H4.49101C3.49413 14.166 2.99568 14.166 2.88511 14.084C2.76268 13.9932 2.7305 13.9352 2.71807 13.7833C2.70685 13.6461 3.01291 13.1542 3.62502 12.1705C4.35069 11.0043 5.0003 9.24117 5.0003 6.66602C5.0003 5.33993 5.52709 4.06816 6.46477 3.13048C7.40245 2.1928 8.67422 1.66602 10.0003 1.66602C11.3264 1.66602 12.5982 2.1928 13.5358 3.13048C14.4735 4.06816 15.0003 5.33993 15.0003 6.66602C15.0003 7.43874 15.0005 7.5 15.0005 8"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17 18.1673H19.5L19.5 16.1845C19.5 11.7156 17 9.83398 17 9.83398C17 9.83398 14.5 11.7156 14.5 16.1845L14.5 18.1673H17Z"
      fill="white"
    />
  </svg>
);

export default AlertsIconBasic;
