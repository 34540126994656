import useFireBaseAuth from "../../context/useFireBaseAuth";
import { db } from "../../services/firebase";
import { useEffect, useState } from "react";
import type { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import {
  Select,
  MenuItem,
  Snackbar,
  Dialog,
  TextField,
  Button,
  SelectChangeEvent,
} from "@mui/material";
import { Link } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
import XLSX from "xlsx";
import styles from "./Admin.module.scss";
import axios from "axios";
import { customConsoleError } from "../../utils/utils";

interface Profile {
  id: string;
  role: string;
  userId: string;
  expiry_date: string;
  firstName: string;
  lastName: string;
  creation: string;
  lastLoggedIn: string;
}

const DataTable = () => {
  const { currentUser } = useFireBaseAuth();
  const { role: userRole, email: userEmail } = currentUser;
  const [allProfiles, setAllProfiles] = useState<Profile[]>([]);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [roleChanged, setRoleChanged] = useState(false);
  const [showDialog, setShowDialog] = useState({
    show: false,
    role: "",
    userId: "",
  });
  const [noOfDays, setNoOfDays] = useState<number>(0);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState({
    open: false,
    message: "",
  });

  const changeRole = async (role: string, userId: string) => {
    if (role === "temporary_theia_user") {
      setShowDialog({
        show: true,
        role,
        userId,
      });
    } else {
      const adminUrl =
        process.env.REACT_APP_ENV === "dev"
          ? "https://frontend-admin-api-j75nm34yfa-uc.a.run.app"
          : "https://frontend-admin-api-y5rlib2gfa-uc.a.run.app";
      setIsDataLoading(true);
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      const user = {
        uid: userId,
        role: role,
      };
      try {
        const response = await axios.post(`${adminUrl}/user`, user, {
          headers,
          withCredentials: true,
        });
        const users = response.data;
        const profiles: Profile[] = users.map((user: any) => ({
          id: user.email,
          role: user.role,
          userId: user.uid,
          expiry_date:
            user.expiry != null
              ? moment.unix(user.expiry_date).format("DD MMM YYYY")
              : "-",
          firstName: user.first,
          lastName: user.last,
          creation: user.created,
          lastLoggedIn: user.lastLogin,
        }));
        setAllProfiles(profiles);
      } catch (err) {
        customConsoleError(err);
        setErrorSnackbar({
          open: true,
          message: "Changing roles failed.",
        });
      }
      setIsDataLoading(false);
      setRoleChanged(!roleChanged);
      setIsSnackBarOpen(true);
    }
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "Email", flex: 2 },
    { field: "firstName", headerName: "First", flex: 1 },
    { field: "lastName", headerName: "Last", flex: 1 },
    {
      field: "creation",
      headerName: "Created",
      flex: 2,
      renderCell: (params: GridRenderCellParams<Profile>) => {
        return params.row.creation.substr(5);
      },
    },
    {
      field: "lastLoggedIn",
      headerName: "Last Logged In",
      minWidth: 150,
      flex: 2,
      renderCell: (params: GridRenderCellParams<Profile>) => {
        return params.row.lastLoggedIn.substr(5);
      },
    },
    {
      field: "role",
      headerName: "Role",
      minWidth: 150,
      flex: 3,
      renderCell: (params: GridRenderCellParams<Profile>) => {
        const { userId, role } = params.row;
        // Check the logged-in user's role to determine permissions
        switch (userRole) {
          case "admin":
            return (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Select
                  size="small"
                  value={role}
                  disabled={role === "admin"}
                  onChange={(e: SelectChangeEvent) => {
                    e.stopPropagation();
                    changeRole(e.target.value, userId);
                  }}
                  sx={{ fontSize: "0.875rem", minWidth: 120 }}
                >
                  <MenuItem value="new_user">New User</MenuItem>
                  <MenuItem value="theia_customer">Theia Customer</MenuItem>
                  <MenuItem value="co-worker">Co Worker</MenuItem>
                  {role === "admin" && <MenuItem value="admin">Admin</MenuItem>}
                  {role === "super_user" && (
                    <MenuItem value="super_user">Super User</MenuItem>
                  )}
                </Select>
                {role === "temporary_hyperion_user" && (
                  <div>
                    Expires on :{" "}
                    {moment(params.row.expiry_date).format("DD MMM")}
                  </div>
                )}
                {role === "temporary_theia_user" && (
                  <div>
                    Expires on :{" "}
                    {moment(params.row.expiry_date).format("DD MMM")}
                  </div>
                )}
              </div>
            );
          default:
            return params.value;
        }
      },
    },
  ];

  useEffect(() => {
    getAllUsers();
  }, [roleChanged]);

  const getAllUsers = async () => {
    const adminUrl =
      process.env.REACT_APP_ENV === "dev"
        ? "https://frontend-admin-api-j75nm34yfa-uc.a.run.app"
        : "https://frontend-admin-api-y5rlib2gfa-uc.a.run.app";

    setIsDataLoading(true);
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.get(`${adminUrl}/users`, { headers });
      const profiles = response.data.map(
        (user: {
          email: string;
          role: string;
          uid: string;
          expiry: number | null;
          expiry_date: number;
          first: string;
          last: string;
          created: string;
          lastLogin: string;
        }) => ({
          id: user.email,
          role: user.role,
          userId: user.uid,
          expiry_date:
            user.expiry != null
              ? moment.unix(user.expiry_date).format("DD MMM YYYY")
              : "-",
          firstName: user.first,
          lastName: user.last,
          creation: user.created,
          lastLoggedIn: user.lastLogin,
        }),
      );

      setAllProfiles(profiles);
    } catch (err) {
      customConsoleError(err);
      setErrorSnackbar({
        open: true,
        message: "Getting all users failed.",
      });
    } finally {
      setIsDataLoading(false);
    }
  };

  const giveTemporaryAccess = async () => {
    const { role, userId } = showDialog;
    const userRef = doc(db, "users", userId);

    const expiry_date = moment(Date.now()).add(noOfDays, "days").toDate();

    await updateDoc(userRef, {
      role,
      expiry_date,
    });
    setRoleChanged(!roleChanged);
    setShowDialog({ show: false, role: "", userId: "" });
    setIsSnackBarOpen(true);
  };

  const downloadXLS = () => {
    const users = allProfiles.map(
      ({
        id,
        role,
        expiry_date,
        firstName,
        lastName,
        lastLoggedIn,
        creation,
      }) => ({
        email: id,
        role,
        "expiry date": expiry_date,
        "First Name": firstName,
        "Last Name": lastName,
        "Created On": creation,
        "Last Logged In": lastLoggedIn,
      }),
    );
    const ws = XLSX.utils.json_to_sheet(users);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "All Users");
    XLSX.writeFile(wb, `allUsers.xlsx`);
  };

  return (
    <div className={styles.admin}>
      <Dialog
        open={showDialog.show}
        onClose={(e, reason) => {
          if (reason === "backdropClick") {
            return;
          } else {
            setShowDialog({ show: false, role: "", userId: "" });
          }
        }}
        classes={{ root: styles["MuiDialog-root"] }}
        disableEscapeKeyDown
      >
        <div className={styles.admin__dialog}>
          <div className={styles.dialogHeading}>
            Enter the number of days, this user should have access for:
          </div>
          <TextField
            placeholder="No Of Days"
            type="number"
            value={noOfDays}
            onChange={(e) => setNoOfDays(Number(e.target.value))}
          />
          <Button
            style={{ display: "block", marginTop: 10 }}
            size="small"
            variant="contained"
            onClick={giveTemporaryAccess}
          >
            Give Access
          </Button>
        </div>
      </Dialog>
      <div className={styles.admin__navbar}>
        <div className={styles["admin__navbar--content"]}>
          <div className={styles.admin__navbarText}>Admin</div>
          <div className={styles.admin__navbarText}>
            <Link
              to="/theia"
              style={{ color: "#000", textDecoration: "underline" }}
            >
              Back to Theia
            </Link>
          </div>
        </div>
      </div>
      <Snackbar
        open={isSnackBarOpen}
        autoHideDuration={2000}
        onClose={() => setIsSnackBarOpen(false)}
        message="Role Updated"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
      <Snackbar
        open={errorSnackbar.open}
        autoHideDuration={2000}
        onClose={() => setErrorSnackbar({ open: false, message: "" })}
        message={errorSnackbar.message}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
      <div className={styles.admin__content}>
        <div className={styles.admin__currentUser}>
          Logged in as: <strong>{userEmail}</strong>
          <div className={styles.admin__role}>({userRole})</div>
        </div>
        <div className={styles.admin__download} onClick={downloadXLS}>
          <DownloadIcon style={{ color: "#000" }} /> Download XLS
        </div>
        <div className={styles.admin__table}>
          <DataGrid
            rows={allProfiles}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 100 },
              },
            }}
            pageSizeOptions={[5, 25, 100]}
            loading={isDataLoading}
            sx={{
              height: "100%",
              width: "100%",
              backgroundColor: "white",
              "& .MuiDataGrid-cell": {
                fontSize: "0.875rem",
                padding: "8px 16px",
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DataTable;
