/**
 * Ship type to display name mapping
 */
export const SHIP_TYPE_DISPLAY_NAMES: Record<string, string> = {
  light: "Light",
  dark: "Dark",
  ais: "AIS",
  unattributed: "Unattributed",
  sanctioned: "Sanctioned",
  spoofing: "Spoofing",
  bunkering: "Ship-To-Ship",
  similar: "Similar Ships",
};

/**
 * Default color mapping for each ship type
 */
export const DEFAULT_COLORS: Record<string, string> = {
  light: "#00A3E3",
  dark: "#FFA500",
  ais: "#00EB6C",
  unattributed: "#F75349",
  sanctioned: "#D256FE",
  spoofing: "#FF6D99",
  bunkering: "#55EFE6",
  similar: "#888888",
};

/**
 * Ship type enum
 */
export type ShipType =
  | "AIS"
  | "ais"
  | "light"
  | "dark"
  | "spoofing"
  | "unattributed"
  | "similar"
  | "bunkering"
  | "sanctioned";
