import { useEffect, useState } from "react";
import useGlobalFilters from "../../../../../context/useGlobalFilter";
import { Port, fetchPortData } from "./model";
import { FeatureCollection, Feature } from "geojson";
import { useSnackbar } from "../../../../../context/SnackbarContext";

const usePortsData = () => {
  const [portsGeoJson, setPortsGeoJson] = useState<FeatureCollection | null>(
    null,
  );
  const [portDataIsLoading, setPortDataIsLoading] = useState<boolean>(false);

  const { setNewSnackBar } = useSnackbar();

  const { layerToggleSwitches } = useGlobalFilters();

  useEffect(() => {
    const handleSetPortsGeoJson = (ports: Port[]) => {
      if (ports == null) {
        setPortsGeoJson(null);
        return;
      }

      const features: Feature[] = ports.map((port: Port) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [port.longitude, port.latitude],
        },
        properties: {
          port_name: port.port_name,
          unlocode: port.unlocode,
          latitude: port.latitude,
          longitude: port.longitude,
        },
      }));

      const featureCollection: FeatureCollection = {
        type: "FeatureCollection",
        features: features,
      };

      setPortsGeoJson(featureCollection);
    };

    if (layerToggleSwitches.updatedPorts && portsGeoJson === null) {
      const getPorts = async () => {
        setPortDataIsLoading(true);
        try {
          const response = await fetchPortData();
          handleSetPortsGeoJson(response);
        } catch {
          setNewSnackBar({
            message:
              "We are sorry we were ran into an issue fetching Port data",
            severity: "error",
          });
        } finally {
          setPortDataIsLoading(false);
        }
      };

      getPorts();
    }
  }, [layerToggleSwitches.updatedPorts, portsGeoJson, setNewSnackBar]);

  return {
    portsGeoJson,
    portDataIsLoading,
  };
};

export default usePortsData;
