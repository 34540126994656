import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import App from "./App";
import GlobalProviderLayers from "./pages/Theia/GlobalProviderLayers";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { customConsoleError } from "./utils/utils";

console.error = customConsoleError;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <GlobalProviderLayers>
        <App />
      </GlobalProviderLayers>
    </Provider>
  </ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
