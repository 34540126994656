import styled from "styled-components";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";

export const DialogTitleStyled = styled(DialogTitle)`
  background-color: #393c56;
  color: white;
`;

export const DialogContentStyled = styled(DialogContent)`
  background-color: #393c56;
`;

export const DialogContentTextStyled = styled(DialogContentText)`
  background-color: #393c56;
  color: white;
`;
export const DialogActionsStyled = styled(DialogActions)`
  background-color: #393c56;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

export const StyledButtonCancel = styled(Button)`
  color: white;
  border-color: white;
  &:hover {
    color: white !important;
    border-color: white !important;
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
`;
export const StyledButtonDelete = styled(Button)`
  color: white;
`;

export const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    background-color: black !important;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  color: white;
`;
