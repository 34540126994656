import { AllDetailsTabs } from "../../../../context/useShipDetails";

export const getSynmaxLength = (
  tabDetails: AllDetailsTabs | undefined,
): number | undefined => {
  if (!tabDetails) return undefined;

  if (tabDetails?.ship1_length && tabDetails?.isShip1) {
    return tabDetails?.ship1_length;
  } else if (
    tabDetails?.ship1_length &&
    tabDetails?.ship2_length &&
    !tabDetails?.isShip1
  ) {
    return tabDetails?.ship2_length;
  } else if (
    tabDetails?.type === "light" ||
    tabDetails?.type === "attributed"
  ) {
    return tabDetails?.detectionData?.length;
  } else if (tabDetails?.type === "unattributed") {
    return tabDetails?.length;
  } else {
    return tabDetails?.detectionData?.length ?? tabDetails?.length;
  }
};

export const getSynmaxWidth = (
  tabDetails: AllDetailsTabs | undefined,
): number | undefined => {
  if (!tabDetails) return undefined;

  if (
    tabDetails?.ship1_width &&
    tabDetails?.ship2_width &&
    tabDetails?.isShip1
  ) {
    return tabDetails?.ship1_width;
  } else if (
    tabDetails?.ship1_width &&
    tabDetails?.ship2_width &&
    !tabDetails?.isShip1
  ) {
    return tabDetails?.ship2_width;
  } else if (
    tabDetails?.type === "light" ||
    tabDetails?.type === "attributed"
  ) {
    return tabDetails?.detectionData?.width;
  } else if (tabDetails?.type === "unattributed") {
    return tabDetails?.width;
  } else {
    return tabDetails?.detectionData?.width ?? tabDetails?.width;
  }
};
