import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { onDragStart } from "../../common/Common";
import CreateEditWorkStationDialog from "../Theia/Dialog/CreateEditWorkStationDialog";
import DeleteDialog from "../../layout/Dialog/DeleteDialog";
import WorkCard from "../../layout/WorkCard/WorkCard";
import { Box, Card, CardMedia, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import styles from "../Theia/Theia.module.scss";
import useWorkstation from "./useWorkstation";

const WorkStations = () => {
  const {
    workStationName,
    addWorkStation,
    editWorkStation,
    setWorkStationId,
    deleteWorkStation,
    shareWorkStation,
    setWorkStationName,
    addTabList,
  } = useWorkstation();
  const { searchText } = useSelector((state: RootState) => state.homePanel);
  const { selectedFolderId, allProjects } = useSelector(
    (state: RootState) => state.common,
  );

  const [isCreateWorkStation, setIsCreateWorkStation] =
    useState<boolean>(false);
  const [isEditWorkStation, setIsEditWorkStation] = useState<boolean>(false);
  const [isDeleteWorkStation, setIsDeleteWorkStation] =
    useState<boolean>(false);
  const [isShareWorkStation, setIsShareWorkStation] = useState<boolean>(false);

  const renderWorkCard = (work: string) => (
    <Box
      key={work}
      onDragStart={(e) =>
        onDragStart(
          e,
          allProjects[selectedFolderId].workStations[work].name,
          selectedFolderId,
        )
      }
      draggable
    >
      <WorkCard
        key={work}
        work={work}
        workStationName={allProjects[selectedFolderId].workStations[work].name}
        thumbnail={allProjects[selectedFolderId].workStations[work].thumbnail}
        setWorkStationName={setWorkStationName}
        setWorkStationId={setWorkStationId}
        setIsEditWorkStation={setIsEditWorkStation}
        setIsDeleteWorkStation={setIsDeleteWorkStation}
        setIsShareWorkStation={setIsShareWorkStation}
        addTabList={addTabList}
      />
    </Box>
  );

  return (
    <Box display="flex" flexDirection="column" className={styles.homeMain}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        position="sticky"
        top="0"
        className={styles.homeWrap}
      >
        {!allProjects.msg && Object.keys(allProjects).length > 0 ? (
          <Box className={styles.homeDashbordMain}>
            <Box
              sx={{
                gridTemplateColumns: {
                  xl: "repeat(4,1fr)",
                  lg: "repeat(3,1fr)",
                  md: "repeat(2,1fr)",
                },
              }}
              display="grid"
              gridTemplateColumns="repeat(3,1fr)"
              gap={3}
              className={styles.homeDashbord}
            >
              <Card
                onClick={() => setIsCreateWorkStation(true)}
                className={styles.homeDashbordCard}
              >
                <CardMedia className={styles.dashbordCardAdd}>
                  <Add className={styles.CardAddIcon} />
                  <Typography variant="h6" className={styles.CardAddText}>
                    Create New Work Station
                  </Typography>
                </CardMedia>
              </Card>
              {searchText
                ? Object.keys(allProjects)
                    .flatMap((data: string) =>
                      Object.keys(allProjects[data].workStations)
                        .filter((work: string) =>
                          allProjects[data].workStations[work].name
                            .toLowerCase()
                            .includes(searchText.toLowerCase()),
                        )
                        .map((work: string) => ({
                          workId: work,
                          workStationData: allProjects[data].workStations[work],
                        })),
                    )
                    .map(
                      ({ workId, workStationData }) =>
                        workId &&
                        workStationData && (
                          <Box
                            key={workId}
                            onDragStart={(e) =>
                              onDragStart(
                                e,
                                workStationData.name,
                                selectedFolderId,
                              )
                            }
                            draggable
                          >
                            <WorkCard
                              key={workId}
                              work={workId}
                              workStationName={workStationData.name}
                              thumbnail={workStationData.thumbnail}
                              setWorkStationName={setWorkStationName}
                              setWorkStationId={setWorkStationId}
                              setIsEditWorkStation={setIsEditWorkStation}
                              setIsDeleteWorkStation={setIsDeleteWorkStation}
                              setIsShareWorkStation={setIsShareWorkStation}
                              addTabList={addTabList}
                            />
                          </Box>
                        ),
                    )
                : Object.keys(
                    allProjects[selectedFolderId]?.workStations || {},
                  ).map(renderWorkCard)}
            </Box>
          </Box>
        ) : (
          <Typography variant="h6" className={styles.CardAddText}>
            Please create folder first
          </Typography>
        )}
        {isCreateWorkStation && (
          <CreateEditWorkStationDialog
            open={isCreateWorkStation}
            setOpen={setIsCreateWorkStation}
            name="work_station_name"
            label="Work Station Name"
            title="Create New Work Station"
            buttonName="Create"
            addEditFolder={addWorkStation}
            isEditDialog={false}
          />
        )}
        {isEditWorkStation && (
          <CreateEditWorkStationDialog
            open={isEditWorkStation}
            setOpen={setIsEditWorkStation}
            name="work_station_name"
            label="Work Station Name"
            title="Edit Work Station Name"
            buttonName="Change"
            addEditFolder={editWorkStation}
            oldWorkStationName={workStationName}
          />
        )}
        {isDeleteWorkStation && (
          <DeleteDialog
            open={isDeleteWorkStation}
            setOpen={setIsDeleteWorkStation}
            deleteFolderWorkStation={deleteWorkStation}
          />
        )}
        {isShareWorkStation && (
          <CreateEditWorkStationDialog
            open={isShareWorkStation}
            setOpen={setIsShareWorkStation}
            name="user_email"
            label="Enter User Email"
            title="User Email"
            buttonName="Send"
            addEditFolder={shareWorkStation}
          />
        )}
      </Box>
    </Box>
  );
};

export default WorkStations;
