import { useState, useEffect } from "react";
import { FeatureProperties } from "../DrawTools";
import { calculateMilesBetweenTwoCoordinates } from "../utils";

// retrieveCoordinates is function with a hashmap that return coordinates of the shape based on renderDrawMode
const retrieveCoordinates = (renderDrawMode: string, feature: any) => {
  // Early returns for invalid inputs
  if (!renderDrawMode || !feature?.geometry?.coordinates) {
    return null;
  }

  const coords = feature.geometry.coordinates;

  // Early return if coords is not an array
  if (!Array.isArray(coords)) {
    return null;
  }

  // Early return if coords[0] is not an array
  if (!Array.isArray(coords[0])) {
    return null;
  }

  const getCoordinates: { [key: string]: () => any } = {
    draw_line_string: () => coords[0],
    draw_circle: () => coords[0][0],
    draw_polygon: () => coords[0][0],
    draw_rectangle: () => coords[0][0],
  };

  try {
    return getCoordinates[renderDrawMode]?.() || null;
  } catch (error) {
    console.error("Error retrieving coordinates:", error);
    return null;
  }
};

// getRoundedValue is a helper function for formatting values
const getRoundedValue = (nonRoundedValue: number) => {
  return Math.round(nonRoundedValue * 100) / 100;
};

// Helper function to calculate total distance of a line string
const calculateLineStringTotalDistance = (coordinates: number[][]): number => {
  let totalDistance = 0;

  for (let i = 1; i < coordinates.length; i++) {
    const prevPoint = coordinates[i - 1];
    const currentPoint = coordinates[i];
    totalDistance += parseFloat(
      calculateMilesBetweenTwoCoordinates(
        prevPoint[1],
        prevPoint[0],
        currentPoint[1],
        currentPoint[0],
      ).toString(),
    );
  }

  return totalDistance;
};

// createMarkerList is a function that accepts raw data and converts it into meaningful object
// This object is later used to populate jsx
const createMarkerList = (data: any) => {
  // Check if data exist and object holds atleast one key
  if (data && Object.keys(data).length) {
    // Loop over data object to modify data and create an array out of it
    const markerData = Object.keys(data).map(
      (featureKey: any, index: number) => {
        // Identifying individual feature, properties and renderDrawMode
        const feature = data[featureKey];
        const properties = feature.properties;
        const renderDrawMode = feature.renderDrawMode;

        // use retrieveCoordinates function to identify coordinates
        let markerPoint: [] = retrieveCoordinates(renderDrawMode, feature);

        let totalDistance = 0;
        if (
          renderDrawMode === "draw_line_string" &&
          feature.geometry?.coordinates
        ) {
          totalDistance = calculateLineStringTotalDistance(
            feature.geometry.coordinates,
          );
        }
        // result variable to be returned after every iteration
        // We are returning all the key values which will be consumed in the jsx
        const result: FeatureProperties = {
          markerPoint: markerPoint && markerPoint,
          count: feature.count && feature.count,
          distance: properties.distance && getRoundedValue(properties.distance),
          radius:
            properties.circleRadius && getRoundedValue(properties.circleRadius),
          angle: properties.angle && getRoundedValue(properties.angle),
          area: properties.area,
          ...(renderDrawMode === "draw_line_string" &&
            totalDistance > 0 && {
              totalDistance: getRoundedValue(totalDistance),
            }),
        };
        return result;
      },
    );

    // Return the array
    return markerData;
  }
};

// useMarker is a custom hook that models the data to be later consumed by the JSX
const useMarker = (data: any) => {
  // Inintialize state to store the data
  const [markerData, setMarkerData] = useState<FeatureProperties[]>([]);

  // This useEffect hook is triggered every time the data changes to ensure jsx shows up to do information on the UI.
  useEffect(() => {
    if (data) {
      const marker: any = createMarkerList(data);
      setMarkerData(marker);
    }
  }, [data]);

  // Return markerData state
  return markerData;
};

export default useMarker;
