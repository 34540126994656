import {
  DEFAULT_SHIP_ICON_WIDTH,
  DEFAULT_SHIP_ICON_HEIGHT,
} from "../../../utils/Constants";

export const AISShipSanctioned = (
  color = "#00A3E3",
  width = DEFAULT_SHIP_ICON_WIDTH,
  height = DEFAULT_SHIP_ICON_HEIGHT
) => {
  return `<svg
      width=${width}
      height=${height}
      viewBox="0 0 14 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.9999 21.5L12.8235 21.5L12.8235 16.7413C12.8235 6.01595 6.99989 1.5 6.99989 1.5C6.99989 1.5 1.17627 6.01595 1.17628 16.7413L1.17628 21.5H6.9999Z"
        fill=${color}
        stroke="#111326"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path d="M12.6763 21.5L3.17627 7.5" stroke="#111326" stroke-width="1.5" />
    </svg>`;
};
