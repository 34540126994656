import { Box, Button, LinearProgress } from "@mui/material";
import { useState, useEffect } from "react";
import styles from "./Loader.module.scss";

interface LoaderProps {
  handleCancelButtonClick: () => void;
  loadingMessage?: string;
  loaderProgress: number | string;
}

const Loader = ({
  handleCancelButtonClick,
  loadingMessage,
  loaderProgress,
}: LoaderProps) => {
  const [normalizedProgress, setNormalizedProgress] = useState(0);

  useEffect(() => {
    // Normalize the loader progress value to ensure it is a number between 0 and 100.
    const newProgress =
      typeof loaderProgress === "string"
        ? Math.min(Math.max(parseFloat(loaderProgress) || 0, 0), 100)
        : Math.min(Math.max(loaderProgress || 0, 0), 100);

    setNormalizedProgress(newProgress);
  }, [loaderProgress]);

  return (
    <Box className={styles.futurePathPredictionLoader}>
      <LinearProgress
        variant="determinate"
        value={normalizedProgress}
        classes={{
          root: styles.linearProgressBar,
          bar: styles.linearProgressBarFill,
        }}
      />
      <Box className={styles.loadingContent}>
        <p className={styles.content}>
          {loadingMessage && `${normalizedProgress}% ${loadingMessage}`}
        </p>
        <Button
          type="button"
          variant="text"
          onClick={handleCancelButtonClick}
          className={styles.cancelBtn}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default Loader;
