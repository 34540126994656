import {
  DEFAULT_SELECTED_SHIP_ICON_WIDTH,
  DEFAULT_SELECTED_SHIP_ICON_HEIGHT,
} from "../../../utils/Constants";

export const UnattributedShipSelected = (
  isActiveShip: boolean,
  color = "#00A3E3",
  width = DEFAULT_SELECTED_SHIP_ICON_WIDTH,
  height = DEFAULT_SELECTED_SHIP_ICON_HEIGHT
) => {
  return `<svg width="${width}" height="${height}" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="25" cy="25.5" r="25" fill="url(#paint0_radial_3218_29340)" fill-opacity="${
    isActiveShip ? "1" : "0.5"
  }" />
  <path d="M25.0004 35.5H30.824L30.824 30.7413C30.824 20.0159 25.0004 15.5 25.0004 15.5C25.0004 15.5 19.1768 20.0159 19.1768 30.7413L19.1768 35.5H25.0004Z" fill="#F75349" stroke="white" stroke-width="1.5" stroke-miterlimit="10" />
  <circle cx="24.9766" cy="27" r="2.5" stroke="white" stroke-width="1.5" />
  <path d="M27.3174 31.9748C27.5446 32.3212 28.0095 32.4179 28.3559 32.1908C28.7023 31.9637 28.799 31.4987 28.5719 31.1523L27.3174 31.9748ZM25.6726 29.4659L27.3174 31.9748L28.5719 31.1523L26.927 28.6435L25.6726 29.4659Z" fill="white" />
  <defs>
    <radialGradient id="paint0_radial_3218_29340" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25 25.5) rotate(90) scale(25)">
      <stop offset="0.27" stop-color="white" stop-opacity="0" />
      <stop offset="1" stop-color="white" />
    </radialGradient>
  </defs>
</svg>`;
};
