import { FormEvent } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import styles from "../../../layout/Dialog/Dialog.module.scss";
import { WORKSTATION_CLOUD, WORKSTATION_MAP } from "../../../utils/Constants";

export type WorkStationData = {
  workStationName: string;
  workStationMode: string;
  workStationType: string;
};

interface CreateFolderDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  name: string;
  label: string;
  title: string;
  buttonName: string;
  addEditFolder: (value: string | WorkStationData) => void;
  oldWorkStationName?: string;
  isEditDialog?: boolean;
}

const CreateEditWorkStationDialog = ({
  open,
  setOpen,
  title,
  name,
  label,
  buttonName,
  addEditFolder,
  oldWorkStationName,
  isEditDialog = true,
}: CreateFolderDialogProps) => {
  const handleClose = () => setOpen(false);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: (event: FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries((formData as any).entries());

          if (name === "user_email" && formJson.user_email) {
            const userEmail = formJson.user_email;
            addEditFolder(userEmail);
          } else {
            const workStationName = formJson.work_station_name;
            const workStationMode = WORKSTATION_CLOUD;
            // const workStationType = formJson.work_station_type;
            const workStationType = WORKSTATION_MAP;
            addEditFolder({
              workStationName,
              workStationMode,
              workStationType,
            });
          }
          handleClose();
        },
      }}
      className={styles.dialogMain}
      classes={{ paper: styles.dialogWrap }}
    >
      <DialogTitle className={styles.dialogHead}>{title}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          required
          margin="dense"
          id={name}
          name={name}
          label={label}
          type="text"
          fullWidth
          variant="standard"
          defaultValue={oldWorkStationName}
          InputLabelProps={{
            classes: {
              root: styles.dialogLabel,
              focused: styles.dialogLabelFocused,
            },
          }}
          InputProps={{
            classes: {
              root: styles.dialogInput,
              input: styles.test,
              focused: styles.dialogInputFocused,
            },
          }}
          className={styles.dialogTextFild}
        />
        {/* 
          Keeping this here in the case we decide to add the ability to change the type of workspace
        {name !== "user_email" && !isEditDialog && (
          <>
            <Box
              display="flex"
              flexDirection="column"
              className={styles.modeWrap}
            >
              <h6 className={styles.modesHead}>Type :</h6>
              <FormControl>
                <RadioGroup
                  className={styles.radioGroup}
                  defaultValue={WORKSTATION_MAP}
                  name="work_station_type"
                >
                  {WORKSPACE_TYPE.map((workStationType) => (
                    <FormControlLabel
                      className={styles.dialogtext}
                      key={workStationType}
                      value={workStationType}
                      control={<Radio className={styles.radioButton} />}
                      label={workStationType}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
          </>
        )} */}
      </DialogContent>
      <DialogActions>
        <Button className={styles.button} onClick={handleClose}>
          Cancel
        </Button>
        <Button className={styles.buttonCreate} type="submit">
          {buttonName}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEditWorkStationDialog;
