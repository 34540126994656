import {
  DEFAULT_SELECTED_SHIP_ICON_WIDTH,
  DEFAULT_SELECTED_SHIP_ICON_HEIGHT,
} from "../../../utils/Constants";

export const LightShipSelected = (
  isActiveShip: boolean,
  color = "#00A3E3",
  width = DEFAULT_SELECTED_SHIP_ICON_WIDTH,
  height = DEFAULT_SELECTED_SHIP_ICON_HEIGHT
) => {
  return `<svg width="${width}" height="${height}" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="25" cy="25.5" r="25" fill="url(#paint0_radial_3218_29324)" fill-opacity="${
    isActiveShip ? "1" : "0.5"
  }" />
  <path d="M24.8236 35.5H30.6473L30.6473 30.7413C30.6472 20.0159 24.8236 15.5 24.8236 15.5C24.8236 15.5 19 20.0159 19 30.7413L19 35.5H24.8236Z" fill="#00A3E3" stroke="white" stroke-width="1.5" stroke-miterlimit="10" />
  <path d="M26.8183 30.1988L26.5242 29.8976L26.878 29.5128C27.3044 29.0416 27.4955 28.6509 27.5858 28.0685C27.7069 27.2365 27.4309 26.4076 26.8349 25.7972L26.5251 25.4799L26.8263 25.1858L27.1275 24.8917L27.4137 25.1849C28.0647 25.8515 28.4229 26.7647 28.4076 27.7211C28.3923 28.6775 28.11 29.3846 27.4623 30.1112L27.1124 30.5L26.8183 30.1988Z" fill="white" />
  <path d="M22.8312 30.1988L23.1253 29.8976L22.7714 29.5128C22.3451 29.0416 22.1539 28.6509 22.0636 28.0685C21.9426 27.2365 22.2185 26.4076 22.8145 25.7972L23.1243 25.4799L22.8231 25.1858L22.522 24.8917L22.2357 25.1849C21.5847 25.8515 21.2265 26.7647 21.2418 27.7211C21.2572 28.6775 21.5394 29.3846 22.1871 30.1112L22.5371 30.5L22.8312 30.1988Z" fill="white" />
  <circle cx="24.8232" cy="27.75" r="2" fill="white" />
  <defs>
    <radialGradient id="paint0_radial_3218_29324" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25 25.5) rotate(90) scale(25)">
      <stop offset="0.27" stop-color="white" stop-opacity="0" />
      <stop offset="1" stop-color="white" />
    </radialGradient>
  </defs>
</svg>`;
};
