import { useState } from "react";
import { PersonOutlineOutlined } from "@mui/icons-material";
import { Box, Menu, MenuItem } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import LockResetIcon from "@mui/icons-material/LockReset";
import styles from "./UserProfile.module.scss";
import useFireBaseAuth from "../../../context/useFireBaseAuth";

const UserProfile = () => {
  const { handleUserLogOut, openChangePasswordModal } = useFireBaseAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenChangePasswordModal = () => {
    openChangePasswordModal();
    handleClose();
  };

  return (
    <Box>
      <Box onClick={handleClick}>
        <PersonOutlineOutlined className={styles.icon} />
      </Box>
      <Menu
        id="basic-menu"
        onClose={handleClose}
        anchorEl={anchorEl}
        open={anchorEl ? true : false}
        classes={{ paper: styles.menuWrap }}
      >
        <MenuItem className={styles.menuItem}>
          <Box
            display="flex"
            alignItems="center"
            gap="8px"
            className={styles.logoutButtonWrap}
            onClick={(e) => {
              e.stopPropagation();
              handleUserLogOut();
            }}
          >
            <LogoutIcon
              style={{
                fontSize: "20px",
              }}
            />
            Logout
          </Box>
        </MenuItem>
        <MenuItem className={styles.menuItem}>
          <Box
            display="flex"
            alignItems="center"
            gap="8px"
            className={styles.logoutButtonWrap}
            onClick={(e) => {
              e.stopPropagation();
              handleOpenChangePasswordModal();
            }}
          >
            <LockResetIcon
              style={{
                fontSize: "20px",
              }}
            />
            Change Password
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UserProfile;
