import { useState } from "react";
import { WorkStationData } from "../Theia/Dialog/CreateEditWorkStationDialog";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import useFireBaseAuth from "../../context/useFireBaseAuth";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { DEFAULT_MAP_STATE } from "../../utils/Constants";
import {
  addWorkStationData,
  deleteWorkStationData,
  updateWorkStationData,
} from "../../common/Common.store";
import {
  findTabIdFromWorkId,
  findWorkStationInTabs,
  getUserByEmail,
} from "../../common/Common";
import { addNotificationData } from "../../layout/Header/Notification/Notification.store";
import {
  addTabData,
  setIsDashboard,
  setSelectedTabId,
  setTabValue,
} from "../../layout/Header/Header.store";
import { ICurrentEventTypeFilterOptions } from "../Theia/MapComponents/FilterArea/FilterArea";
import { setIsRecent } from "../../layout/HomePanel/HomePanel.store";
import { setIsShared } from "../Shared/Shared.store";
import useSelectedWorkstation from "../../context/useSelectedWorkstation";
import { useSnackbar } from "../../context/SnackbarContext";

export interface IMapState {
  showGlobeView: boolean;
  zoomLevel: number;
  mapLatLng: {
    lat: number;
    lng: number;
  };
  date: string | null;
  mapFilters: ICurrentEventTypeFilterOptions;
  myShips: {
    synmax_ship_id: string;
    object_id: number;
    type: string;
    lat?: number;
    lng?: number;
    synmax_ship_id_1?: string;
    synmax_ship_id_2?: string;
    object_id_1?: number;
    object_id_2?: number;
    path: { startDate: string; endDate: string; color: string };
    path2?: { startDate: string; endDate: string; color: string };
    name: string;
    heading: number;
  }[];
  mySatellite: {
    synmax_ship_id: string;
    name: string;
  }[];
  myEvents: {
    synmax_ship_id: string;
    type: string;
    path: { startDate: string; endDate: string; color: string };
    name: string;
  }[];
  sidebarTabIndex: number;
  sidebarDrawer: boolean;
  shipDetailsDrawer: boolean;
  polygonFeatures: any;
  activeTabId: string | null;
  mapStyle: string;
}

const useWorkstation = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, void, any>>();
  const { setSelectedWorkstation } = useSelectedWorkstation();
  const { currentUser } = useFireBaseAuth();
  const { tabs } = useSelector((state: RootState) => state.header);
  const [workStationId, setWorkStationId] = useState<string>("");
  const [workStationName, setWorkStationName] = useState<string>("");
  const { setNewSnackBar } = useSnackbar();
  const { selectedFolderId, allProjects, users } = useSelector(
    (state: RootState) => state.common,
  );
  const userId = currentUser.uid;

  const addWorkStation = async (workStationData: WorkStationData | string) => {
    const { workStationName, workStationMode, workStationType } =
      workStationData as WorkStationData;
    const data: {
      userId: any;
      folderId: string;
      workStationName: string;
      mode: string;
      type: string;
      state?: object;
      mapState?: any;
    } = {
      userId,
      folderId: selectedFolderId,
      workStationName: workStationName,
      mode: workStationMode,
      type: workStationType,
    };

    if (workStationType === "Whiteboard") {
      data.state = {};
    } else {
      data.mapState = DEFAULT_MAP_STATE;
    }

    dispatch(addWorkStationData(data));
    setNewSnackBar({ message: "Work Station Added", severity: "success" });
  };

  const editWorkStation = async (workStationData: WorkStationData | string) => {
    const { workStationName, workStationMode } =
      workStationData as WorkStationData;
    for (const key in tabs) {
      if (
        tabs[key].id ===
        allProjects[selectedFolderId].workStations[workStationId].tabId
      ) {
        const data = {
          userId,
          folderId: selectedFolderId,
          workStationId,
          newWorkStationName: workStationName,
          tabId: key,
        };
        dispatch(updateWorkStationData(data));
        return;
      }
    }
    const data = {
      userId,
      folderId: selectedFolderId,
      workStationId,
      newWorkStationName: workStationName,
      newWorkStationMode: workStationMode,
    };
    dispatch(updateWorkStationData(data));
    setNewSnackBar({ message: "Work Station Updated", severity: "success" });
  };

  const deleteWorkStation = async () => {
    for (const key in tabs) {
      if (
        tabs[key].id ===
        allProjects[selectedFolderId].workStations[workStationId].tabId
      ) {
        const data = {
          userId,
          folderId: selectedFolderId,
          workStationId,
          tabId: key,
        };
        dispatch(deleteWorkStationData(data));
        return;
      }
    }
    const data = {
      userId,
      folderId: selectedFolderId,
      workStationId,
    };
    dispatch(deleteWorkStationData(data));
    setNewSnackBar({ message: "Work Station Deleted", severity: "error" });
  };

  const shareWorkStation = async (userEmail: string | WorkStationData) => {
    const emailUserId = await getUserByEmail(users, userEmail.toString());
    const shareWorkData = {
      userId: emailUserId as string,
      workStationName,
      sharedUserId: userId,
      type: "share",
    };
    dispatch(addNotificationData(shareWorkData));
    setNewSnackBar({ message: "Work Station Shared", severity: "success" });
  };

  const addTabList = (name: string, workId: string) => {
    const workStationData = findWorkStationInTabs(workId, tabs);
    const selectedTabId = findTabIdFromWorkId(tabs, workId);
    // Can consider setting a global state to always have selected object
    const selectedWorkStation =
      allProjects[selectedFolderId].workStations[workId];
    const updateDatedata = {
      userId,
      folderId: selectedFolderId,
      workStationId: workId,
      isWorkStationDate: true,
      mapState: selectedWorkStation.mapState as IMapState,
      type: selectedWorkStation.type ? selectedWorkStation.type : "Map",
    };

    // This is my attempt on slowly moving away from redux
    setSelectedWorkstation(selectedWorkStation);

    if (workStationData) {
      dispatch(setTabValue(workStationData.value));
      dispatch(setSelectedTabId(selectedTabId));
    } else {
      const maxTabValue =
        Object.keys(tabs).length > 0
          ? Math.max(...Object.values(tabs).map((item) => item.value)) + 1
          : 0;
      const newTab = {
        userId,
        tabName: name,
        tabValue: maxTabValue,
        folderId: selectedFolderId,
        workStationId: workId,
        type: selectedWorkStation.type ? selectedWorkStation.type : "Map",
      };
      dispatch(addTabData(newTab));
      dispatch(setTabValue(maxTabValue));
    }
    dispatch(setIsDashboard(false));
    dispatch(setIsRecent(false));
    dispatch(setIsShared(false));
    dispatch(updateWorkStationData(updateDatedata));
  };

  return {
    workStationName,
    addWorkStation,
    editWorkStation,
    deleteWorkStation,
    setWorkStationId,
    shareWorkStation,
    setWorkStationName,
    addTabList,
  };
};

export default useWorkstation;
