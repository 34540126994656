import { useRef, useEffect } from "react";
import {
  DEFAULT_SHIP_ICON_WIDTH_SPOOFING,
  DEFAULT_SHIP_ICON_HEIGHT_SPOOFING,
} from "../../../utils/Constants";

export const SpoofingShips = (
  color = "#FF6D99",
  width = DEFAULT_SHIP_ICON_WIDTH_SPOOFING,
  height = DEFAULT_SHIP_ICON_HEIGHT_SPOOFING,
) => {
  return `<svg
      width="${width}"
      height="${height}"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="11"
        y="1.5"
        width="12.7282"
        height="12.7282"
        transform="rotate(45 11 1.5)"
        fill="${color}"
        stroke="#111326"
        stroke-width="1.5"
      />
      <path
        d="M11 11.25V7"
        stroke="#111326"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="11" cy="14" r="1" fill="#111326" />
    </svg>`;
};

const SpoofingShipsIcon = ({ color, width, height }: any) => {
  const svgWrapperRef: any = useRef();
  useEffect(() => {
    if (svgWrapperRef.current) {
      svgWrapperRef.current.innerHTML = SpoofingShips(color, width, height);
    }
  }, [color, width, height]);

  return <div ref={svgWrapperRef}></div>;
};

export default SpoofingShipsIcon;

const SpoofingShips1 = ({ width = 105, height = 100 }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="11"
        y="1.5"
        width="12.7282"
        height="12.7282"
        transform="rotate(45 11 1.5)"
        fill="#FF6D99"
        stroke="#111326"
        stroke-width="1.5"
      />
      <path
        d="M11 11.25V7"
        stroke="#111326"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="11" cy="14" r="1" fill="#111326" />
    </svg>
  );
};
