import { ArrowForwardIos } from "@mui/icons-material";
import { Box, Collapse, Divider, styled } from "@mui/material";
import { ShipCountsInView } from "./MainMap";

const StyledShipCountDivider = styled(Divider)(() => ({
  background: "#ffffff4d",
  height: "19px",
  marginLeft: "20px",
  marginRight: "20px",
}));

const ShipCountItem = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ShipCounter = styled(Box)(() => ({
  fontWeight: 700,
  marginLeft: "3px",
}));

const MainMapHeaderShipCounts: React.FC<{
  shipsInCurrentViewCountIsShown: boolean;
  shipCountsInCurrentView: ShipCountsInView;
  showGlobeView: boolean;
}> = ({
  shipsInCurrentViewCountIsShown,
  shipCountsInCurrentView,
  showGlobeView,
}) => {
  return (
    <Collapse
      orientation="vertical"
      in={shipsInCurrentViewCountIsShown}
      collapsedSize={0}
      timeout={400}
      sx={{ overflowX: "hidden", minWidth: "1500px" }}
    >
      <Box
        sx={{
          height: "45px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          marginLeft: "100px",
          marginTop: "15px",
        }}
      >
        <ShipCountItem sx={{ marginLeft: "20px", minWidth: "280px" }}>
          <Box>{`Total ships in ${
            showGlobeView ? "globe" : "current"
          } view: `}</Box>
          <ShipCounter>{shipCountsInCurrentView.total}</ShipCounter>
          <ArrowForwardIos style={{ fontSize: "18px" }} />
        </ShipCountItem>
        <ShipCountItem
          sx={{
            marginLeft: "20px",
            color: "#00EB6C",
            minWidth: "85px",
          }}
        >
          <Box>AIS:</Box>
          <ShipCounter>{shipCountsInCurrentView.ais}</ShipCounter>
        </ShipCountItem>
        <StyledShipCountDivider orientation="vertical" />
        <ShipCountItem sx={{ color: "#0094FF", minWidth: "100px" }}>
          <Box>Light:</Box>
          <ShipCounter>{shipCountsInCurrentView.light}</ShipCounter>
        </ShipCountItem>
        <StyledShipCountDivider orientation="vertical" />
        <ShipCountItem sx={{ color: "#FFA949", minWidth: "100px" }}>
          <Box>Dark:</Box>
          <ShipCounter>{shipCountsInCurrentView.dark}</ShipCounter>
        </ShipCountItem>
        <StyledShipCountDivider orientation="vertical" />
        <ShipCountItem sx={{ color: "#FA5849", minWidth: "160px" }}>
          <Box>Unattributed:</Box>
          <ShipCounter>{shipCountsInCurrentView.unattributed}</ShipCounter>
        </ShipCountItem>
        <StyledShipCountDivider orientation="vertical" />
        <ShipCountItem sx={{ color: "#FF6D99", minWidth: "130px" }}>
          <Box>Spoofing:</Box>
          <ShipCounter>{shipCountsInCurrentView.spoofing}</ShipCounter>
        </ShipCountItem>
        <StyledShipCountDivider orientation="vertical" />
        <ShipCountItem sx={{ color: "#55EFE6", minWidth: "140px" }}>
          <Box>Ship-To-Ship:</Box>
          <ShipCounter>{shipCountsInCurrentView.bunkering}</ShipCounter>
        </ShipCountItem>
        <StyledShipCountDivider orientation="vertical" />
      </Box>
    </Collapse>
  );
};

export default MainMapHeaderShipCounts;
