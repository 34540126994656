import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Map from "./MainMap";
import Recent from "../Recent/Recent";
import Shared from "../Shared/Shared";
import BannerModal from "./BannerModal/BannerModal";
import MapProviderLayers from "./MapProviderLayers/MapProviderLayers";
import ChangePasswordModal from "../../components/ChangePasswordModal/ChangePasswordModal";
import SessionTimeoutModal from "../../components/SessionTimeoutModal/SessionTimeoutModal";
import WorkStations from "../Workstations/WorkStations";
import useSelectedWorkstation from "../../context/useSelectedWorkstation";

const Theia = () => {
  const { isDashboard } = useSelector((state: RootState) => state.header);
  const { isRecent } = useSelector((state: RootState) => state.homePanel);
  const { selectedWorkstation } = useSelectedWorkstation();
  const { isShared } = useSelector((state: RootState) => state.shared);

  const getWorkstationType = () => {
    switch (selectedWorkstation.type) {
      case "Map":
        return (
          <MapProviderLayers>
            <Map />
          </MapProviderLayers>
        );
      default:
        return (
          <MapProviderLayers>
            <Map />
          </MapProviderLayers>
        );
    }
  };

  return (
    <>
      <SessionTimeoutModal />
      <BannerModal />
      <ChangePasswordModal />
      {isDashboard && !isRecent && !isShared ? (
        <WorkStations />
      ) : isRecent && isDashboard && !isShared ? (
        <Recent />
      ) : isShared && !isRecent && isDashboard ? (
        <Shared />
      ) : (
        getWorkstationType()
      )}
    </>
  );
};

export default Theia;
