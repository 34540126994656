import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchFuturePath,
  fetchFuturePathPrediction,
  fetchFuturePathProgress,
} from "./FuturePathPrediction.services";
import { FuturePathParams } from "./FuturePathPrediction";

interface FuturePathPrediction {
  success: boolean;
  task_id: string;
  version: string;
}

interface FuturePathProgress {
  message: string;
  progress: string | number;
  status: string;
  task_id?: string;
  timestamp?: number;
}

// Helper function to retry API calls
const retryApiCall = async (
  apiCall: (signal?: AbortSignal) => Promise<any>,
  signal?: AbortSignal,
  maxRetries = 2,
) => {
  let lastError;
  for (let attempt = 0; attempt <= maxRetries; attempt++) {
    try {
      return await apiCall(signal);
    } catch (error: any) {
      // If aborted, immediately stop retrying
      if (error?.name === "AbortError") {
        throw error;
      }

      // Don't retry on 4xx errors (client errors)
      if (error?.response?.status >= 400 && error?.response?.status < 500) {
        throw error;
      }

      // Special case: 503 Service Unavailable - might recover quickly
      if (error?.response?.status === 503 && attempt < maxRetries) {
        lastError = error;
        await new Promise((resolve) => setTimeout(resolve, 1000));
        continue;
      }

      // Handle timeouts - might need a longer wait before retry
      if (
        (error.code === "ECONNABORTED" || error.message?.includes("timeout")) &&
        attempt < maxRetries
      ) {
        lastError = error;
        // 2000, 4000, 8000 ms delays using simple multiplication
        await new Promise((resolve) =>
          setTimeout(resolve, 2000 * (attempt + 1)),
        );
        continue;
      }

      // Generic error handling with exponential backoff
      lastError = error;
      if (attempt < maxRetries) {
        // 1000, 2000, 4000 ms delays using simple multiplication
        await new Promise((resolve) =>
          setTimeout(resolve, 1000 * (attempt + 1)),
        );
      }
    }
  }

  throw lastError;
};

export const fetchFuturePathPredictionData = createAsyncThunk(
  "futurePathPrediction/fetchFuturePathPredictionData",
  async (
    params: { shipData: any; signal?: AbortSignal },
    { rejectWithValue },
  ) => {
    try {
      // Use retry mechanism for API call
      const data = await retryApiCall(
        (signal) => fetchFuturePathPrediction(params.shipData, signal),
        params.signal,
      );
      return data;
    } catch (error: any) {
      console.error("Error in fetchFuturePathPredictionData:", error);
      return rejectWithValue(error);
    }
  },
);

export const fetchFuturePathProgressData = createAsyncThunk(
  "futurePathPrediction/fetchFuturePathProgressData",
  async (
    params: { action: string; task_id: string; signal?: AbortSignal },
    { rejectWithValue },
  ) => {
    try {
      // Use retry mechanism for API call
      const data = await retryApiCall(
        (signal) => fetchFuturePathProgress(params, signal),
        params.signal,
      );
      return data;
    } catch (error: any) {
      console.error("Error in fetchFuturePathProgressData:", error);
      return rejectWithValue(error);
    }
  },
);

export const fetchFuturePathData = createAsyncThunk(
  "futurePathPrediction/fetchFuturePathData",
  async (
    params: { action: string; task_id: string; signal?: AbortSignal },
    { rejectWithValue },
  ) => {
    try {
      // Use retry mechanism for API call
      const data = await retryApiCall(
        (signal) => fetchFuturePath(params, signal),
        params.signal,
      );
      return data;
    } catch (error: any) {
      console.error("Error in fetchFuturePathData:", error);
      return rejectWithValue(error);
    }
  },
);

export const futurePathPredictionSlice = createSlice({
  name: "futurePathPrediction",
  initialState: {
    loading: false,
    error: "",
    futurePathPredictionData: {} as FuturePathPrediction,
    futurePathProgressData: {} as FuturePathProgress,
    futurePathData: [] as any,
    pathCluster: [] as any,
    slider: 0,
    intervalId: "",
    layers: [] as any,
    futurePathParams: {} as FuturePathParams,
  },
  reducers: {
    setPathCluster: (state, action) => {
      state.pathCluster = action.payload;
    },
    setSliderForFuturePath: (state, action) => {
      state.slider = action.payload;
    },
    setFuturePathIntialValue: (state) => {
      state.futurePathPredictionData = {} as FuturePathPrediction;
      state.futurePathProgressData = {} as FuturePathProgress;
      state.futurePathData = [] as any;
      state.slider = 0;
      state.error = "";
      state.layers = [];
      state.intervalId = "";
    },
    setLayersFuturePath: (state, action) => {
      state.layers = action.payload;
    },
    setIntervalId: (state, action) => {
      state.intervalId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //GET FUTURE PATH PREDICTION
      .addCase(fetchFuturePathPredictionData.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(
        fetchFuturePathPredictionData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.futurePathPredictionData = action.payload;
        },
      )
      .addCase(fetchFuturePathPredictionData.rejected, (state, action: any) => {
        state.loading = false;
        // Always set a generic user-friendly error
        state.error = "Error occurred";
        // Reset state on error
        state.futurePathProgressData = {} as FuturePathProgress;
      })
      //GET FUTURE PATH PROGRESS
      .addCase(fetchFuturePathProgressData.pending, (state) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(
        fetchFuturePathProgressData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.futurePathProgressData = action.payload;
        },
      )
      .addCase(fetchFuturePathProgressData.rejected, (state, action: any) => {
        state.loading = false;
        // Always set a generic user-friendly error
        state.error = "Error occurred";
      })
      //GET FUTURE PATH DATA
      .addCase(fetchFuturePathData.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(
        fetchFuturePathData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.futurePathData = action.payload;
        },
      )
      .addCase(fetchFuturePathData.rejected, (state, action: any) => {
        state.loading = false;
        // Always set a generic user-friendly error
        state.error = "Error occurred";
      });
  },
});

export const {
  setPathCluster,
  setSliderForFuturePath,
  setFuturePathIntialValue,
  setLayersFuturePath,
  setIntervalId,
} = futurePathPredictionSlice.actions;

export const futurePathPredictionReducer = futurePathPredictionSlice.reducer;
