import React, { Component, ErrorInfo, ReactNode } from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import styles from "./ErrorBoundary.module.scss";
import { customConsoleError } from "../../utils/utils";

interface Props {
  children: ReactNode;
  fallback?: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

/**
 * ErrorBoundary component that catches JavaScript errors anywhere in their child
 * component tree, logs those errors, and displays a fallback UI.
 *
 * Note: componentDidCatch must be used in a class component as it's a lifecycle method.
 * Error boundaries must be class components and cannot be implemented with hooks/useEffect.
 */
class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      error,
      errorInfo: null,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // Log the error to console and potentially to an error reporting service
    customConsoleError("ErrorBoundary caught an error:", error, errorInfo);
    this.setState({
      error,
      errorInfo,
    });
  }

  handleReset = (): void => {
    this.setState({
      hasError: false,
      error: null,
      errorInfo: null,
    });
    // Reload the page to reset the application state
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      // If a custom fallback is provided, use it
      if (this.props.fallback) {
        return this.props.fallback;
      }

      // Default fallback UI
      return (
        <div
          className={styles.errorContainer}
          data-testid="error-boundary-container"
        >
          <div className={styles.errorCard}>
            <div className={styles.errorContent}>
              <WarningAmberIcon
                className={styles.errorIcon}
                aria-hidden="true"
              />
              <h2
                className={styles.errorTitle}
                data-testid="error-boundary-title"
              >
                Something went wrong
              </h2>
              <p
                className={styles.errorMessage}
                data-testid="error-boundary-message"
              >
                {"An unexpected error occurred"}
              </p>
              <button
                onClick={this.handleReset}
                className={styles.resetButton}
                type="button"
                data-testid="error-boundary-reset"
              >
                Try again
              </button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
