import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Draggable from "react-draggable";
import styles from "./AllSearchResultsDialog.module.scss";
import {
  FilterOption,
  FilterType,
  ObjectSearchOptionResult,
  VesselSearcOptionResult,
} from "./useSearch";
import AllResultsTable from "./SearchResultsTable";
import { useCallback, useRef } from "react";

const SelectFilterButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  borderRadius: 0,
  minWidth: "max-content",
  whiteSpace: "nowrap",
}));

interface AllSearchResultsDialogProps {
  showResultsDialogIsOpen: boolean;
  handleCloseAllResultsDialog: () => void;
  filterOptions: FilterOption[];
  selectedFilter: FilterOption;
  setSelectedFilter: (newState: FilterOption) => void;
  currentSearchValue: string;
  searchTermRefValue: string | null;
  loadShipsOnMap: (
    isAISSearch: boolean,
    searchValue: ObjectSearchOptionResult | VesselSearcOptionResult,
    date: string | undefined,
  ) => void;
  currentSearchCounts: { vesselsTotal: number; objectsTotal: number };
  date: string | undefined;
}

function AllSearchResultsDialog({
  showResultsDialogIsOpen,
  handleCloseAllResultsDialog,
  filterOptions,
  selectedFilter,
  setSelectedFilter,
  currentSearchValue,
  searchTermRefValue,
  currentSearchCounts,
  loadShipsOnMap,
  date,
}: AllSearchResultsDialogProps) {
  const dialogRef = useRef<HTMLDivElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const handleFilterSelect = useCallback(
    (option: FilterOption) => {
      setSelectedFilter(option);
    },
    [setSelectedFilter],
  );

  const getButtonColor = (option: FilterOption) =>
    selectedFilter === option
      ? { borderBottom: "2px solid #ffffff", color: "#ffffff" }
      : { borderBottom: "1px solid gray", color: "gray" };

  const getFilterCount = (filterType: FilterType) =>
    filterType === FilterType.Vessel
      ? currentSearchCounts.vesselsTotal
      : currentSearchCounts.objectsTotal;

  return (
    <Draggable nodeRef={dialogRef}>
      <Dialog
        ref={dialogRef}
        open={showResultsDialogIsOpen}
        onClose={handleCloseAllResultsDialog}
        PaperProps={{
          style: {
            backgroundColor: "#24263C",
            borderRadius: 0,
          },
        }}
        componentsProps={{
          backdrop: {
            style: { backgroundColor: "transparent" },
          },
        }}
        className={styles.allResultsDialog}
        maxWidth="md"
        fullWidth
        disableRestoreFocus
      >
        <DialogTitle className={styles.dialogTitle}>
          <div className={styles.dialogTitleHeader}>
            <h3 className={styles.dialogTitleText}>
              Search Results For "{searchTermRefValue}"
            </h3>
            <IconButton
              ref={closeButtonRef}
              sx={{ ml: "auto", color: "#ffffff" }}
              onClick={handleCloseAllResultsDialog}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>

          <div className={styles.dialogContentButtons}>
            {filterOptions.map((option: FilterOption) => (
              <SelectFilterButton
                type="button"
                variant="text"
                sx={getButtonColor(option)}
                onClick={() => handleFilterSelect(option)}
                key={`${option.filterType}-${getFilterCount(option.filterType)}`}
              >
                {`${option.filterType} (${getFilterCount(option.filterType)})`}
              </SelectFilterButton>
            ))}
            <div className={styles.dialogContentDivider} />
          </div>
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <AllResultsTable
            date={date}
            searchTermRefValue={searchTermRefValue}
            selectedFilter={selectedFilter}
            loadShipsOnMap={loadShipsOnMap}
            columns={
              selectedFilter.filterType === FilterType.Vessel
                ? [
                    { title: "Name", field: "name" },
                    { title: "Flag", field: "flag" },
                    { title: "Type", field: "ship_type" },
                    { title: "IMO", field: "imo" },
                    { title: "MMSI", field: "mmsi" },
                    { title: "Call Sign", field: "call_sign" },
                    { title: "Length", field: "length" },
                    { title: "Width", field: "width" },
                  ]
                : [
                    { title: "Acquired", field: "acquired" },
                    { title: "Type", field: "ship_type" },
                    { title: "IMO", field: "imo" },
                    { title: "Status", field: "status" },
                    { title: "Provider", field: "provider" },
                    { title: "Length", field: "length" },
                    { title: "Width", field: "width" },
                  ]
            }
            icon={
              selectedFilter.filterType === FilterType.Vessel
                ? "shipBow"
                : "satellite"
            }
            currentSearchValue={currentSearchValue}
          />
        </DialogContent>
      </Dialog>
    </Draggable>
  );
}

AllSearchResultsDialog.displayName = "AllSearchResultsDialog";

export default AllSearchResultsDialog;
